import firebase, { apps, database } from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/storage";
import { REALDB_CONSTANT } from "../Constant/FirebaseConstant";
import * as XLSX from "xlsx";
import { NotifyEvenuefy } from "../3rdParty";

const firebaseConfig = {
  apiKey: "AIzaSyBBgAbHlaczfUI-X1aKaQFIre8b1O7sfW8",
  authDomain: "iijs-floorplan.firebaseapp.com",
  databaseURL: "https://iijs-floorplan-default-rtdb.firebaseio.com",
  projectId: "iijs-floorplan",
  storageBucket: "iijs-floorplan.appspot.com",
  messagingSenderId: "144359212350",
  appId: "1:144359212350:web:d734da9f4f6f933e89986f",
  measurementId: "G-4ZFBF8M91S",
};

firebase.initializeApp(firebaseConfig);

export const firebaseApp = firebase;

export const auth = firebase.auth();

export const firestore = firebase.firestore();

export const realDB = firebase.database();

export const analytics = firebase.analytics();

export const storage = firebase.storage();

export const logoutUser = () => {
  return auth.signOut();
};

export const GetSellersBaseData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // const snapshot = await realDB.ref(REALDB_CONSTANT.SellerBaseData).get();
      const snapshot = await realDB
        .ref(REALDB_CONSTANT.SellerBaseData)
        .once("value");
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.warn("No Sellers Base Data found!!");
        resolve({});
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const GetSingleSellerBaseData = (sellerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await realDB
        .ref(`${REALDB_CONSTANT.SellerBaseData}/${sellerId}`)
        .get();
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.warn("No Seller Base Data found!!");
        resolve(null);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const GetAllSellersStallData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      // const snapshot = await realDB.ref(REALDB_CONSTANT.Sellersdata).get();
      const snapshot = await realDB
        .ref(REALDB_CONSTANT.Sellersdata)
        .once("value");
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.warn("No Sellers Stall Data found!!");
        resolve({});
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};
export const GetSingleSellerStallData = (sellerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = await realDB
        .ref(`${REALDB_CONSTANT.Sellersdata}/${sellerId}`)
        .get();
      if (snapshot.exists()) {
        resolve(snapshot.val());
      } else {
        console.warn("No Seller Stall Data found!!");
        resolve(null);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const UpdateSellerStallData = (sellerId, payload, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!sellerId || !payload) {
        throw ({ code: "Parameter_UnFullfilled", message: "parameter not passed correctly" })
      }

      // const result = await NotifyEvenuefy.allocated(NotifyEvenuefy.SellersDataToNotifyData(payload))
      // console.log(result)
      // if (result.code != 200) {
      //   throw ({ code: "API_ERROR", message: "api occurred from API", data: result })
      // }
      const nodeRef = realDB.ref(`${REALDB_CONSTANT.Sellersdata}/${sellerId}`);
      await nodeRef.update(payload);
      const _nodeRef = realDB.ref(
        `${REALDB_CONSTANT.SellerBaseData}/${sellerId}`
      );
      let _pa = {
        ...payload,
      };
      if (user) {
        _pa = {
          ..._pa,
          admin: {
            uid: user.uid,
            email: user.email,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
          },
          // evenuefyResponse: {
          //   [new Date().getTime()]: result
          // }
        };
      }
      delete _pa.stallData;
      await _nodeRef.update(_pa);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const DeleteSellersStallData = (sellerId, sectionId, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!sellerId || !sectionId) {
        throw ({ code: "Parameter_UnFullfilled", message: "parameter not passed correctly" })
      }

      console.log("sellerId", sellerId)
      console.log("sectionId", sectionId)

      // const result = await NotifyEvenuefy.revoked(sellerId, sectionId)
      // console.log(result)
      // if (result.code != 200) {
      //   throw ({ code: "API_ERROR", message: "api occurred from API", data: result })
      // }

      const nodeRef = realDB.ref(`${REALDB_CONSTANT.Sellersdata}/${sellerId}`);
      await nodeRef.remove();
      if (user) {
        const _nodeRef = realDB.ref(
          `${REALDB_CONSTANT.SellerBaseData}/${sellerId}`
        );
        _nodeRef.update({
          admin: {
            uid: user.uid,
            email: user.email,
            timestamp: firebase.database.ServerValue.TIMESTAMP,
          },
          // evenuefyResponse: {
          //   [new Date().getTime()]: result
          // }
        });
      }
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const getDataForExcel = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const baseNodeRef = realDB.ref(`${REALDB_CONSTANT.SellerBaseData}`);
      const baseNodeData = await baseNodeRef.get();
      if (!baseNodeData.exists()) {
        return;
      }
      let baseNodeDataObj = Object.values(baseNodeData.val());

      const nodeRef = realDB.ref(`${REALDB_CONSTANT.Sellersdata}`);
      const nodeData = await nodeRef.get();
      let nodeDataObj = {};
      if (nodeData.exists()) {
        nodeDataObj = Object.values(nodeData.val());
      }
      console.log(nodeDataObj);

      let nodeDataObjHash = {};
      nodeDataObj.forEach((elementData) => {
        nodeDataObjHash[elementData.comapnyId] = {
          ...elementData,
        };
      });
      let result = baseNodeDataObj.map((_bnd) => {
        let _adminData = { ..._bnd.admin };
        delete _bnd.admin;

        let _r = {
          ..._bnd,
        };
        Object.keys(_adminData).forEach((k) => {
          _r[`Admin_${k}`] = _adminData[k];
        });
        if (nodeDataObjHash[_bnd.comapnyId]) {
          let stallsData = nodeDataObjHash[_bnd.comapnyId].stallData;
          Object.keys(stallsData).forEach((stallId, index) => {
            let _index = index + 1;
            let stallData = stallsData[stallId];
            Object.keys(stallData).forEach((k) => {
              _r[`Stall${_index}_${k}`] = stallData[k];
            });
          });
        }
        return _r;
      });
      console.log(result);
      exportFile(
        result,
        "sheet1",
        "IIJS_DATA" + new Date().getTime() + ".xlsx"
      );
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const exportFile = (
  data,
  sheetName,
  filename,
  headerOrdering = null
) => {
  try {
    console.log("exportFile");
    const wb = XLSX.utils.book_new();
    let wsAll = null;
    if (headerOrdering) {
      wsAll = XLSX.utils.json_to_sheet(data, {
        header: headerOrdering,
      });
    } else {
      wsAll = XLSX.utils.json_to_sheet(data);
    }
    XLSX.utils.book_append_sheet(wb, wsAll, sheetName);
    XLSX.writeFile(wb, filename);
  } catch (error) {
    console.error(error);
  }
};
window.getDataForExcel = getDataForExcel;

const newData = {
  7000067606: {
    comapnyId: 7000067606,
    company_name: "SHREE RADHE DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 54,
  },
  7000078826: {
    comapnyId: 7000078826,
    company_name: "ROHINI DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000058972: {
    comapnyId: 7000058972,
    company_name: "DIANIL JEWELS",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000000590: {
    comapnyId: 7000000590,
    company_name: "UNIQUE GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000080213: {
    comapnyId: 7000080213,
    company_name: "AJAY S ORNAMENTS LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000079643: {
    comapnyId: 7000079643,
    company_name: "BIPRA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000080349: {
    comapnyId: 7000080349,
    company_name: "SK GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000078326: {
    comapnyId: 7000078326,
    company_name: "CDPL DIAMONDS LLP",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000004572: {
    comapnyId: 7000004572,
    company_name: "TG LEGACY PVT. LTD",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000006723: {
    comapnyId: 7000006723,
    company_name: "DIVINE STAR",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000078402: {
    comapnyId: 7000078402,
    company_name: "TRIFON JEWELS LLP",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000080212: {
    comapnyId: 7000080212,
    company_name: "GOLDIE JEWELS LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000068546: {
    comapnyId: 7000068546,
    company_name: "MOKSH JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000075632: {
    comapnyId: 7000075632,
    company_name: "SHOORA DESIGNS LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000075609: {
    comapnyId: 7000075609,
    company_name: "SHRI BANU DIAMOND",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000080248: {
    comapnyId: 7000080248,
    company_name: "7R JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000007933: {
    comapnyId: 7000007933,
    company_name: "DHANI JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000069501: {
    comapnyId: 7000069501,
    company_name: "DIAMOND TRADE CENTRE LLP",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000079757: {
    comapnyId: 7000079757,
    company_name: "STELLAR CREATIONS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000068518: {
    comapnyId: 7000068518,
    company_name: "SPAACE JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000070145: {
    comapnyId: 7000070145,
    company_name: "KENSTAR",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000080335: {
    comapnyId: 7000080335,
    company_name: "SHREE NATH JEWLS",
    region: "region_4",
    section: "sectionData_2",
    area: 18,
  },
  7000066476: {
    comapnyId: 7000066476,
    company_name: "MAHAVEER JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000080208: {
    comapnyId: 7000080208,
    company_name: "GREEN GROW DIAM",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000075548: {
    comapnyId: 7000075548,
    company_name: "DASHRATH GOLD ART PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000057564: {
    comapnyId: 7000057564,
    company_name: "SHILPA DIAMONDS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000004802: {
    comapnyId: 7000004802,
    company_name: "PATEL GEMS AND JEWELLRY",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000075753: {
    comapnyId: 7000075753,
    company_name: "K.TUSHAR DIAM",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000077016: {
    comapnyId: 7000077016,
    company_name: "MEERA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000076267: {
    comapnyId: 7000076267,
    company_name: "HOUSE OF POLKI",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000009896: {
    comapnyId: 7000009896,
    company_name: "KUSHAL GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000054321: {
    comapnyId: 7000054321,
    company_name: "RADHIKA CHAINS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000070850: {
    comapnyId: 7000070850,
    company_name: "DIYAMA JEWELS",
    region: "region_1",
    section: "sectionData_7",
    area: 9,
  },
  7000075066: {
    comapnyId: 7000075066,
    company_name: "C6 LABGROWN DIAMONDS LLP",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000004111: {
    comapnyId: 7000004111,
    company_name: "SHREE RAGHAV EXPORTS",
    region: "region_4",
    section: "sectionData_9",
    area: 36,
  },
  7000075707: {
    comapnyId: 7000075707,
    company_name: "RAJGURU JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000080209: {
    comapnyId: 7000080209,
    company_name: "M A DIAMONDS",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000080137: {
    comapnyId: 7000080137,
    company_name: "EKTA GEMS AND JEWELS",
    region: "region_1",
    section: "sectionData_9",
    area: 18,
  },
  7000066435: {
    comapnyId: 7000066435,
    company_name: "R. C. JAIN",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000070574: {
    comapnyId: 7000070574,
    company_name: "AIRAVATA HERITAGE JEWELLERY",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000075440: {
    comapnyId: 7000075440,
    company_name: "ISHI INDIA JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000078979: {
    comapnyId: 7000078979,
    company_name: "RATHOD GOLD AND DIAMOND PRIVATE LIMITE",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000066559: {
    comapnyId: 7000066559,
    company_name: "SHANTI SONS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000075340: {
    comapnyId: 7000075340,
    company_name: "SUNIL CHAINS AND ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000049846: {
    comapnyId: 7000049846,
    company_name: "NAVKAR GOLD PALACE LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000068469: {
    comapnyId: 7000068469,
    company_name: "SIPANI JADAU ANTIQUE PVT. LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000063654: {
    comapnyId: 7000063654,
    company_name: "TULSI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000080203: {
    comapnyId: 7000080203,
    company_name: "RADHE DIAM GEMS AND JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000080198: {
    comapnyId: 7000080198,
    company_name: "DECENT LAB GROWN DIAMOND PRIVATE LIMIT",
    region: "region_4",
    section: "sectionData_6",
    area: 18,
  },
  7000066843: {
    comapnyId: 7000066843,
    company_name: "J . T . JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000008145: {
    comapnyId: 7000008145,
    company_name: "SRI NAVDURGA JEWELLERY",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000012927: {
    comapnyId: 7000012927,
    company_name: "HARSH GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000080189: {
    comapnyId: 7000080189,
    company_name: "OM SAI KRIPA JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000070678: {
    comapnyId: 7000070678,
    company_name: "SRI DURGA GEMS & JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000070641: {
    comapnyId: 7000070641,
    company_name: "KJ S FEATHER TOUCH JEWELLERY PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000051572: {
    comapnyId: 7000051572,
    company_name: "MUTHAJI & SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000079646: {
    comapnyId: 7000079646,
    company_name: "GOMTI JEWELS LLP",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000064090: {
    comapnyId: 7000064090,
    company_name: "JALUS JEWELLERY",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000066198: {
    comapnyId: 7000066198,
    company_name: "JAMUNA COMMERCIAL PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000000474: {
    comapnyId: 7000000474,
    company_name: "DEE BEE JEWELLERS",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000071000: {
    comapnyId: 7000071000,
    company_name: "NEETTI ATELIIER PVT LTD",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000069928: {
    comapnyId: 7000069928,
    company_name: "KHAJANCHI JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000053589: {
    comapnyId: 7000053589,
    company_name: "MEERA JEWELLERYHOUSE LLP",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000060995: {
    comapnyId: 7000060995,
    company_name: "YASHIKA DIAMONDS PVT. LTD.",
    region: "region_6",
    section: "sectionData_1",
    area: 36,
  },
  7000075964: {
    comapnyId: 7000075964,
    company_name: "JAI MAA BRIJESHWARI JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000006595: {
    comapnyId: 7000006595,
    company_name: "SHEETAL JEWELLERY HOUSE LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 108,
  },
  7000066608: {
    comapnyId: 7000066608,
    company_name: "RANIEK JEWELS LLP",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000077542: {
    comapnyId: 7000077542,
    company_name: "SIYA ORO JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000070269: {
    comapnyId: 7000070269,
    company_name: "B DIAMOND AND GOLD JEWELLERY PRIVATE L",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000070102: {
    comapnyId: 7000070102,
    company_name: "DELICATE DIAMOND PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000066444: {
    comapnyId: 7000066444,
    company_name: "MY ZAVERI BAZAAR PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000075993: {
    comapnyId: 7000075993,
    company_name: "KEDIA JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000075762: {
    comapnyId: 7000075762,
    company_name: "SUJATA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000003061: {
    comapnyId: 7000003061,
    company_name: "MANIBHADRA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000080135: {
    comapnyId: 7000080135,
    company_name: "MISRI GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000007090: {
    comapnyId: 7000007090,
    company_name: "VIJISAN JEWELS PVT.LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000079589: {
    comapnyId: 7000079589,
    company_name: "RATNA DESIGN PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000007811: {
    comapnyId: 7000007811,
    company_name: "TEJ JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000080108: {
    comapnyId: 7000080108,
    company_name: "MARQUISE JEWELS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_10",
    area: 36,
  },
  7000053087: {
    comapnyId: 7000053087,
    company_name: "SHREE DHAN VERSHA JEWELLERS",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000075734: {
    comapnyId: 7000075734,
    company_name: "ORO JEWELS",
    region: "region_1",
    section: "sectionData_7",
    area: 18,
  },
  7000069997: {
    comapnyId: 7000069997,
    company_name: "MERRIGOLD JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000066460: {
    comapnyId: 7000066460,
    company_name: "MK EXPORTS",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000071490: {
    comapnyId: 7000071490,
    company_name: "DIANA JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000004779: {
    comapnyId: 7000004779,
    company_name: "JEWEL GOLDI (INDIA)",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000002074: {
    comapnyId: 7000002074,
    company_name: "NIMESH GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000078533: {
    comapnyId: 7000078533,
    company_name: "SHRI VARAH JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000079928: {
    comapnyId: 7000079928,
    company_name: "PADMINI JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000005248: {
    comapnyId: 7000005248,
    company_name: "JIGNA CREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000079867: {
    comapnyId: 7000079867,
    company_name: "ALLANZA JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000079945: {
    comapnyId: 7000079945,
    company_name: "SHINESHILPI WORLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000079987: {
    comapnyId: 7000079987,
    company_name: "CIARA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000074564: {
    comapnyId: 7000074564,
    company_name: "NAKODA ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000009619: {
    comapnyId: 7000009619,
    company_name: "AMY JEWELLERY DESIGNERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000007963: {
    comapnyId: 7000007963,
    company_name: "M.M.K. ENTERPRISES",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000075847: {
    comapnyId: 7000075847,
    company_name: "SHREE PAGARIYA JEWELLERS PVT LTD",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000080030: {
    comapnyId: 7000080030,
    company_name: "DIAMOND TREASURE",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000070076: {
    comapnyId: 7000070076,
    company_name: "PREVAS JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000059155: {
    comapnyId: 7000059155,
    company_name: "M G",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000080164: {
    comapnyId: 7000080164,
    company_name: "SHEAURA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000060937: {
    comapnyId: 7000060937,
    company_name: "S S JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000059151: {
    comapnyId: 7000059151,
    company_name: "MITTAL PEARLS AND JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000060816: {
    comapnyId: 7000060816,
    company_name: "REYONA JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000079948: {
    comapnyId: 7000079948,
    company_name: "INDRAHEER GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000069804: {
    comapnyId: 7000069804,
    company_name: "SIAM JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000066380: {
    comapnyId: 7000066380,
    company_name: "MAHIMA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000058828: {
    comapnyId: 7000058828,
    company_name: "MEHTA JEWELS EMPORIUM",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000005023: {
    comapnyId: 7000005023,
    company_name: "MANAK DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000002675: {
    comapnyId: 7000002675,
    company_name: "SILETTA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000080017: {
    comapnyId: 7000080017,
    company_name: "MALLINATH JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000007719: {
    comapnyId: 7000007719,
    company_name: "DFINE JEWELLERY",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000080112: {
    comapnyId: 7000080112,
    company_name: "IVARA SPARK LLP",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000003390: {
    comapnyId: 7000003390,
    company_name: "INDIANIA JEWELLERY COMPANY   PVT LTD",
    region: "region_1",
    section: "sectionData_10",
    area: 72,
  },
  7000008109: {
    comapnyId: 7000008109,
    company_name: "RATI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000002890: {
    comapnyId: 7000002890,
    company_name: "PARI IMPEX",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000076054: {
    comapnyId: 7000076054,
    company_name: "VIJAY SHANTI ARTS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000001620: {
    comapnyId: 7000001620,
    company_name: "FINE CREATION",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000079665: {
    comapnyId: 7000079665,
    company_name: "SOLANKI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000079625: {
    comapnyId: 7000079625,
    company_name: "RU-BRAMA RETAIL PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000079876: {
    comapnyId: 7000079876,
    company_name: "UNIJEWELS INDIA PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000057626: {
    comapnyId: 7000057626,
    company_name: "SHRI PEARL CORAL",
    region: "region_4",
    section: "sectionData_9",
    area: 27,
  },
  7000066646: {
    comapnyId: 7000066646,
    company_name: "SSVAR LLP",
    region: "region_1",
    section: "sectionData_10",
    area: 36,
  },
  7000059388: {
    comapnyId: 7000059388,
    company_name: "SPARKLING CHAINS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000059269: {
    comapnyId: 7000059269,
    company_name: "MI & MY JEWELS LLP",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000079235: {
    comapnyId: 7000079235,
    company_name: "JEWELS BY SOLANKI",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000009037: {
    comapnyId: 7000009037,
    company_name: "GEMS (INDIA)",
    region: "region_5",
    section: "sectionData_4",
    area: 9,
  },
  7000078571: {
    comapnyId: 7000078571,
    company_name: "NAVPAD GOLD",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000078591: {
    comapnyId: 7000078591,
    company_name: "VEEDI JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000079017: {
    comapnyId: 7000079017,
    company_name: "SHAKUN JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000000034: {
    comapnyId: 7000000034,
    company_name: "BHAGWATI JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000070142: {
    comapnyId: 7000070142,
    company_name: "AVSAR GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000061915: {
    comapnyId: 7000061915,
    company_name: "KRIM DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000070012: {
    comapnyId: 7000070012,
    company_name: "VAIBHAV CREATIONS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000070119: {
    comapnyId: 7000070119,
    company_name: "ZAMBAD INDUSTRIES PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000040757: {
    comapnyId: 7000040757,
    company_name: "SHEETAL GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000066327: {
    comapnyId: 7000066327,
    company_name: "R AMBALAL CREATIONS PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000063515: {
    comapnyId: 7000063515,
    company_name: "AASHIRWAD JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000072618: {
    comapnyId: 7000072618,
    company_name: "UNIVERSAL SILVER",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000079765: {
    comapnyId: 7000079765,
    company_name: "SUVARRNA JEWELS",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000079683: {
    comapnyId: 7000079683,
    company_name: "SS JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000069882: {
    comapnyId: 7000069882,
    company_name: "A GEMAWAT & SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000080125: {
    comapnyId: 7000080125,
    company_name: "REAL MANGALSTRA",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000070305: {
    comapnyId: 7000070305,
    company_name: "ROYAL ANGEL PEARL GEM JEWELS LLP",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000007102: {
    comapnyId: 7000007102,
    company_name: "AADEY JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_10",
    area: 24,
  },
  7000066440: {
    comapnyId: 7000066440,
    company_name: "MANOKAMANA JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000053166: {
    comapnyId: 7000053166,
    company_name: "LOKHESHH JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000057569: {
    comapnyId: 7000057569,
    company_name: "HARSHIT GOLD PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000080028: {
    comapnyId: 7000080028,
    company_name: "A. PARAS & CO.",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000061162: {
    comapnyId: 7000061162,
    company_name: "KAVYASHREE JEWELLERS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000000791: {
    comapnyId: 7000000791,
    company_name: "MANJALLY JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000068500: {
    comapnyId: 7000068500,
    company_name: "SIRMOR JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000057543: {
    comapnyId: 7000057543,
    company_name: "DANTARA DIAMONDS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000079614: {
    comapnyId: 7000079614,
    company_name: "SHREE RAJ RAJENDRA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000079823: {
    comapnyId: 7000079823,
    company_name: "AUM SWARNMANDIR JEWEL CREATION",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000079653: {
    comapnyId: 7000079653,
    company_name: "SHREE TULJA BHAVANI JEWELLERS PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 54,
  },
  7000037986: {
    comapnyId: 7000037986,
    company_name: "MAYUR GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000057651: {
    comapnyId: 7000057651,
    company_name: "JIMIT GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000069658: {
    comapnyId: 7000069658,
    company_name: "KAYRA CRYSTAL LLP",
    region: "region_2",
    section: "sectionData_6",
    area: 18,
  },
  7000060964: {
    comapnyId: 7000060964,
    company_name: "YASHODA JAGDISH & SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000079763: {
    comapnyId: 7000079763,
    company_name: "SWASTHIK GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000075344: {
    comapnyId: 7000075344,
    company_name: "UNI DIAM JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000070051: {
    comapnyId: 7000070051,
    company_name: "ZEWAR",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000070694: {
    comapnyId: 7000070694,
    company_name: "ASTHA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000044723: {
    comapnyId: 7000044723,
    company_name: "AMARKOSH JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000060398: {
    comapnyId: 7000060398,
    company_name: "SHIVA JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000034388: {
    comapnyId: 7000034388,
    company_name: "HASMUKH JEWELLERS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000066461: {
    comapnyId: 7000066461,
    company_name: "S. B. GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000075863: {
    comapnyId: 7000075863,
    company_name: "VANIOR JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_7",
    area: 9,
  },
  7000079648: {
    comapnyId: 7000079648,
    company_name: "BLAZE DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000068826: {
    comapnyId: 7000068826,
    company_name: "AANGI GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000066718: {
    comapnyId: 7000066718,
    company_name: "S.V.GEMS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000059384: {
    comapnyId: 7000059384,
    company_name: "STAR MANGALSUTRA LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000070306: {
    comapnyId: 7000070306,
    company_name: "CULTURE ANTIQUE JEWELLERY PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000080065: {
    comapnyId: 7000080065,
    company_name: "ASTMANGAL ORNAMENTS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000057568: {
    comapnyId: 7000057568,
    company_name: "NAKSHATRA CREATION",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000012955: {
    comapnyId: 7000012955,
    company_name: "GANESH DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000077640: {
    comapnyId: 7000077640,
    company_name: "BHAKTI LAB GROWN DIAMOND PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000057541: {
    comapnyId: 7000057541,
    company_name: "RGS CREATION",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000017243: {
    comapnyId: 7000017243,
    company_name: "STAR JEWELLERY",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000026627: {
    comapnyId: 7000026627,
    company_name: "R S GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000057606: {
    comapnyId: 7000057606,
    company_name: "SERAKI GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000053530: {
    comapnyId: 7000053530,
    company_name: "CREATIVE GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000079669: {
    comapnyId: 7000079669,
    company_name: "DIALTIN JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000060707: {
    comapnyId: 7000060707,
    company_name: "SUTRAM JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000080072: {
    comapnyId: 7000080072,
    company_name: "K.K.SILVER",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000069551: {
    comapnyId: 7000069551,
    company_name: "KISHORCHANDRA K BHAVSAR",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000011733: {
    comapnyId: 7000011733,
    company_name: "RAINBOW STONES",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000046160: {
    comapnyId: 7000046160,
    company_name: "RAJLAXMI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000067327: {
    comapnyId: 7000067327,
    company_name: "TAPERED JEWELS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000075984: {
    comapnyId: 7000075984,
    company_name: "ZEMERRA JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000009615: {
    comapnyId: 7000009615,
    company_name: "NIKHIL ENTERPRISES",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000075876: {
    comapnyId: 7000075876,
    company_name: "AASHI JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000070408: {
    comapnyId: 7000070408,
    company_name: "TATVA TULYA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000061245: {
    comapnyId: 7000061245,
    company_name: "ADA JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000072782: {
    comapnyId: 7000072782,
    company_name: "SARDA JEWELLERS INDIA PVT LTD",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000079873: {
    comapnyId: 7000079873,
    company_name: "REONA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000074560: {
    comapnyId: 7000074560,
    company_name: "MOHAR GEMS AND JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000061046: {
    comapnyId: 7000061046,
    company_name: "DHEE SIGNATURE JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000076603: {
    comapnyId: 7000076603,
    company_name: "OMJIYANSH JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000069887: {
    comapnyId: 7000069887,
    company_name: "AUREZA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000015956: {
    comapnyId: 7000015956,
    company_name: "SUN JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000070581: {
    comapnyId: 7000070581,
    company_name: "SURESH GARG GEMS LLP",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000075375: {
    comapnyId: 7000075375,
    company_name: "SONAM JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000068064: {
    comapnyId: 7000068064,
    company_name: "SILVER SOURCE",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000052386: {
    comapnyId: 7000052386,
    company_name: "CVD DIAMOND",
    region: "region_2",
    section: "sectionData_6",
    area: 9,
  },
  7000070366: {
    comapnyId: 7000070366,
    company_name: "1UNIQUE JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000075402: {
    comapnyId: 7000075402,
    company_name: "OMKALASH JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000075973: {
    comapnyId: 7000075973,
    company_name: "MAA BRIJESHWARI JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000069503: {
    comapnyId: 7000069503,
    company_name: "RADIANT JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000061180: {
    comapnyId: 7000061180,
    company_name: "ZAYAH SOLITAIRES",
    region: "region_6",
    section: "sectionData_1",
    area: 27,
  },
  7000079915: {
    comapnyId: 7000079915,
    company_name: "RAMKUDI DESIGNER JOOLRY",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000052443: {
    comapnyId: 7000052443,
    company_name: "NAKODA GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000068063: {
    comapnyId: 7000068063,
    company_name: "MANSUKH JEWELS",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000067700: {
    comapnyId: 7000067700,
    company_name: "MADHAVAN ORNAMENTS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000063261: {
    comapnyId: 7000063261,
    company_name: "GANESH GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000079805: {
    comapnyId: 7000079805,
    company_name: "JARNAS JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000060717: {
    comapnyId: 7000060717,
    company_name: "TANVI ETERNALS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000007340: {
    comapnyId: 7000007340,
    company_name: "MIRA GEMS",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000070660: {
    comapnyId: 7000070660,
    company_name: "CHANDIKA PEARLS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000068333: {
    comapnyId: 7000068333,
    company_name: "NATHI LAL ASHOK KUMAR",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000061125: {
    comapnyId: 7000061125,
    company_name: "TRADEMARK JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000070347: {
    comapnyId: 7000070347,
    company_name: "M S GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000009549: {
    comapnyId: 7000009549,
    company_name: "RAJ JEWELLERS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000079912: {
    comapnyId: 7000079912,
    company_name: "VRIT JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061093: {
    comapnyId: 7000061093,
    company_name: "PODDAR JEWELLERS",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000067566: {
    comapnyId: 7000067566,
    company_name: "FOREVER JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000043208: {
    comapnyId: 7000043208,
    company_name: "S. G. KAREL & SONS JEWELLERS PVT. LTD.",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000050544: {
    comapnyId: 7000050544,
    company_name: "KANTHILAL AND SONS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000013766: {
    comapnyId: 7000013766,
    company_name: "FARAH KHAN FINE JEWELLERY PVT. LTD.",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000076686: {
    comapnyId: 7000076686,
    company_name: "ANVEYA JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000037655: {
    comapnyId: 7000037655,
    company_name: "SIDDHESHWAR GEMS & JEWELS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000073730: {
    comapnyId: 7000073730,
    company_name: "M K GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000009463: {
    comapnyId: 7000009463,
    company_name: "JENY JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000070117: {
    comapnyId: 7000070117,
    company_name: "RIDDHI SIDDHI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000075394: {
    comapnyId: 7000075394,
    company_name: "AMOGH JEWELS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000003587: {
    comapnyId: 7000003587,
    company_name: "HEENA IMPEX",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000076586: {
    comapnyId: 7000076586,
    company_name: "SUVIDHI ORNA PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000017565: {
    comapnyId: 7000017565,
    company_name: "ANAND EXPORTS",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000070364: {
    comapnyId: 7000070364,
    company_name: "DIAGLITER",
    region: "region_2",
    section: "sectionData_7",
    area: 27,
  },
  7000066479: {
    comapnyId: 7000066479,
    company_name: "ANIKA JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000075742: {
    comapnyId: 7000075742,
    company_name: "MARS JEWELZ PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000070452: {
    comapnyId: 7000070452,
    company_name: "NV JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000068574: {
    comapnyId: 7000068574,
    company_name: "SWAADHA JEWEL LLP",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000070256: {
    comapnyId: 7000070256,
    company_name: "SAMARTH JEWELLERY CREATIONS PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000076966: {
    comapnyId: 7000076966,
    company_name: "QUBE STAR",
    region: "region_3",
    section: "sectionData_7",
    area: 18,
  },
  7000075902: {
    comapnyId: 7000075902,
    company_name: "PLUTUSJEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000079584: {
    comapnyId: 7000079584,
    company_name: "K.NILESH DIAMOND",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000079608: {
    comapnyId: 7000079608,
    company_name: "SHREE JI IMPEX",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000075364: {
    comapnyId: 7000075364,
    company_name: "KYRA JEWELZ",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000079723: {
    comapnyId: 7000079723,
    company_name: "LAXMI NARAYAN JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000009828: {
    comapnyId: 7000009828,
    company_name: "ARHAM INTERNATIONAL GEMS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000079754: {
    comapnyId: 7000079754,
    company_name: "JEWELSBY SPARK PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000069595: {
    comapnyId: 7000069595,
    company_name: "SHRIMAL GEMS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000069814: {
    comapnyId: 7000069814,
    company_name: "BHIKSHU JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000077421: {
    comapnyId: 7000077421,
    company_name: "LASHKARI ARTS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000076650: {
    comapnyId: 7000076650,
    company_name: "KLIORO FINE JEWELLERY PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000069923: {
    comapnyId: 7000069923,
    company_name: "NAVRASA FINE JEWELS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000062247: {
    comapnyId: 7000062247,
    company_name: "MINAL PALACE",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000057542: {
    comapnyId: 7000057542,
    company_name: "KK ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000026479: {
    comapnyId: 7000026479,
    company_name: "SHREE ASHAPURA ABHUSHAN BHANDAR",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000070985: {
    comapnyId: 7000070985,
    company_name: "MAHAVIR JEWELLERS NX LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000038537: {
    comapnyId: 7000038537,
    company_name: "SHILP CHAINS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000079794: {
    comapnyId: 7000079794,
    company_name: "D. T. JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000067646: {
    comapnyId: 7000067646,
    company_name: "MAHAVEER CHAIN & C. Z.",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000078372: {
    comapnyId: 7000078372,
    company_name: "R B ORNAMENT",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000066428: {
    comapnyId: 7000066428,
    company_name: "SHINE JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000001514: {
    comapnyId: 7000001514,
    company_name: "EARTH STONE INC",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000003476: {
    comapnyId: 7000003476,
    company_name: "HARE RAMA HARE KRISHNA CORALS  PVT. LTD.",
    region: "region_4",
    section: "sectionData_9",
    area: 27,
  },
  7000078653: {
    comapnyId: 7000078653,
    company_name: "KETAN GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000070558: {
    comapnyId: 7000070558,
    company_name: "PEARL GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000054110: {
    comapnyId: 7000054110,
    company_name: "Ronit Jewel",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000012437: {
    comapnyId: 7000012437,
    company_name: "OM ALANKAR JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000062071: {
    comapnyId: 7000062071,
    company_name: "DIVIJA JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000052922: {
    comapnyId: 7000052922,
    company_name: "SPH IMPEX",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000069579: {
    comapnyId: 7000069579,
    company_name: "BALMUKUND GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000057630: {
    comapnyId: 7000057630,
    company_name: "ARIHANT JEWEL CRAFT",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000007118: {
    comapnyId: 7000007118,
    company_name: "ANKIT GOLD LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000061641: {
    comapnyId: 7000061641,
    company_name: "AVANI CREATION",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000010802: {
    comapnyId: 7000010802,
    company_name: "ZAYNA EXPORTS",
    region: "region_6",
    section: "sectionData_10",
    area: 24,
  },
  7000065250: {
    comapnyId: 7000065250,
    company_name: "MERAKI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000060392: {
    comapnyId: 7000060392,
    company_name: "GEE ESS EMM JEWELLERS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000068111: {
    comapnyId: 7000068111,
    company_name: "MANJUSHA JEWELS INDUSTRY INDIA PVT. LTD.",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000052194: {
    comapnyId: 7000052194,
    company_name: "DESIRE JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000013828: {
    comapnyId: 7000013828,
    company_name: "SAI GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000069817: {
    comapnyId: 7000069817,
    company_name: "SHA MADANLAL DALICHAND",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000011109: {
    comapnyId: 7000011109,
    company_name: "VD JEWELS ARTISON PVT.LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000009953: {
    comapnyId: 7000009953,
    company_name: "CHHOTALAL DHANJIBHAI & SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010727: {
    comapnyId: 7000010727,
    company_name: "FINESSE JEWELS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000060552: {
    comapnyId: 7000060552,
    company_name: "BLUE GEMS&JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000075377: {
    comapnyId: 7000075377,
    company_name: "MAGNIFIC GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000066095: {
    comapnyId: 7000066095,
    company_name: "VIJAYKUMAR ABASAHEB BHOSALE",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069535: {
    comapnyId: 7000069535,
    company_name: "DIAMONDXE PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_5",
    area: 9,
  },
  7000070695: {
    comapnyId: 7000070695,
    company_name: "SHREEJI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000076668: {
    comapnyId: 7000076668,
    company_name: "RIDDHI JEWEL",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000078550: {
    comapnyId: 7000078550,
    company_name: "SALASAR JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000067049: {
    comapnyId: 7000067049,
    company_name: "KHAJANCHI GEMS",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000063031: {
    comapnyId: 7000063031,
    company_name: "HEM FINE JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000071504: {
    comapnyId: 7000071504,
    company_name: "JP ZAVERI LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000076016: {
    comapnyId: 7000076016,
    company_name: "FLORA JEWELS PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000000464: {
    comapnyId: 7000000464,
    company_name: "DECENT IMPEX",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000070146: {
    comapnyId: 7000070146,
    company_name: "PUREBON DIAMOND LLP",
    region: "region_3",
    section: "sectionData_7",
    area: 9,
  },
  7000079240: {
    comapnyId: 7000079240,
    company_name: "RJ JEWELLERS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 9,
  },
  7000034745: {
    comapnyId: 7000034745,
    company_name: "HIRANYA INDIA JEWEL PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 27,
  },
  7000004563: {
    comapnyId: 7000004563,
    company_name: "JAGDAMBA CHAINS PVT. LTD.",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000002461: {
    comapnyId: 7000002461,
    company_name: "DOCTOR DIAM PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000070784: {
    comapnyId: 7000070784,
    company_name: "BHAGWAT SHANTI JEWELLERS PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000075687: {
    comapnyId: 7000075687,
    company_name: "YOGI JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000066434: {
    comapnyId: 7000066434,
    company_name: "INDERLAL BALMUKAND GEMS & JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000066436: {
    comapnyId: 7000066436,
    company_name: "ROSE JEWELS INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000066690: {
    comapnyId: 7000066690,
    company_name: "AAGAM CHAIN",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000051949: {
    comapnyId: 7000051949,
    company_name: "VAKPATI JEWELLERS LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000049060: {
    comapnyId: 7000049060,
    company_name: "SHREE NAKODA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000000067: {
    comapnyId: 7000000067,
    company_name: "PRAVEEN JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000080057: {
    comapnyId: 7000080057,
    company_name: "KIFS FINSTOCK LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000063693: {
    comapnyId: 7000063693,
    company_name: "ARITZA GOLD PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 72,
  },
  7000006372: {
    comapnyId: 7000006372,
    company_name: "MAMRAJ MUSSADILAL JEWELLERS",
    region: "region_1",
    section: "sectionData_10",
    area: 72,
  },
  7000003614: {
    comapnyId: 7000003614,
    company_name: "VITHRAAG JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 72,
  },
  7000006333: {
    comapnyId: 7000006333,
    company_name: "ASHAPURI GOLD ORNAMENT LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 72,
  },
  7000078693: {
    comapnyId: 7000078693,
    company_name: "GURUKRUPA EXPORT PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 108,
  },
  7000012034: {
    comapnyId: 7000012034,
    company_name: "UNIQUE CHAINS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000010185: {
    comapnyId: 7000010185,
    company_name: "SKY GOLD LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000052715: {
    comapnyId: 7000052715,
    company_name: "RUSHABH JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 72,
  },
  7000050409: {
    comapnyId: 7000050409,
    company_name: "JAIPUR RATNA MANUFACTURING PVT. LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 72,
  },
  7000051622: {
    comapnyId: 7000051622,
    company_name: "FIONAA GOLD PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000026269: {
    comapnyId: 7000026269,
    company_name: "VK JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 72,
  },
  7000013304: {
    comapnyId: 7000013304,
    company_name: "PADMAVATI CHAINS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000012826: {
    comapnyId: 7000012826,
    company_name: "ROYAL ITALIAN JEWELLERY PVT. L TD.",
    region: "region_2",
    section: "sectionData_4",
    area: 54,
  },
  7000005320: {
    comapnyId: 7000005320,
    company_name: "VAGONI TRADE LINK",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000050137: {
    comapnyId: 7000050137,
    company_name: "VALENTINE JADAU",
    region: "region_4",
    section: "sectionData_10",
    area: 48,
  },
  7000057359: {
    comapnyId: 7000057359,
    company_name: "ANJALI DIAMONDS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_6",
    area: 72,
  },
  7000008800: {
    comapnyId: 7000008800,
    company_name: "ORO PRECIOUS METALS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000052597: {
    comapnyId: 7000052597,
    company_name: "SUNIL GEMS AND JEWELLERY PVT. LTD",
    region: "region_4",
    section: "sectionData_10",
    area: 48,
  },
  7000003412: {
    comapnyId: 7000003412,
    company_name: "H.K. JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000052426: {
    comapnyId: 7000052426,
    company_name: "CUPID DIAMONDS PVT.LTD.",
    region: "region_2",
    section: "sectionData_6",
    area: 36,
  },
  7000053113: {
    comapnyId: 7000053113,
    company_name: "J J J JEWELLERS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000060808: {
    comapnyId: 7000060808,
    company_name: "P D SONI JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000037733: {
    comapnyId: 7000037733,
    company_name: "AAROHI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000053578: {
    comapnyId: 7000053578,
    company_name: "RM PRECIOUS JEWELRY INDIA LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000060495: {
    comapnyId: 7000060495,
    company_name: "SHISH JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000003551: {
    comapnyId: 7000003551,
    company_name: "LILI STAR JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000044818: {
    comapnyId: 7000044818,
    company_name: "GOLDEN TOUCH ARTWARE  PVT. LTD.",
    region: "region_6",
    section: "sectionData_2",
    area: 36,
  },
  7000047491: {
    comapnyId: 7000047491,
    company_name: "S R GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000002497: {
    comapnyId: 7000002497,
    company_name: "GEM INDIA EXPORTS",
    region: "region_4",
    section: "sectionData_9",
    area: 36,
  },
  7000069624: {
    comapnyId: 7000069624,
    company_name: "BHAKTI JEWELS LLP",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000010428: {
    comapnyId: 7000010428,
    company_name: "R. KOTHARI & CO. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000057605: {
    comapnyId: 7000057605,
    company_name: "HEEDARIO GEMS AND JEWELS",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000000608: {
    comapnyId: 7000000608,
    company_name: "JEWEL HOUSE",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000060853: {
    comapnyId: 7000060853,
    company_name: "SAMRUDDHI JEWELCRAFT PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000013370: {
    comapnyId: 7000013370,
    company_name: "HRD DIAMOND INSTITUT E PVT LTD",
    region: "region_2",
    section: "sectionData_5",
    area: 36,
  },
  7000066079: {
    comapnyId: 7000066079,
    company_name: "JEWEL PARK",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000002274: {
    comapnyId: 7000002274,
    company_name: "LAXMI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000050810: {
    comapnyId: 7000050810,
    company_name: "HASMUKH PAREKH JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 54,
  },
  7000066336: {
    comapnyId: 7000066336,
    company_name: "SILVER EMPORIUM ARTE LLP.",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000051748: {
    comapnyId: 7000051748,
    company_name: "JEWELS OF RAJPUTAANA",
    region: "region_6",
    section: "sectionData_10",
    area: 36,
  },
  7000058871: {
    comapnyId: 7000058871,
    company_name: "ROYAL 925 STERLING SILVER JEWELLERY",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000034760: {
    comapnyId: 7000034760,
    company_name: "SAHANARATNABH PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000044473: {
    comapnyId: 7000044473,
    company_name: "SHEETAL SOLITAIRE LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000044497: {
    comapnyId: 7000044497,
    company_name: "RAJ GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000053117: {
    comapnyId: 7000053117,
    company_name: "MANGALDEEP CHAINS",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000002020: {
    comapnyId: 7000002020,
    company_name: "ABANS JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000054288: {
    comapnyId: 7000054288,
    company_name: "SHREE GURU GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000052513: {
    comapnyId: 7000052513,
    company_name: "CIYAZA INDUSTRIES LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000013170: {
    comapnyId: 7000013170,
    company_name: "S.K. CHAINS PVT. LTD.",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000059467: {
    comapnyId: 7000059467,
    company_name: "PARV GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000060958: {
    comapnyId: 7000060958,
    company_name: "SONANI INDUSTRIES PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_6",
    area: 36,
  },
  7000038360: {
    comapnyId: 7000038360,
    company_name: "SANKET JEWELLERS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000008650: {
    comapnyId: 7000008650,
    company_name: "SOUTH INDIA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000012054: {
    comapnyId: 7000012054,
    company_name: "ORNAMENT CREATIONS",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000007815: {
    comapnyId: 7000007815,
    company_name: "SHUBH ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000053494: {
    comapnyId: 7000053494,
    company_name: "RAJ HANS JEWELLERS AND SONS",
    region: "region_4",
    section: "sectionData_4",
    area: 36,
  },
  7000010334: {
    comapnyId: 7000010334,
    company_name: "NICE GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000000390: {
    comapnyId: 7000000390,
    company_name: "D.P. DESIGNS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000011815: {
    comapnyId: 7000011815,
    company_name: "S M GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000008697: {
    comapnyId: 7000008697,
    company_name: "MRK JEWELS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 36,
  },
  7000008715: {
    comapnyId: 7000008715,
    company_name: "SITARA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000010872: {
    comapnyId: 7000010872,
    company_name: "PRECIOUS GEMS & JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000002306: {
    comapnyId: 7000002306,
    company_name: "VEE J JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000051697: {
    comapnyId: 7000051697,
    company_name: "GOVINDJI ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000012397: {
    comapnyId: 7000012397,
    company_name: "BANSI JEWELLERS",
    region: "region_3",
    section: "sectionData_10",
    area: 36,
  },
  7000023213: {
    comapnyId: 7000023213,
    company_name: "R D KAREL JEWELLERS PVT LTD",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000006264: {
    comapnyId: 7000006264,
    company_name: "SOLITAA DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000001101: {
    comapnyId: 7000001101,
    company_name: "SAP JEWELS LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000007738: {
    comapnyId: 7000007738,
    company_name: "JENISHA JEWELS ARTS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000006382: {
    comapnyId: 7000006382,
    company_name: "DHARMA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000012734: {
    comapnyId: 7000012734,
    company_name: "SAMYAK LIFESTYLES PVT. LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000011530: {
    comapnyId: 7000011530,
    company_name: "MOMAI ART",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000016099: {
    comapnyId: 7000016099,
    company_name: "T J IMPEX FINE JEWELS LLP",
    region: "region_2",
    section: "sectionData_10",
    area: 48,
  },
  7000012576: {
    comapnyId: 7000012576,
    company_name: "SANSKRITI JEWELS",
    region: "region_2",
    section: "sectionData_10",
    area: 48,
  },
  7000002380: {
    comapnyId: 7000002380,
    company_name: "TARA FINE JEWELS",
    region: "region_2",
    section: "sectionData_10",
    area: 48,
  },
  7000005760: {
    comapnyId: 7000005760,
    company_name: "MUNOT ORNAMENTS ANNEX LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000012286: {
    comapnyId: 7000012286,
    company_name: "RAJAT EMPORIUM",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000000970: {
    comapnyId: 7000000970,
    company_name: "PURPLE",
    region: "region_1",
    section: "sectionData_4",
    area: 36,
  },
  7000000673: {
    comapnyId: 7000000673,
    company_name: "SOVAN JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000011250: {
    comapnyId: 7000011250,
    company_name: "R K R GOLD PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000005214: {
    comapnyId: 7000005214,
    company_name: "SUVARNAM JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000004155: {
    comapnyId: 7000004155,
    company_name: "ELVEE JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000002178: {
    comapnyId: 7000002178,
    company_name: "MAHARAJA CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000007974: {
    comapnyId: 7000007974,
    company_name: "MUKESH ART JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000071271: {
    comapnyId: 7000071271,
    company_name: "SHANKESHWAR JEWELCRAFT PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000004946: {
    comapnyId: 7000004946,
    company_name: "SRISHTI GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000014225: {
    comapnyId: 7000014225,
    company_name: "B.C. JAIN JEWELLERS PVT. LTD.",
    region: "region_5",
    section: "sectionData_1",
    area: 36,
  },
  7000000026: {
    comapnyId: 7000000026,
    company_name: "PUSHPA JEWELLERS PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000001035: {
    comapnyId: 7000001035,
    company_name: "DHANRUPJI DEVAJI CO",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000011378: {
    comapnyId: 7000011378,
    company_name: "JKS JEWELS PVT LTD",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000009937: {
    comapnyId: 7000009937,
    company_name: "AARYA24KT INDIA PVT.LTD",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000014812: {
    comapnyId: 7000014812,
    company_name: "LOTUS JEWELLERY CREATION",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000008094: {
    comapnyId: 7000008094,
    company_name: "B.C. JAIN JEWELLERS (VIVEK)   PVT. LTD.",
    region: "region_5",
    section: "sectionData_1",
    area: 36,
  },
  7000006803: {
    comapnyId: 7000006803,
    company_name: "LAXMI JEWELLERY CHENNAI   PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000035489: {
    comapnyId: 7000035489,
    company_name: "DANTARA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000049909: {
    comapnyId: 7000049909,
    company_name: "DHANAKSH JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000000935: {
    comapnyId: 7000000935,
    company_name: "ROSE JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000007195: {
    comapnyId: 7000007195,
    company_name: "P.R. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000001109: {
    comapnyId: 7000001109,
    company_name: "R. V. ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000004035: {
    comapnyId: 7000004035,
    company_name: "UNIQUE VALLEY",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000001073: {
    comapnyId: 7000001073,
    company_name: "AMAN ORNAMENTS",
    region: "region_3",
    section: "sectionData_4",
    area: 36,
  },
  7000015483: {
    comapnyId: 7000015483,
    company_name: "SHIVIN JEWELLERS",
    region: "region_4",
    section: "sectionData_2",
    area: 36,
  },
  7000015065: {
    comapnyId: 7000015065,
    company_name: "SANGHI JEWELLERS PVT. LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 54,
  },
  7000038034: {
    comapnyId: 7000038034,
    company_name: "ARTS N JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000057641: {
    comapnyId: 7000057641,
    company_name: "KAMAKHYA JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000060929: {
    comapnyId: 7000060929,
    company_name: "DIPTI AMISHA",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000049853: {
    comapnyId: 7000049853,
    company_name: "MANISH BHINDI JEWELS",
    region: "region_3",
    section: "sectionData_10",
    area: 36,
  },
  7000003941: {
    comapnyId: 7000003941,
    company_name: "V.D. JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 36,
  },
  7000015546: {
    comapnyId: 7000015546,
    company_name: "SHREE RAJ JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000037835: {
    comapnyId: 7000037835,
    company_name: "ARYA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000037535: {
    comapnyId: 7000037535,
    company_name: "L STAR",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000038027: {
    comapnyId: 7000038027,
    company_name: "VIE 92 JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000003610: {
    comapnyId: 7000003610,
    company_name: "SHINJINI JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000017059: {
    comapnyId: 7000017059,
    company_name: "RANIWALA JEWELLERS PVT. LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 72,
  },
  7000015125: {
    comapnyId: 7000015125,
    company_name: "SENSUEL",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000015612: {
    comapnyId: 7000015612,
    company_name: "SHUBHAM MOTIWALA & JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000011961: {
    comapnyId: 7000011961,
    company_name: "ACHAL JEWELS  PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_10",
    area: 72,
  },
  7000067273: {
    comapnyId: 7000067273,
    company_name: "SRK JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000004026: {
    comapnyId: 7000004026,
    company_name: "GNS JEWELLERY",
    region: "region_3",
    section: "sectionData_1",
    area: 27,
  },
  7000016061: {
    comapnyId: 7000016061,
    company_name: "SURYA GOLDS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000052565: {
    comapnyId: 7000052565,
    company_name: "NAVRITHI JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000016969: {
    comapnyId: 7000016969,
    company_name: "RAMBHAJO S",
    region: "region_4",
    section: "sectionData_10",
    area: 72,
  },
  7000013092: {
    comapnyId: 7000013092,
    company_name: "S. VINODKUMAR DIAMONDS PVT.LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000014832: {
    comapnyId: 7000014832,
    company_name: "MEHTA GOLD PVT. LTD.",
    region: "region_1",
    section: "sectionData_2",
    area: 54,
  },
  7000006960: {
    comapnyId: 7000006960,
    company_name: "RII SIGNATURE JOOLRY",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000014949: {
    comapnyId: 7000014949,
    company_name: "SUKHRAJ JEWELS PVT. LTD.",
    region: "region_4",
    section: "sectionData_4",
    area: 54,
  },
  7000060785: {
    comapnyId: 7000060785,
    company_name: "NANESH DIAMONDS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000002205: {
    comapnyId: 7000002205,
    company_name: "VIPS JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000003691: {
    comapnyId: 7000003691,
    company_name: "SHUBHAM SILVER",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000066893: {
    comapnyId: 7000066893,
    company_name: "ILA CREATION PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000004882: {
    comapnyId: 7000004882,
    company_name: "T.T. GEMS INDIA LLP",
    region: "region_2",
    section: "sectionData_9",
    area: 36,
  },
  7000069750: {
    comapnyId: 7000069750,
    company_name: "APARAJITAA JEWEL",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000004586: {
    comapnyId: 7000004586,
    company_name: "SPARKLING GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000043227: {
    comapnyId: 7000043227,
    company_name: "SHREE RATNA JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000037922: {
    comapnyId: 7000037922,
    company_name: "SHILPI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000016326: {
    comapnyId: 7000016326,
    company_name: "UNIQUE ART DIAMONDS",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000064504: {
    comapnyId: 7000064504,
    company_name: "SKJGOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000016822: {
    comapnyId: 7000016822,
    company_name: "UNIQUE BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000016531: {
    comapnyId: 7000016531,
    company_name: "VENUS JEWEL",
    region: "region_2",
    section: "sectionData_3",
    area: 36,
  },
  7000067023: {
    comapnyId: 7000067023,
    company_name: "VARNICA JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000006399: {
    comapnyId: 7000006399,
    company_name: "KHUSHALI JEWELS (P) LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000047350: {
    comapnyId: 7000047350,
    company_name: "M.R.PARMAR",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000048256: {
    comapnyId: 7000048256,
    company_name: "DIVA CLASSIC JEWELS AND GEMS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_1",
    area: 27,
  },
  7000012745: {
    comapnyId: 7000012745,
    company_name: "L. JAVERCHAND JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000044223: {
    comapnyId: 7000044223,
    company_name: "S. M. GOLD LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000069764: {
    comapnyId: 7000069764,
    company_name: "ROSE GOLD JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000038422: {
    comapnyId: 7000038422,
    company_name: "MANYATA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000038210: {
    comapnyId: 7000038210,
    company_name: "VS JEWELLERY WHOLESALER GLOBAL PVT. LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000037282: {
    comapnyId: 7000037282,
    company_name: "SMR JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000002670: {
    comapnyId: 7000002670,
    company_name: "GLORIOUS JEWELLERY (INDIA) PVT . LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 27,
  },
  7000053169: {
    comapnyId: 7000053169,
    company_name: "ANOKHA JEWELS PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_10",
    area: 24,
  },
  7000037954: {
    comapnyId: 7000037954,
    company_name: "OPH JEWELLER",
    region: "region_6",
    section: "sectionData_10",
    area: 24,
  },
  7000062601: {
    comapnyId: 7000062601,
    company_name: "JEWELS  BY SEPHORA LLP",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000010410: {
    comapnyId: 7000010410,
    company_name: "PEACEMOON TRADERS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000009292: {
    comapnyId: 7000009292,
    company_name: "PRIMESTAR GEMS & JEWELS PVT.  LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000007732: {
    comapnyId: 7000007732,
    company_name: "RAJ GEMS N JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000008743: {
    comapnyId: 7000008743,
    company_name: "BHAGWATI IMPEX",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000070690: {
    comapnyId: 7000070690,
    company_name: "SUNRISE AURO & DIAMOND PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000069639: {
    comapnyId: 7000069639,
    company_name: "EMPRIYAL",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000017387: {
    comapnyId: 7000017387,
    company_name: "SPECTRUM JEWELMART PVT. LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000069607: {
    comapnyId: 7000069607,
    company_name: "LEO SILVER ART PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000006877: {
    comapnyId: 7000006877,
    company_name: "L.K. JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000046257: {
    comapnyId: 7000046257,
    company_name: "MAASILVER HUB INDIA PVT LTD",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000057604: {
    comapnyId: 7000057604,
    company_name: "AAHAV FINE JEWELS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000005790: {
    comapnyId: 7000005790,
    company_name: "S.R ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000037682: {
    comapnyId: 7000037682,
    company_name: "SILVERIO ARTESA PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000002108: {
    comapnyId: 7000002108,
    company_name: "ENSHINE",
    region: "region_3",
    section: "sectionData_10",
    area: 24,
  },
  7000013725: {
    comapnyId: 7000013725,
    company_name: "D YNE JEWELLERY",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000052329: {
    comapnyId: 7000052329,
    company_name: "SANKALP JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000006463: {
    comapnyId: 7000006463,
    company_name: "JEWEL DARSHAN",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000047755: {
    comapnyId: 7000047755,
    company_name: "AQUA JEWELLERS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000011716: {
    comapnyId: 7000011716,
    company_name: "SABOO",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000004954: {
    comapnyId: 7000004954,
    company_name: "CHINTAMANI GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000011492: {
    comapnyId: 7000011492,
    company_name: "RIVERA GEMS N JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000069849: {
    comapnyId: 7000069849,
    company_name: "SHREE JOYERIA",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000002202: {
    comapnyId: 7000002202,
    company_name: "BHAWANI GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000057408: {
    comapnyId: 7000057408,
    company_name: "JEWELLERY BY SURANAS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000015511: {
    comapnyId: 7000015511,
    company_name: "SHREE GOLD JEWEL CREATOR",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000008208: {
    comapnyId: 7000008208,
    company_name: "SPLURGE JEWELS LLP",
    region: "region_2",
    section: "sectionData_10",
    area: 48,
  },
  7000026283: {
    comapnyId: 7000026283,
    company_name: "MOHAN LAL NARAYAN DAS JEWELLERS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000012672: {
    comapnyId: 7000012672,
    company_name: "BAHETI GEMS & JEWELS PVT. LTD.",
    region: "region_5",
    section: "sectionData_10",
    area: 48,
  },
  7000006778: {
    comapnyId: 7000006778,
    company_name: "CARAT COUTURE FINE JEWELLERY",
    region: "region_6",
    section: "sectionData_10",
    area: 24,
  },
  7000002816: {
    comapnyId: 7000002816,
    company_name: "J.P. GEMS AND JEWELS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000011190: {
    comapnyId: 7000011190,
    company_name: "THE LEO JEWELS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000009097: {
    comapnyId: 7000009097,
    company_name: "GOLD MINE",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000000434: {
    comapnyId: 7000000434,
    company_name: "DASSANI BROTHERS",
    region: "region_2",
    section: "sectionData_10",
    area: 48,
  },
  7000009967: {
    comapnyId: 7000009967,
    company_name: "ORIENTAL GEMCO PVT. LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000001819: {
    comapnyId: 7000001819,
    company_name: "GDK JEWELS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_10",
    area: 24,
  },
  7000047484: {
    comapnyId: 7000047484,
    company_name: "MAA SATTI JEWELS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000016986: {
    comapnyId: 7000016986,
    company_name: "SWASTIK JEWELS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000016990: {
    comapnyId: 7000016990,
    company_name: "AKS JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000003194: {
    comapnyId: 7000003194,
    company_name: "PRATAP JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000005717: {
    comapnyId: 7000005717,
    company_name: "MATUSHREE GOLD LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000005861: {
    comapnyId: 7000005861,
    company_name: "JAI GULAB DEV JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000057595: {
    comapnyId: 7000057595,
    company_name: "VEER JEWELLERS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000001289: {
    comapnyId: 7000001289,
    company_name: "L.M. LIFESTYLE INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000014936: {
    comapnyId: 7000014936,
    company_name: "S.S. BROTHERS",
    region: "region_4",
    section: "sectionData_9",
    area: 27,
  },
  7000010602: {
    comapnyId: 7000010602,
    company_name: "VALDARIA IMPEX PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000003903: {
    comapnyId: 7000003903,
    company_name: "K.V. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000001478: {
    comapnyId: 7000001478,
    company_name: "P.D. & COMPANY",
    region: "region_4",
    section: "sectionData_9",
    area: 27,
  },
  7000061072: {
    comapnyId: 7000061072,
    company_name: "SWARN SHREE CHAIN",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000035666: {
    comapnyId: 7000035666,
    company_name: "ECO LIGHT DIAMOND",
    region: "region_2",
    section: "sectionData_6",
    area: 18,
  },
  7000004598: {
    comapnyId: 7000004598,
    company_name: "SUNBERA EXPORTS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000027790: {
    comapnyId: 7000027790,
    company_name: "SRI MONDAL JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000061140: {
    comapnyId: 7000061140,
    company_name: "SWARN JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000009258: {
    comapnyId: 7000009258,
    company_name: "UMA ORNAMENTS",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000009932: {
    comapnyId: 7000009932,
    company_name: "VAJRA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000001754: {
    comapnyId: 7000001754,
    company_name: "GANESH JEWELLERY (INDIA) PVT. LTD.",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000005738: {
    comapnyId: 7000005738,
    company_name: "MANAPPURAM JEWELLERS LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000017199: {
    comapnyId: 7000017199,
    company_name: "CREATIVE JEWEL",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000007124: {
    comapnyId: 7000007124,
    company_name: "RAJ ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000001975: {
    comapnyId: 7000001975,
    company_name: "VIPUL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000015242: {
    comapnyId: 7000015242,
    company_name: "SHUBH DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000052963: {
    comapnyId: 7000052963,
    company_name: "SHANTILAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000059226: {
    comapnyId: 7000059226,
    company_name: "GOKUL DIVAY JEWELLERS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000063124: {
    comapnyId: 7000063124,
    company_name: "NITYAS GEMS AND JEWELLERY PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_7",
    area: 18,
  },
  7000079864: {
    comapnyId: 7000079864,
    company_name: "HDJ JEWEL PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000080132: {
    comapnyId: 7000080132,
    company_name: "RISHITH JEWELLERS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000069988: {
    comapnyId: 7000069988,
    company_name: "RATNALIFE CREATION LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000008870: {
    comapnyId: 7000008870,
    company_name: "ROSETTA DIAMOND JEWELLERY PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000052904: {
    comapnyId: 7000052904,
    company_name: "PROMISE JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000016852: {
    comapnyId: 7000016852,
    company_name: "VENKATESH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000057732: {
    comapnyId: 7000057732,
    company_name: "PURPLE JEWELS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000016596: {
    comapnyId: 7000016596,
    company_name: "VINAYAK EXPORTS",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000068473: {
    comapnyId: 7000068473,
    company_name: "CELAVO",
    region: "region_3",
    section: "sectionData_7",
    area: 18,
  },
  7000053051: {
    comapnyId: 7000053051,
    company_name: "NAVKAR STERLING SILVER",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000063178: {
    comapnyId: 7000063178,
    company_name: "TISTABENE ENTERPRISES PVT LTD",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000011727: {
    comapnyId: 7000011727,
    company_name: "HIMANI DIAMOND",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000048633: {
    comapnyId: 7000048633,
    company_name: "SAAJ CREATIONS",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000011439: {
    comapnyId: 7000011439,
    company_name: "SRI VENKATESHWARA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000035640: {
    comapnyId: 7000035640,
    company_name: "SHREE HARI KRUPA JEWEL",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000066566: {
    comapnyId: 7000066566,
    company_name: "SRI G. G. N. GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000067687: {
    comapnyId: 7000067687,
    company_name: "MAHAKALI CRAFT",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000068583: {
    comapnyId: 7000068583,
    company_name: "KOSHA FINE JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000005249: {
    comapnyId: 7000005249,
    company_name: "IGM EXPORTS PVT. LTD",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000061174: {
    comapnyId: 7000061174,
    company_name: "V.K. JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000026501: {
    comapnyId: 7000026501,
    company_name: "S R JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000049458: {
    comapnyId: 7000049458,
    company_name: "S D GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000015869: {
    comapnyId: 7000015869,
    company_name: "STERLING JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000067365: {
    comapnyId: 7000067365,
    company_name: "PRATHAM GEMS AND JEWELLERY PVT LTD",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000067257: {
    comapnyId: 7000067257,
    company_name: "MJR SILVER PVT LTD",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000034360: {
    comapnyId: 7000034360,
    company_name: "HRDK BULLION & REFINERY PVT. LTD.",
    region: "region_4",
    section: "sectionData_2",
    area: 18,
  },
  7000069966: {
    comapnyId: 7000069966,
    company_name: "VRUSHABH ORNAMENTS",
    region: "region_3",
    section: "sectionData_4",
    area: 18,
  },
  7000058891: {
    comapnyId: 7000058891,
    company_name: "DVYNLOVE LLP",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000000870: {
    comapnyId: 7000000870,
    company_name: "DIAGOLD",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000058642: {
    comapnyId: 7000058642,
    company_name: "REVAZ",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000009674: {
    comapnyId: 7000009674,
    company_name: "NINE JEWELLERY",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000009433: {
    comapnyId: 7000009433,
    company_name: "NAROLA DIAMONDS PVT LTD",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000012886: {
    comapnyId: 7000012886,
    company_name: "H.P.SILVER",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000012855: {
    comapnyId: 7000012855,
    company_name: "RADHIKA JEWELSCRAFT PVT. LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000013638: {
    comapnyId: 7000013638,
    company_name: "DAGINA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000026622: {
    comapnyId: 7000026622,
    company_name: "ARHAM CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000008298: {
    comapnyId: 7000008298,
    company_name: "MEHTA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000044649: {
    comapnyId: 7000044649,
    company_name: "VARDHMAN GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000008300: {
    comapnyId: 7000008300,
    company_name: "MANISH JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000061077: {
    comapnyId: 7000061077,
    company_name: "AMULYA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000015335: {
    comapnyId: 7000015335,
    company_name: "SHAH GEMS",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000017234: {
    comapnyId: 7000017234,
    company_name: "NELKT CREATION",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000037942: {
    comapnyId: 7000037942,
    company_name: "PALAK JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000052703: {
    comapnyId: 7000052703,
    company_name: "SHREE PARAM GEMS AND JEWELLERY LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000054706: {
    comapnyId: 7000054706,
    company_name: "FINE COLOUR GEMS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_9",
    area: 27,
  },
  7000002636: {
    comapnyId: 7000002636,
    company_name: "GIE GOLD CREATIONS PVT. LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000016887: {
    comapnyId: 7000016887,
    company_name: "KINU BABA JEWELLERY (INDIA) PVT. LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000026241: {
    comapnyId: 7000026241,
    company_name: "AVNI JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000004520: {
    comapnyId: 7000004520,
    company_name: "NAVPUSHPA JEWELLERS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000053088: {
    comapnyId: 7000053088,
    company_name: "EKTA DIAMOND LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000006017: {
    comapnyId: 7000006017,
    company_name: "M S DIAAGEM",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000069990: {
    comapnyId: 7000069990,
    company_name: "ATUL JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000051206: {
    comapnyId: 7000051206,
    company_name: "RADHE KRISHNA GEMS & JEWELLERY",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000058830: {
    comapnyId: 7000058830,
    company_name: "MEHTA VENTURES",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000066136: {
    comapnyId: 7000066136,
    company_name: "A.D.JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000037985: {
    comapnyId: 7000037985,
    company_name: "TRINITY JEWELLERY CO.",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000015155: {
    comapnyId: 7000015155,
    company_name: "BAANGANGA DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000009121: {
    comapnyId: 7000009121,
    company_name: "MAHASHILA JEWELLERY PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000019011: {
    comapnyId: 7000019011,
    company_name: "N K JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000000382: {
    comapnyId: 7000000382,
    company_name: "D.N. JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000066196: {
    comapnyId: 7000066196,
    company_name: "SURANA SILVER HOUSE",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000057587: {
    comapnyId: 7000057587,
    company_name: "SANKALP JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000052667: {
    comapnyId: 7000052667,
    company_name: "SWARN DEEP",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000016084: {
    comapnyId: 7000016084,
    company_name: "SWASTIK SALES INCORPORATION",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000066441: {
    comapnyId: 7000066441,
    company_name: "TRESORIE",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000037991: {
    comapnyId: 7000037991,
    company_name: "SHREE MAHAVIR SILVER ART",
    region: "region_3",
    section: "sectionData_4",
    area: 18,
  },
  7000026262: {
    comapnyId: 7000026262,
    company_name: "SHINE GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000009377: {
    comapnyId: 7000009377,
    company_name: "B.D. BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000052651: {
    comapnyId: 7000052651,
    company_name: "SHIVENDRA JEWELLERS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000012391: {
    comapnyId: 7000012391,
    company_name: "SUKH BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000061010: {
    comapnyId: 7000061010,
    company_name: "HOUSE OF SPARSH PVT LTD",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000060965: {
    comapnyId: 7000060965,
    company_name: "MAHEK ENTERPRISES",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000003897: {
    comapnyId: 7000003897,
    company_name: "SHRI VARDHMAN ORNAMENTS  PVT.LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000060888: {
    comapnyId: 7000060888,
    company_name: "S RAGHAV JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000037693: {
    comapnyId: 7000037693,
    company_name: "PGP JOYERIA LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000057602: {
    comapnyId: 7000057602,
    company_name: "RAJENDRA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000066003: {
    comapnyId: 7000066003,
    company_name: "SWASTIK JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000008839: {
    comapnyId: 7000008839,
    company_name: "E- DESIGNS",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000002616: {
    comapnyId: 7000002616,
    company_name: "GHATIWALA JEWELLERS",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000000812: {
    comapnyId: 7000000812,
    company_name: "DIA JEWEL",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000003350: {
    comapnyId: 7000003350,
    company_name: "RAKSHIKA JEWELS LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000068390: {
    comapnyId: 7000068390,
    company_name: "CUBIC CARBON CREATIONS LLP",
    region: "region_2",
    section: "sectionData_6",
    area: 18,
  },
  7000061872: {
    comapnyId: 7000061872,
    company_name: "SAMOR GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000048530: {
    comapnyId: 7000048530,
    company_name: "KENVI JEWELS LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000052552: {
    comapnyId: 7000052552,
    company_name: "KAASHVI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000008346: {
    comapnyId: 7000008346,
    company_name: "THIRUMALA",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000016617: {
    comapnyId: 7000016617,
    company_name: "VIRAASAT JEWELS PVT. LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 36,
  },
  7000013267: {
    comapnyId: 7000013267,
    company_name: "SHREE OMKAR JEWELLER S (P) LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000010896: {
    comapnyId: 7000010896,
    company_name: "BHAIRAV SILVER MART",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000035942: {
    comapnyId: 7000035942,
    company_name: "T.G. ART INDIA",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000066155: {
    comapnyId: 7000066155,
    company_name: "P. R. & SONS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000012377: {
    comapnyId: 7000012377,
    company_name: "UNITOUCH CREATIONS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000068401: {
    comapnyId: 7000068401,
    company_name: "ARSHIA JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000038426: {
    comapnyId: 7000038426,
    company_name: "S.P.SHAH & SONS JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000047480: {
    comapnyId: 7000047480,
    company_name: "MATRA SMURTI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000044128: {
    comapnyId: 7000044128,
    company_name: "M. M. CORPORATION",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000061032: {
    comapnyId: 7000061032,
    company_name: "CHORARIA JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000063227: {
    comapnyId: 7000063227,
    company_name: "MAA JEWELS",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000060975: {
    comapnyId: 7000060975,
    company_name: "YASH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000035481: {
    comapnyId: 7000035481,
    company_name: "MEZZARIA JEWELS PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000053120: {
    comapnyId: 7000053120,
    company_name: "MIDAS TOUCH INTERNATIONAL PVT. LTD.",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000002347: {
    comapnyId: 7000002347,
    company_name: "SCENTS JEWELRY LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000060998: {
    comapnyId: 7000060998,
    company_name: "ANAND SHAH JEWELS LLP",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000054221: {
    comapnyId: 7000054221,
    company_name: "KANGANAM",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000059307: {
    comapnyId: 7000059307,
    company_name: "B.S.K. INTERNATIONAL PVT. LTD.",
    region: "region_5",
    section: "sectionData_1",
    area: 18,
  },
  7000011989: {
    comapnyId: 7000011989,
    company_name: "RAMAVTAR JEWELLERS PVT. LTD.",
    region: "region_6",
    section: "sectionData_9",
    area: 18,
  },
  7000058461: {
    comapnyId: 7000058461,
    company_name: "MANIKA GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000014628: {
    comapnyId: 7000014628,
    company_name: "BIJOUX ARGENT PVT LTD",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000007361: {
    comapnyId: 7000007361,
    company_name: "VENUS FINE JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000058887: {
    comapnyId: 7000058887,
    company_name: "RUSHABH JEWELS",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000006851: {
    comapnyId: 7000006851,
    company_name: "JHALAK JEWELLERS",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000038540: {
    comapnyId: 7000038540,
    company_name: "UGRA IMPEX",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000053547: {
    comapnyId: 7000053547,
    company_name: "NIRVAAN JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000002837: {
    comapnyId: 7000002837,
    company_name: "GEMCRAFT",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000012634: {
    comapnyId: 7000012634,
    company_name: "IRA JEWELS",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000003110: {
    comapnyId: 7000003110,
    company_name: "PRAGYA JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000012628: {
    comapnyId: 7000012628,
    company_name: "NEO DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000060956: {
    comapnyId: 7000060956,
    company_name: "SHRI RAGHAV JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000014809: {
    comapnyId: 7000014809,
    company_name: "R R FOREVER JEWELLERY  PVT. LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000052939: {
    comapnyId: 7000052939,
    company_name: "OSTWAL JEWELS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000050856: {
    comapnyId: 7000050856,
    company_name: "SWARN VILLA",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000009714: {
    comapnyId: 7000009714,
    company_name: "GOMES GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000004648: {
    comapnyId: 7000004648,
    company_name: "VARA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000001876: {
    comapnyId: 7000001876,
    company_name: "JENISHA JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000034402: {
    comapnyId: 7000034402,
    company_name: "DHYAAN JEWELS LLP.",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000012061: {
    comapnyId: 7000012061,
    company_name: "SHREE DARSHAN JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000066667: {
    comapnyId: 7000066667,
    company_name: "SWASTIK JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000007065: {
    comapnyId: 7000007065,
    company_name: "VERO STELLA JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000019013: {
    comapnyId: 7000019013,
    company_name: "HISSARIA DIAMONDS",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000005147: {
    comapnyId: 7000005147,
    company_name: "SHA RATANCHAND DILIPKUMAR OSWAL",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069633: {
    comapnyId: 7000069633,
    company_name: "RAYAN DESIGNS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000011810: {
    comapnyId: 7000011810,
    company_name: "RAJENDER GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 36,
  },
  7000069973: {
    comapnyId: 7000069973,
    company_name: "R K SONI AND SONS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000001338: {
    comapnyId: 7000001338,
    company_name: "RISHABH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000004262: {
    comapnyId: 7000004262,
    company_name: "RIDDHI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069611: {
    comapnyId: 7000069611,
    company_name: "S V JEWELLERS",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000069674: {
    comapnyId: 7000069674,
    company_name: "GORDHANBHAI VIRJIBHAI JEWELLERS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000039221: {
    comapnyId: 7000039221,
    company_name: "NITMAN GOLD EXPORTS LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000010295: {
    comapnyId: 7000010295,
    company_name: "PARAS GOTTAM & COMPANY",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000037996: {
    comapnyId: 7000037996,
    company_name: "RIO GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000053134: {
    comapnyId: 7000053134,
    company_name: "JEWEL N DESIGN",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000005341: {
    comapnyId: 7000005341,
    company_name: "JEWELSMITH",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000061521: {
    comapnyId: 7000061521,
    company_name: "SUN GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000015147: {
    comapnyId: 7000015147,
    company_name: "SRI RAJ JEWELLERY",
    region: "region_1",
    section: "sectionData_9",
    area: 18,
  },
  7000008333: {
    comapnyId: 7000008333,
    company_name: "SRI KISHAN JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000044392: {
    comapnyId: 7000044392,
    company_name: "SORA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000047643: {
    comapnyId: 7000047643,
    company_name: "KRISHNA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000010326: {
    comapnyId: 7000010326,
    company_name: "PARTH DIAMOND PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000069826: {
    comapnyId: 7000069826,
    company_name: "TATVAM GOLD LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000035364: {
    comapnyId: 7000035364,
    company_name: "M. V. GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000069977: {
    comapnyId: 7000069977,
    company_name: "SILVENTIC JEWELS",
    region: "region_3",
    section: "sectionData_4",
    area: 18,
  },
  7000014043: {
    comapnyId: 7000014043,
    company_name: "ASHOK JEWELLERS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000058322: {
    comapnyId: 7000058322,
    company_name: "GOTI EXPORTS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000059156: {
    comapnyId: 7000059156,
    company_name: "N. J. ARTS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000037988: {
    comapnyId: 7000037988,
    company_name: "TATVAM JEWELS CORPORATION",
    region: "region_4",
    section: "sectionData_1",
    area: 27,
  },
  7000000218: {
    comapnyId: 7000000218,
    company_name: "AWESOME SPARKLERS",
    region: "region_3",
    section: "sectionData_10",
    area: 24,
  },
  7000006717: {
    comapnyId: 7000006717,
    company_name: "VAMA FINE JEWELS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000059791: {
    comapnyId: 7000059791,
    company_name: "RVJ JEWELLERS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 16,
  },
  7000003055: {
    comapnyId: 7000003055,
    company_name: "NAULAKHA JEWELLERS",
    region: "region_6",
    section: "sectionData_10",
    area: 24,
  },
  7000007082: {
    comapnyId: 7000007082,
    company_name: "AAROH JEWELS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000014807: {
    comapnyId: 7000014807,
    company_name: "RAWAT JEWELS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000016573: {
    comapnyId: 7000016573,
    company_name: "VIJAYRAJ & SONS",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000009103: {
    comapnyId: 7000009103,
    company_name: "SHIVAM JEWELS AND ARTS LLP",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000000494: {
    comapnyId: 7000000494,
    company_name: "DEEPAK JEWELLERS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000015643: {
    comapnyId: 7000015643,
    company_name: "GOLD ARTISM",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000057304: {
    comapnyId: 7000057304,
    company_name: "ASHTALAXMI JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000047345: {
    comapnyId: 7000047345,
    company_name: "PADMASHRI JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000005903: {
    comapnyId: 7000005903,
    company_name: "TIRTH JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000053138: {
    comapnyId: 7000053138,
    company_name: "SILVER EMPIRE",
    region: "region_1",
    section: "sectionData_4",
    area: 16,
  },
  7000060544: {
    comapnyId: 7000060544,
    company_name: "SHRI J. K. SILVER HOUSE",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000051050: {
    comapnyId: 7000051050,
    company_name: "SHREE BALAJI CHAIN & JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000017558: {
    comapnyId: 7000017558,
    company_name: "AMRUT GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 16,
  },
  7000052186: {
    comapnyId: 7000052186,
    company_name: "A. DARSHAN DIAM",
    region: "region_2",
    section: "sectionData_3",
    area: 16,
  },
  7000006931: {
    comapnyId: 7000006931,
    company_name: "PRISM INTERNATIONAL",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000014398: {
    comapnyId: 7000014398,
    company_name: "BEAUTIFUL JEWEL",
    region: "region_3",
    section: "sectionData_1",
    area: 16,
  },
  7000079935: {
    comapnyId: 7000079935,
    company_name: "TEYANA JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000001184: {
    comapnyId: 7000001184,
    company_name: "SILVER SEAL",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000048523: {
    comapnyId: 7000048523,
    company_name: "SUBH",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000047572: {
    comapnyId: 7000047572,
    company_name: "SADGURU JEWELLERS",
    region: "region_3",
    section: "sectionData_9",
    area: 16,
  },
  7000000621: {
    comapnyId: 7000000621,
    company_name: "SHREE RISHABH GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000038288: {
    comapnyId: 7000038288,
    company_name: "ENTER GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000009672: {
    comapnyId: 7000009672,
    company_name: "DHANLAXMI JEWELLERS",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000044114: {
    comapnyId: 7000044114,
    company_name: "RAJASTHAN SILVER HOUSE",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000001125: {
    comapnyId: 7000001125,
    company_name: "SHOBHAVAT JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000052108: {
    comapnyId: 7000052108,
    company_name: "S.MAHENDRAKUMAR DEVICHAND",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000061302: {
    comapnyId: 7000061302,
    company_name: "KLASSIC MOUNTS",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000052962: {
    comapnyId: 7000052962,
    company_name: "SAMBHAV JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000057271: {
    comapnyId: 7000057271,
    company_name: "NAVAM SUNIL JEWELLERS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000051709: {
    comapnyId: 7000051709,
    company_name: "MAHI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000053077: {
    comapnyId: 7000053077,
    company_name: "ARMAS JEWELS LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000058981: {
    comapnyId: 7000058981,
    company_name: "KIA DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000057275: {
    comapnyId: 7000057275,
    company_name: "TJ JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000052287: {
    comapnyId: 7000052287,
    company_name: "LAKSHMI GEMS AND JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000013023: {
    comapnyId: 7000013023,
    company_name: "SONI SHAILESH NANALAL JEWELLERS PVT. LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000050427: {
    comapnyId: 7000050427,
    company_name: "MOMENTS925 SILVER INDIA LLP",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000009297: {
    comapnyId: 7000009297,
    company_name: "MG SONS JEWELLERS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000007114: {
    comapnyId: 7000007114,
    company_name: "BANSIDHAR JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000007582: {
    comapnyId: 7000007582,
    company_name: "SIDDHI JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000052389: {
    comapnyId: 7000052389,
    company_name: "EFFULGENT JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000027915: {
    comapnyId: 7000027915,
    company_name: "RK DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000016925: {
    comapnyId: 7000016925,
    company_name: "EXQUISITE COLOR KRAFT PVT. LTD",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000000418: {
    comapnyId: 7000000418,
    company_name: "MANOJ ORNAMENTS PVT. LTD.",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000038473: {
    comapnyId: 7000038473,
    company_name: "KARNI JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000012180: {
    comapnyId: 7000012180,
    company_name: "RD FINE JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000007796: {
    comapnyId: 7000007796,
    company_name: "ASHOK JEWELLERS",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000061906: {
    comapnyId: 7000061906,
    company_name: "NAVYAA CREATION JEWELLERY PRIVATE LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000008595: {
    comapnyId: 7000008595,
    company_name: "GEM EXPORTS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000000536: {
    comapnyId: 7000000536,
    company_name: "DESIGN CREATION",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000009275: {
    comapnyId: 7000009275,
    company_name: "MARVEL CREATION",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000000923: {
    comapnyId: 7000000923,
    company_name: "MOKSH ORNAMENTS LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000016023: {
    comapnyId: 7000016023,
    company_name: "SUPERGEMS JEWELLERY MFG. PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000079806: {
    comapnyId: 7000079806,
    company_name: "AMBAJI JEWELLERS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000009946: {
    comapnyId: 7000009946,
    company_name: "J K JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000035588: {
    comapnyId: 7000035588,
    company_name: "SUMAN",
    region: "region_1",
    section: "sectionData_4",
    area: 18,
  },
  7000008226: {
    comapnyId: 7000008226,
    company_name: "RIDDHIMA CHAIN AND JEWELLERY",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000016009: {
    comapnyId: 7000016009,
    company_name: "SUNVIM EXPORTS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000079860: {
    comapnyId: 7000079860,
    company_name: "RATANSHINE JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000006975: {
    comapnyId: 7000006975,
    company_name: "NARAYAN GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000016922: {
    comapnyId: 7000016922,
    company_name: "PURE PLATINUM JEWELLERY  PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000015658: {
    comapnyId: 7000015658,
    company_name: "SILVER CREATIONS PVT. LTD.",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000011931: {
    comapnyId: 7000011931,
    company_name: "RAKYAN GEMS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000004731: {
    comapnyId: 7000004731,
    company_name: "VAISHNAVI GEMS & JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000003561: {
    comapnyId: 7000003561,
    company_name: "BLINGGG",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000010907: {
    comapnyId: 7000010907,
    company_name: "WONDERCUTS JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000008505: {
    comapnyId: 7000008505,
    company_name: "PARINA INTERNATIONAL",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000005881: {
    comapnyId: 7000005881,
    company_name: "RAJSHREE GEMS AND JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000006849: {
    comapnyId: 7000006849,
    company_name: "RISHAB JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000002195: {
    comapnyId: 7000002195,
    company_name: "BHAIRAV GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000052407: {
    comapnyId: 7000052407,
    company_name: "RUSHABHVINITLABDHIJEWELS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000008052: {
    comapnyId: 7000008052,
    company_name: "SIDDI VINAYAK EXPORT",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000013700: {
    comapnyId: 7000013700,
    company_name: "ABHAY NAVINCHANDRA",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000061426: {
    comapnyId: 7000061426,
    company_name: "VIJAY JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000016759: {
    comapnyId: 7000016759,
    company_name: "ZIVA JEWELLERY PVT.LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000009553: {
    comapnyId: 7000009553,
    company_name: "MEHTA GOLD AND DIAMONDS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000002133: {
    comapnyId: 7000002133,
    company_name: "SHRINGAR CREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000079661: {
    comapnyId: 7000079661,
    company_name: "GOLD STRINGS JEWELLERY PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_10",
    area: 24,
  },
  7000006964: {
    comapnyId: 7000006964,
    company_name: "REEVAZZ",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000059348: {
    comapnyId: 7000059348,
    company_name: "ACLASS DIAMOND LLP",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000001959: {
    comapnyId: 7000001959,
    company_name: "SHUBH JEWELLERY INDIA LLP",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000061576: {
    comapnyId: 7000061576,
    company_name: "MD GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000027819: {
    comapnyId: 7000027819,
    company_name: "SHREE SIDDHI VINAYAK JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000012682: {
    comapnyId: 7000012682,
    company_name: "TULSI DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000011473: {
    comapnyId: 7000011473,
    company_name: "N R GOLD & JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000013809: {
    comapnyId: 7000013809,
    company_name: "SEJAL JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000009491: {
    comapnyId: 7000009491,
    company_name: "RISHAB JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000010607: {
    comapnyId: 7000010607,
    company_name: "ANGEL JEWELS LLP",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000014408: {
    comapnyId: 7000014408,
    company_name: "BEAUTY GEMS & JEWELLERY",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000066539: {
    comapnyId: 7000066539,
    company_name: "DEEPA JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000014022: {
    comapnyId: 7000014022,
    company_name: "SHANTI S SETH JEWELLERS",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000011822: {
    comapnyId: 7000011822,
    company_name: "DIVAS JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000004559: {
    comapnyId: 7000004559,
    company_name: "ELEGANCE CREATION",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000011843: {
    comapnyId: 7000011843,
    company_name: "ZEVAR THE JEWELLERY WORLD",
    region: "region_4",
    section: "sectionData_2",
    area: 18,
  },
  7000016265: {
    comapnyId: 7000016265,
    company_name: "TRUE CASTE JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000010877: {
    comapnyId: 7000010877,
    company_name: "GEHNA JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_10",
    area: 24,
  },
  7000066164: {
    comapnyId: 7000066164,
    company_name: "OM DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000008896: {
    comapnyId: 7000008896,
    company_name: "MODI EXPORT HOUSE",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000004649: {
    comapnyId: 7000004649,
    company_name: "JATIN GEMS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000051190: {
    comapnyId: 7000051190,
    company_name: "RASHI ORNAMENTS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000005744: {
    comapnyId: 7000005744,
    company_name: "MANGALSUTRAM",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000008449: {
    comapnyId: 7000008449,
    company_name: "DIVINE JEWELS",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000012266: {
    comapnyId: 7000012266,
    company_name: "S R JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000008270: {
    comapnyId: 7000008270,
    company_name: "MANIRATANA EXPORTS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000005872: {
    comapnyId: 7000005872,
    company_name: "REAL PEARLS (BUNTY MOTIWALA)",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000050557: {
    comapnyId: 7000050557,
    company_name: "YUG GLOBAL PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000006066: {
    comapnyId: 7000006066,
    company_name: "VARLAKSHMI JEWELLERY",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000026588: {
    comapnyId: 7000026588,
    company_name: "MIVAA SILVER",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000051554: {
    comapnyId: 7000051554,
    company_name: "ROYAL GOLD",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000050492: {
    comapnyId: 7000050492,
    company_name: "MEENA JEWELLERS",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000003957: {
    comapnyId: 7000003957,
    company_name: "SWARN SHRISHTI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000005767: {
    comapnyId: 7000005767,
    company_name: "BHAVESH GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000012136: {
    comapnyId: 7000012136,
    company_name: "JINKUSHAL JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000009599: {
    comapnyId: 7000009599,
    company_name: "SAMTA JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000006061: {
    comapnyId: 7000006061,
    company_name: "ORVI LIFE STYLE PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000002635: {
    comapnyId: 7000002635,
    company_name: "SETS JEWELLERY PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000000021: {
    comapnyId: 7000000021,
    company_name: "N.J. GEMS",
    region: "region_2",
    section: "sectionData_7",
    area: 12,
  },
  7000014126: {
    comapnyId: 7000014126,
    company_name: "AURA CREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000063218: {
    comapnyId: 7000063218,
    company_name: "VEDIKA CREATIONS PRIVATE LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000059817: {
    comapnyId: 7000059817,
    company_name: "K. RAJ ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000052722: {
    comapnyId: 7000052722,
    company_name: "FUSION JEWELS INDIA",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000053276: {
    comapnyId: 7000053276,
    company_name: "VISHAL JEWELLERS",
    region: "region_3",
    section: "sectionData_4",
    area: 12,
  },
  7000008938: {
    comapnyId: 7000008938,
    company_name: "MOHIT DIAMONDS PVT. LTD",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000010667: {
    comapnyId: 7000010667,
    company_name: "DIAMOND CREATION",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003088: {
    comapnyId: 7000003088,
    company_name: "SUNDHA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003642: {
    comapnyId: 7000003642,
    company_name: "MANN SILVER",
    region: "region_2",
    section: "sectionData_4",
    area: 12,
  },
  7000038265: {
    comapnyId: 7000038265,
    company_name: "TAJ JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000066499: {
    comapnyId: 7000066499,
    company_name: "KATTAS GEMS & JEWELS",
    region: "region_4",
    section: "sectionData_4",
    area: 12,
  },
  7000059611: {
    comapnyId: 7000059611,
    company_name: "STRIBRNY CREATIONS LLP",
    region: "region_5",
    section: "sectionData_4",
    area: 12,
  },
  7000053541: {
    comapnyId: 7000053541,
    company_name: "SAMSAA",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000079986: {
    comapnyId: 7000079986,
    company_name: "MOONLIGHT PEARLS MUMBAI LLP",
    region: "region_2",
    section: "sectionData_9",
    area: 12,
  },
  7000063189: {
    comapnyId: 7000063189,
    company_name: "HUNNAR GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000038003: {
    comapnyId: 7000038003,
    company_name: "SHREE DEV JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003907: {
    comapnyId: 7000003907,
    company_name: "SANGHVI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000052820: {
    comapnyId: 7000052820,
    company_name: "BERRY GEMS GALLERY",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000042894: {
    comapnyId: 7000042894,
    company_name: "MOR GOLD JEWELLERS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000052700: {
    comapnyId: 7000052700,
    company_name: "SONIC JEWELLERS LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000006100: {
    comapnyId: 7000006100,
    company_name: "DASHRATH SILVER ART PRIVATE  LIMITED",
    region: "region_3",
    section: "sectionData_4",
    area: 12,
  },
  7000061141: {
    comapnyId: 7000061141,
    company_name: "TRISHUL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000047179: {
    comapnyId: 7000047179,
    company_name: "VIDHI JEWELS",
    region: "region_2",
    section: "sectionData_4",
    area: 12,
  },
  7000038423: {
    comapnyId: 7000038423,
    company_name: "4CS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000034340: {
    comapnyId: 7000034340,
    company_name: "SUNSHINE CORPORATION",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000026605: {
    comapnyId: 7000026605,
    company_name: "JAINAM CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000058474: {
    comapnyId: 7000058474,
    company_name: "SILVER SHADES",
    region: "region_2",
    section: "sectionData_4",
    area: 12,
  },
  7000068348: {
    comapnyId: 7000068348,
    company_name: "ANOOTHI JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000052949: {
    comapnyId: 7000052949,
    company_name: "PADMAWATI GEMS AND JEWELLERY LLP",
    region: "region_1",
    section: "sectionData_1",
    area: 12,
  },
  7000016446: {
    comapnyId: 7000016446,
    company_name: "VAISHALI EXPORTS",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000046089: {
    comapnyId: 7000046089,
    company_name: "INDIANA JEWEL",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000057645: {
    comapnyId: 7000057645,
    company_name: "THASHNA JEWELLERY",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000059472: {
    comapnyId: 7000059472,
    company_name: "HARI OM JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000007360: {
    comapnyId: 7000007360,
    company_name: "GARG GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000061098: {
    comapnyId: 7000061098,
    company_name: "SHREE AMBIKA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000044219: {
    comapnyId: 7000044219,
    company_name: "NUVO AEON DIAMOND AND JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_7",
    area: 12,
  },
  7000017328: {
    comapnyId: 7000017328,
    company_name: "DIAMOND AND GEM LABORATORIES  OF AMERICA. PVT. LTD",
    region: "region_2",
    section: "sectionData_5",
    area: 12,
  },
  7000011123: {
    comapnyId: 7000011123,
    company_name: "PUNAM GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 12,
  },
  7000052685: {
    comapnyId: 7000052685,
    company_name: "S K JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000053082: {
    comapnyId: 7000053082,
    company_name: "SHAKTI ENTERPRISE",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000011418: {
    comapnyId: 7000011418,
    company_name: "R.B. CHAINS",
    region: "region_6",
    section: "sectionData_4",
    area: 12,
  },
  7000066553: {
    comapnyId: 7000066553,
    company_name: "CHAINSKART",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000061040: {
    comapnyId: 7000061040,
    company_name: "MAYA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000069921: {
    comapnyId: 7000069921,
    company_name: "GOSI JEWELS INDIA",
    region: "region_4",
    section: "sectionData_1",
    area: 12,
  },
  7000037737: {
    comapnyId: 7000037737,
    company_name: "MAITY GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000060465: {
    comapnyId: 7000060465,
    company_name: "ATMIYA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000058649: {
    comapnyId: 7000058649,
    company_name: "B. D. JEWELLS",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000041051: {
    comapnyId: 7000041051,
    company_name: "PRAYOSHA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000067204: {
    comapnyId: 7000067204,
    company_name: "JH AND RH CHUDIWALA",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000061016: {
    comapnyId: 7000061016,
    company_name: "RADHA GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000060837: {
    comapnyId: 7000060837,
    company_name: "SHREE I JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000066954: {
    comapnyId: 7000066954,
    company_name: "SSP GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000061012: {
    comapnyId: 7000061012,
    company_name: "R. SONS JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000069773: {
    comapnyId: 7000069773,
    company_name: "MERRY GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000061116: {
    comapnyId: 7000061116,
    company_name: "DZIRE BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000068519: {
    comapnyId: 7000068519,
    company_name: "VIDHAN JEWELLS AND DIAMONDS PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000061387: {
    comapnyId: 7000061387,
    company_name: "RADHAKRISHNA JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000044503: {
    comapnyId: 7000044503,
    company_name: "AHELI JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000069543: {
    comapnyId: 7000069543,
    company_name: "R.R.GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000038070: {
    comapnyId: 7000038070,
    company_name: "REAL JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000004973: {
    comapnyId: 7000004973,
    company_name: "GHANSHYAM GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 12,
  },
  7000001925: {
    comapnyId: 7000001925,
    company_name: "SHREESHRISHTI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000057314: {
    comapnyId: 7000057314,
    company_name: "TJ GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000053365: {
    comapnyId: 7000053365,
    company_name: "HUNAR GOLD",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000002241: {
    comapnyId: 7000002241,
    company_name: "M.M. JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000060773: {
    comapnyId: 7000060773,
    company_name: "KIYAASH",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000008554: {
    comapnyId: 7000008554,
    company_name: "MIRAJ GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000003928: {
    comapnyId: 7000003928,
    company_name: "H.M.T. ORNAMENT",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000010916: {
    comapnyId: 7000010916,
    company_name: "SKY JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000009345: {
    comapnyId: 7000009345,
    company_name: "SEJAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000004566: {
    comapnyId: 7000004566,
    company_name: "STUTI JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000010493: {
    comapnyId: 7000010493,
    company_name: "SPHERE",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000013190: {
    comapnyId: 7000013190,
    company_name: "PRIDE GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000060819: {
    comapnyId: 7000060819,
    company_name: "SMRITI JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000001983: {
    comapnyId: 7000001983,
    company_name: "VEER JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000014916: {
    comapnyId: 7000014916,
    company_name: "SHREE BHOMIYA GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000017376: {
    comapnyId: 7000017376,
    company_name: "MANIL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000002059: {
    comapnyId: 7000002059,
    company_name: "HEMRATNA JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000002357: {
    comapnyId: 7000002357,
    company_name: "LURBA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000012268: {
    comapnyId: 7000012268,
    company_name: "CUT N CABS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000016796: {
    comapnyId: 7000016796,
    company_name: "BADHALIA GEMS & JEWELLERY",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000002295: {
    comapnyId: 7000002295,
    company_name: "DREAMS BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000015505: {
    comapnyId: 7000015505,
    company_name: "SHREE DIAMOND",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000009434: {
    comapnyId: 7000009434,
    company_name: "V. NARENDRA & CO",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000016244: {
    comapnyId: 7000016244,
    company_name: "TRADITIONS",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000012338: {
    comapnyId: 7000012338,
    company_name: "GOLDTHEME GEMS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 16,
  },
  7000002081: {
    comapnyId: 7000002081,
    company_name: "ROSE GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000044368: {
    comapnyId: 7000044368,
    company_name: "SMILING ROCKS",
    region: "region_2",
    section: "sectionData_7",
    area: 9,
  },
  7000002657: {
    comapnyId: 7000002657,
    company_name: "GLITTER JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000003327: {
    comapnyId: 7000003327,
    company_name: "PRABHA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000007773: {
    comapnyId: 7000007773,
    company_name: "RUBBY GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000005811: {
    comapnyId: 7000005811,
    company_name: "VARSHIP JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000013730: {
    comapnyId: 7000013730,
    company_name: "KP SANGHVI JEWELS PVT. LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 108,
  },
  7000000102: {
    comapnyId: 7000000102,
    company_name: "DITI JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 12,
  },
  7000004176: {
    comapnyId: 7000004176,
    company_name: "HARDIK ZAVERI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000015016: {
    comapnyId: 7000015016,
    company_name: "KULTHIAA JEWEL PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_1",
    area: 16,
  },
  7000038833: {
    comapnyId: 7000038833,
    company_name: "EZARA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000004239: {
    comapnyId: 7000004239,
    company_name: "R.K. GEMS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000070052: {
    comapnyId: 7000070052,
    company_name: "SONI BHARATKUMAR MATHURADAS",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000045717: {
    comapnyId: 7000045717,
    company_name: "NITIN JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000002492: {
    comapnyId: 7000002492,
    company_name: "GEM IMPEX",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000012075: {
    comapnyId: 7000012075,
    company_name: "ZUNDAA",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000007544: {
    comapnyId: 7000007544,
    company_name: "R.S. JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010456: {
    comapnyId: 7000010456,
    company_name: "SRI MARUTI JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000038276: {
    comapnyId: 7000038276,
    company_name: "JAIPUR EMPORIUM JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000015823: {
    comapnyId: 7000015823,
    company_name: "SRI VIJAY DURGA MAHASHAKTI JEWELLERS & EXPORTERS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000038316: {
    comapnyId: 7000038316,
    company_name: "UDAY JEWELS",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000038032: {
    comapnyId: 7000038032,
    company_name: "SANGAM JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000038393: {
    comapnyId: 7000038393,
    company_name: "MEHTA JEWELLERS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000002250: {
    comapnyId: 7000002250,
    company_name: "ORNA JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000014854: {
    comapnyId: 7000014854,
    company_name: "DIA-M-ART",
    region: "region_4",
    section: "sectionData_1",
    area: 16,
  },
  7000003356: {
    comapnyId: 7000003356,
    company_name: "PRAVIN MOTIWALA",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000037722: {
    comapnyId: 7000037722,
    company_name: "VAID JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 12,
  },
  7000013126: {
    comapnyId: 7000013126,
    company_name: "ZEST CORPORATION",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000001213: {
    comapnyId: 7000001213,
    company_name: "DIMPEX MANUFACTURING CO",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000034368: {
    comapnyId: 7000034368,
    company_name: "U. T. ZAVERI & SONS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000037490: {
    comapnyId: 7000037490,
    company_name: "FIRST DIVINITY DIAMONDS PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000013148: {
    comapnyId: 7000013148,
    company_name: "ELA DESIGNER JOOLRY",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000001910: {
    comapnyId: 7000001910,
    company_name: "RITIKA CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000014795: {
    comapnyId: 7000014795,
    company_name: "KAILASHNATH & CO",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000052198: {
    comapnyId: 7000052198,
    company_name: "GEETA SHYAM JEWELLERS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000000253: {
    comapnyId: 7000000253,
    company_name: "FILO DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000004861: {
    comapnyId: 7000004861,
    company_name: "LABDHI  DIAMOND",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000079976: {
    comapnyId: 7000079976,
    company_name: "SUNBERA INDIA PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000006598: {
    comapnyId: 7000006598,
    company_name: "KRIPA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000006055: {
    comapnyId: 7000006055,
    company_name: "CREATIVE JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000010781: {
    comapnyId: 7000010781,
    company_name: "ROMIL JEWELRY",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000013097: {
    comapnyId: 7000013097,
    company_name: "VIJAYRAJ JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000008816: {
    comapnyId: 7000008816,
    company_name: "AB JEWELS PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000057971: {
    comapnyId: 7000057971,
    company_name: "DAIWIK JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000048125: {
    comapnyId: 7000048125,
    company_name: "PARAMPARA THE JEWELS",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000009175: {
    comapnyId: 7000009175,
    company_name: "MYRA THE DIAMOND STUDIO",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000007785: {
    comapnyId: 7000007785,
    company_name: "TIBARUMAL RAMNIVAS GEMS  JEWELS & PEARLS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000038375: {
    comapnyId: 7000038375,
    company_name: "BIRDICHAND GANDHI JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000001361: {
    comapnyId: 7000001361,
    company_name: "PURVI GEMS & JEWELLERY INDIA PVT.LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000007891: {
    comapnyId: 7000007891,
    company_name: "MERULIYA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 16,
  },
  7000009643: {
    comapnyId: 7000009643,
    company_name: "R B JEWELS AND GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000069669: {
    comapnyId: 7000069669,
    company_name: "ZIANA DIAMOND JEWELLERY",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000008203: {
    comapnyId: 7000008203,
    company_name: "MANI JEWEL",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000017389: {
    comapnyId: 7000017389,
    company_name: "R.K. INTERNATIONALS",
    region: "region_4",
    section: "sectionData_1",
    area: 16,
  },
  7000006914: {
    comapnyId: 7000006914,
    company_name: "AKSHAR JEWEL",
    region: "region_3",
    section: "sectionData_1",
    area: 16,
  },
  7000013760: {
    comapnyId: 7000013760,
    company_name: "KRISHNAM DIAMOND AND JEWELLERS   PVT. LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000015301: {
    comapnyId: 7000015301,
    company_name: "SEJAL EXPORTS (INDIA)",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000000463: {
    comapnyId: 7000000463,
    company_name: "MUNNII GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000011407: {
    comapnyId: 7000011407,
    company_name: "AVI DESIGNER DIAMOND",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000068072: {
    comapnyId: 7000068072,
    company_name: "AMYRAH CREATIONS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000066483: {
    comapnyId: 7000066483,
    company_name: "SRI AAKRITI JEWELS",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000061102: {
    comapnyId: 7000061102,
    company_name: "SWASTIK JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000006632: {
    comapnyId: 7000006632,
    company_name: "TVISHAJEWLZ",
    region: "region_4",
    section: "sectionData_1",
    area: 12,
  },
  7000003352: {
    comapnyId: 7000003352,
    company_name: "J.S JEWEL OM LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000051507: {
    comapnyId: 7000051507,
    company_name: "TUSHAR JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 12,
  },
  7000007855: {
    comapnyId: 7000007855,
    company_name: "RAJWADA JEWELS OF JAIPUR",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000067575: {
    comapnyId: 7000067575,
    company_name: "STAR JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000006444: {
    comapnyId: 7000006444,
    company_name: "KASVI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000067505: {
    comapnyId: 7000067505,
    company_name: "IDT GEMOLOGICAL LABORATORIES WORLDWIDE PVT. LTD.",
    region: "region_6",
    section: "sectionData_5",
    area: 9,
  },
  7000066272: {
    comapnyId: 7000066272,
    company_name: "VIHIT INVESTMENTS",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000053468: {
    comapnyId: 7000053468,
    company_name: "SRUJAN JEWELLERS INDIA PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000003246: {
    comapnyId: 7000003246,
    company_name: "R.V.J.ENTERPRISES PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000017518: {
    comapnyId: 7000017518,
    company_name: "AMDEAN EXPORTS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000067822: {
    comapnyId: 7000067822,
    company_name: "JAINA DIAMOND AND GOLD PVT LTD",
    region: "region_4",
    section: "sectionData_7",
    area: 18,
  },
  7000007928: {
    comapnyId: 7000007928,
    company_name: "M.H. KARBAWALA & CO.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000012330: {
    comapnyId: 7000012330,
    company_name: "SOLITAIRE JEWELLERY PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000052985: {
    comapnyId: 7000052985,
    company_name: "M.K. JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000003041: {
    comapnyId: 7000003041,
    company_name: "NINE SIL JEWELLERY",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000005822: {
    comapnyId: 7000005822,
    company_name: "RANKA TRADERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000038447: {
    comapnyId: 7000038447,
    company_name: "PRAVIN ORNAMENTS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000001019: {
    comapnyId: 7000001019,
    company_name: "ANAN JEWELS INDIA",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000058500: {
    comapnyId: 7000058500,
    company_name: "TAHI JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000070017: {
    comapnyId: 7000070017,
    company_name: "SHREE JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000014194: {
    comapnyId: 7000014194,
    company_name: "B. SETHIA & SONS (JEWELLERS)",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000016440: {
    comapnyId: 7000016440,
    company_name: "VAIBHAV JEWELLERS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000058685: {
    comapnyId: 7000058685,
    company_name: "GAINFUL GEMS & JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000016314: {
    comapnyId: 7000016314,
    company_name: "JASANI INDIA PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000015550: {
    comapnyId: 7000015550,
    company_name: "SHREE RAMKRISHNA EXPORTS P.LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000009600: {
    comapnyId: 7000009600,
    company_name: "NICETRENDS JEWELS (I) PVT.LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000006378: {
    comapnyId: 7000006378,
    company_name: "EON JEWELLERY PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000002315: {
    comapnyId: 7000002315,
    company_name: "SHRI RAM JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000011064: {
    comapnyId: 7000011064,
    company_name: "LAXMI JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000051909: {
    comapnyId: 7000051909,
    company_name: "SHASHWAT ORNAMENTS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000069823: {
    comapnyId: 7000069823,
    company_name: "GOYAL BROTHERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000002248: {
    comapnyId: 7000002248,
    company_name: "J K DIAMONDS INSTITUTE OF GEMS & JEWELRY",
    region: "region_2",
    section: "sectionData_5",
    area: 9,
  },
  7000053583: {
    comapnyId: 7000053583,
    company_name: "AURO DIAM EXPORTS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000069908: {
    comapnyId: 7000069908,
    company_name: "MTJ WORLD INDIA PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000014321: {
    comapnyId: 7000014321,
    company_name: "BALAJI SILVERCRAFTS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000010922: {
    comapnyId: 7000010922,
    company_name: "BR DESIGNS PVT.LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000044592: {
    comapnyId: 7000044592,
    company_name: "SAUNAY JEWELS PVT.LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000078469: {
    comapnyId: 7000078469,
    company_name: "LAXMI IMPERIAL PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 54,
  },
  7000060973: {
    comapnyId: 7000060973,
    company_name: "S SAGAR ENTERPRISE",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000008232: {
    comapnyId: 7000008232,
    company_name: "SHILPAM JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000014924: {
    comapnyId: 7000014924,
    company_name: "RAMESHCHAND & SONS J EWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000016833: {
    comapnyId: 7000016833,
    company_name: "AURA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000003961: {
    comapnyId: 7000003961,
    company_name: "S.S.CHAINS JEWELLERY  PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000008395: {
    comapnyId: 7000008395,
    company_name: "MEENAKSHI JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000004702: {
    comapnyId: 7000004702,
    company_name: "BINNYS JEWELLERY PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000015276: {
    comapnyId: 7000015276,
    company_name: "SATYAM GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000066406: {
    comapnyId: 7000066406,
    company_name: "KATYAYANI JEWELS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 12,
  },
  7000003212: {
    comapnyId: 7000003212,
    company_name: "PANI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000010831: {
    comapnyId: 7000010831,
    company_name: "PRAVEEN JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000015725: {
    comapnyId: 7000015725,
    company_name: "SMIT GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000017661: {
    comapnyId: 7000017661,
    company_name: "ARHAM JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000069613: {
    comapnyId: 7000069613,
    company_name: "MEGHA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000044701: {
    comapnyId: 7000044701,
    company_name: "KRYSTHAL JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000004246: {
    comapnyId: 7000004246,
    company_name: "GEMART",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000002807: {
    comapnyId: 7000002807,
    company_name: "HAPPY GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000038108: {
    comapnyId: 7000038108,
    company_name: "TISHYAM HAPPY PEARLS LLP",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000037528: {
    comapnyId: 7000037528,
    company_name: "KANAK GEM & JEWELRY",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000017182: {
    comapnyId: 7000017182,
    company_name: "KKM GOLD",
    region: "region_6",
    section: "sectionData_2",
    area: 16,
  },
  7000049818: {
    comapnyId: 7000049818,
    company_name: "XAZINA DIAMONDS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000016525: {
    comapnyId: 7000016525,
    company_name: "VEER GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000079918: {
    comapnyId: 7000079918,
    company_name: "DHANUSH LABGROWN PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000006702: {
    comapnyId: 7000006702,
    company_name: "ANAND DARSHAN SILVERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000012714: {
    comapnyId: 7000012714,
    company_name: "ROSY BLUE (INDIA) PVT. LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000049732: {
    comapnyId: 7000049732,
    company_name: "SIDDHACHAL JEWELLERS",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000047650: {
    comapnyId: 7000047650,
    company_name: "SANGEETA DIAMONDS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000005734: {
    comapnyId: 7000005734,
    company_name: "KANTILAL CHHOTALAL",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000012314: {
    comapnyId: 7000012314,
    company_name: "RE JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000026642: {
    comapnyId: 7000026642,
    company_name: "RJ JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000061119: {
    comapnyId: 7000061119,
    company_name: "AARNA JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000012321: {
    comapnyId: 7000012321,
    company_name: "VINAYAK JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000061074: {
    comapnyId: 7000061074,
    company_name: "SAMSARA",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000057256: {
    comapnyId: 7000057256,
    company_name: "RAJ SHREE JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000000200: {
    comapnyId: 7000000200,
    company_name: "ARIHANT JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000066349: {
    comapnyId: 7000066349,
    company_name: "JEWEL SAGA",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000076281: {
    comapnyId: 7000076281,
    company_name: "CAARA JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000009664: {
    comapnyId: 7000009664,
    company_name: "RUCHITA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000008349: {
    comapnyId: 7000008349,
    company_name: "MARUTI DIAMONDS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000007252: {
    comapnyId: 7000007252,
    company_name: "NIBHA GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000008326: {
    comapnyId: 7000008326,
    company_name: "MANUBHAI ZAVERI ORNAMENTS PVT. LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000070360: {
    comapnyId: 7000070360,
    company_name: "SHREEYASH JEWELLERS (INDIA) PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000003988: {
    comapnyId: 7000003988,
    company_name: "MERU DIAMOND (I) PVT.LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000027838: {
    comapnyId: 7000027838,
    company_name: "ADASTRA JEWELRY PVT. LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000013277: {
    comapnyId: 7000013277,
    company_name: "S.R. JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000066862: {
    comapnyId: 7000066862,
    company_name: "A G JEWELS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000000499: {
    comapnyId: 7000000499,
    company_name: "SPARK JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000003529: {
    comapnyId: 7000003529,
    company_name: "HARSH JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000009334: {
    comapnyId: 7000009334,
    company_name: "J.P.GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000004152: {
    comapnyId: 7000004152,
    company_name: "NIRMAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000000455: {
    comapnyId: 7000000455,
    company_name: "DEEJAY",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000037981: {
    comapnyId: 7000037981,
    company_name: "LABDHI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000048368: {
    comapnyId: 7000048368,
    company_name: "MILAN JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000001882: {
    comapnyId: 7000001882,
    company_name: "BISHWANATH GEMS AND JEWELS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000045028: {
    comapnyId: 7000045028,
    company_name: "SANJAY KUMAR BAVISHI AND SON",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000078349: {
    comapnyId: 7000078349,
    company_name: "AROHAA DIAMOND JEWELLERY PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000047403: {
    comapnyId: 7000047403,
    company_name: "KUNDAN REFINERY PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 12,
  },
  7000052627: {
    comapnyId: 7000052627,
    company_name: "AUSPERA INDIA PVT LTD",
    region: "region_1",
    section: "sectionData_4",
    area: 9,
  },
  7000003084: {
    comapnyId: 7000003084,
    company_name: "GEMS & JEWELS PALACE",
    region: "region_4",
    section: "sectionData_1",
    area: 16,
  },
  7000068345: {
    comapnyId: 7000068345,
    company_name: "AAYU JEWELS LLP",
    region: "region_2",
    section: "sectionData_7",
    area: 9,
  },
  7000062075: {
    comapnyId: 7000062075,
    company_name: "KUNDAN & ZEYA LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 9,
  },
  7000067328: {
    comapnyId: 7000067328,
    company_name: "SETH SUNDERDAS PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000061288: {
    comapnyId: 7000061288,
    company_name: "ANKIT MALPANI",
    region: "region_4",
    section: "sectionData_1",
    area: 16,
  },
  7000070040: {
    comapnyId: 7000070040,
    company_name: "TANAY JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 12,
  },
  7000050304: {
    comapnyId: 7000050304,
    company_name: "JET GEMS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000015605: {
    comapnyId: 7000015605,
    company_name: "SHUBH DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000049698: {
    comapnyId: 7000049698,
    company_name: "GANAPATI JEWELLERS CRAFTS PVT. LTD.",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000011762: {
    comapnyId: 7000011762,
    company_name: "SPARSH DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000038389: {
    comapnyId: 7000038389,
    company_name: "RAJMANI JEWELS & CO.",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000007159: {
    comapnyId: 7000007159,
    company_name: "RATILAL NAJABHAI",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000000744: {
    comapnyId: 7000000744,
    company_name: "UV JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000003204: {
    comapnyId: 7000003204,
    company_name: "HARIKRISHNA GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000048290: {
    comapnyId: 7000048290,
    company_name: "SAMBHAV GEMS INTERNATIONAL",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000069839: {
    comapnyId: 7000069839,
    company_name: "RIYA GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000007129: {
    comapnyId: 7000007129,
    company_name: "MOHAR DIAMONDS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000026482: {
    comapnyId: 7000026482,
    company_name: "MODI CREATIONS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000019020: {
    comapnyId: 7000019020,
    company_name: "DIORO",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000057615: {
    comapnyId: 7000057615,
    company_name: "ZORBA DIAMOND PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000047313: {
    comapnyId: 7000047313,
    company_name: "JAYSHANKAR GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000052827: {
    comapnyId: 7000052827,
    company_name: "ATHARV JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000019081: {
    comapnyId: 7000019081,
    company_name: "UJALA ART JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000059066: {
    comapnyId: 7000059066,
    company_name: "ENDLESS DIAMOND",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000044505: {
    comapnyId: 7000044505,
    company_name: "NR JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000037982: {
    comapnyId: 7000037982,
    company_name: "RAJGURU GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000000523: {
    comapnyId: 7000000523,
    company_name: "SHREE SHARDA CHAINS PVT. LTD.",
    region: "region_6",
    section: "sectionData_2",
    area: 9,
  },
  7000037999: {
    comapnyId: 7000037999,
    company_name: "OMKAR JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000006653: {
    comapnyId: 7000006653,
    company_name: "SAINATH JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000001366: {
    comapnyId: 7000001366,
    company_name: "DWARKA GEMS  LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000060848: {
    comapnyId: 7000060848,
    company_name: "ADVIKA CREATION",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000061104: {
    comapnyId: 7000061104,
    company_name: "RAJSHREE GEMS AND JEWELS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000050586: {
    comapnyId: 7000050586,
    company_name: "LEELA GOLD",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000001350: {
    comapnyId: 7000001350,
    company_name: "JAIPUR JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000070038: {
    comapnyId: 7000070038,
    company_name: "KHRYSA DESIGNS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000002757: {
    comapnyId: 7000002757,
    company_name: "GOLANK DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000069697: {
    comapnyId: 7000069697,
    company_name: "KHATORIA GEM HOUSE",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000008521: {
    comapnyId: 7000008521,
    company_name: "GORDHANDAS NANDKISHORE SARRAF & JEWELLERS",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000000345: {
    comapnyId: 7000000345,
    company_name: "NAKODA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000070048: {
    comapnyId: 7000070048,
    company_name: "ALTIER JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000015341: {
    comapnyId: 7000015341,
    company_name: "SHAH VANAJI KESAJI & CO.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000003151: {
    comapnyId: 7000003151,
    company_name: "POOJA JEWELLS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000006675: {
    comapnyId: 7000006675,
    company_name: "ISHA GEMS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000011478: {
    comapnyId: 7000011478,
    company_name: "R.K. GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000007230: {
    comapnyId: 7000007230,
    company_name: "OM JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000067711: {
    comapnyId: 7000067711,
    company_name: "D-ORNIVAA",
    region: "region_3",
    section: "sectionData_1",
    area: 12,
  },
  7000000571: {
    comapnyId: 7000000571,
    company_name: "NAVRATAN GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000035664: {
    comapnyId: 7000035664,
    company_name: "GEMMOLOGICAL INSTITUTE OF INDIA",
    region: "region_2",
    section: "sectionData_5",
    area: 9,
  },
  7000008480: {
    comapnyId: 7000008480,
    company_name: "TANVIRKUMAR & CO.",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000007277: {
    comapnyId: 7000007277,
    company_name: "KRISHNA BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000014723: {
    comapnyId: 7000014723,
    company_name: "C.M. GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000004280: {
    comapnyId: 7000004280,
    company_name: "MAYFAIR CREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000010507: {
    comapnyId: 7000010507,
    company_name: "PLATINUM GUILD INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000015751: {
    comapnyId: 7000015751,
    company_name: "SONI BACHUBHAI JIVABHAI",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000016137: {
    comapnyId: 7000016137,
    company_name: "TARANG DIAMOND",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000064234: {
    comapnyId: 7000064234,
    company_name: "RRJ ENTERPRISE",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000017196: {
    comapnyId: 7000017196,
    company_name: "PEEYAR MANUFACTURERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069359: {
    comapnyId: 7000069359,
    company_name: "DHRUV DIAMONDS",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000017236: {
    comapnyId: 7000017236,
    company_name: "BHAGWATI JEWELLERS DILIP ZAVERI",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000001749: {
    comapnyId: 7000001749,
    company_name: "JAY BHAVANI JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000054188: {
    comapnyId: 7000054188,
    company_name: "RUMI JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000006299: {
    comapnyId: 7000006299,
    company_name: "ARIHANT DIAMOND INSTITUTE",
    region: "region_3",
    section: "sectionData_5",
    area: 9,
  },
  7000000912: {
    comapnyId: 7000000912,
    company_name: "DIALMAZ",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000001439: {
    comapnyId: 7000001439,
    company_name: "ARIHANT DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000005252: {
    comapnyId: 7000005252,
    company_name: "TAPTI JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 16,
  },
  7000000734: {
    comapnyId: 7000000734,
    company_name: "DHARMANANDAN DIAMONDS PVT. LTD",
    region: "region_2",
    section: "sectionData_3",
    area: 72,
  },
  7000004488: {
    comapnyId: 7000004488,
    company_name: "JASH DIAJEWELS PVT.LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000035670: {
    comapnyId: 7000035670,
    company_name: "CREATIVE TECHNOLOGIES",
    region: "region_3",
    section: "sectionData_6",
    area: 18,
  },
  7000000321: {
    comapnyId: 7000000321,
    company_name: "PALSANI JEWELLERS PVT. LTD.",
    region: "region_5",
    section: "sectionData_1",
    area: 18,
  },
  7000061087: {
    comapnyId: 7000061087,
    company_name: "KALAVATHI JEWELLERSS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000051048: {
    comapnyId: 7000051048,
    company_name: "JANVI GEMS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000002473: {
    comapnyId: 7000002473,
    company_name: "GEM CRAFTS ENTERPRISES PVT. LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000049843: {
    comapnyId: 7000049843,
    company_name: "NOLKHA BROTHERS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000006751: {
    comapnyId: 7000006751,
    company_name: "SOMIRA DIAM",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000000247: {
    comapnyId: 7000000247,
    company_name: "SUNITA JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000067955: {
    comapnyId: 7000067955,
    company_name: "GOURAV GEMS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000013862: {
    comapnyId: 7000013862,
    company_name: "V J JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000061052: {
    comapnyId: 7000061052,
    company_name: "KANCHANS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000003361: {
    comapnyId: 7000003361,
    company_name: "MITAL JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 16,
  },
  7000037476: {
    comapnyId: 7000037476,
    company_name: "AMAIRA JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000016131: {
    comapnyId: 7000016131,
    company_name: "TAPTI EXPORTS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000003323: {
    comapnyId: 7000003323,
    company_name: "CHOKSHI VACHHARAJ MAKANJI & COMPANY",
    region: "region_3",
    section: "sectionData_1",
    area: 36,
  },
  7000016790: {
    comapnyId: 7000016790,
    company_name: "DHEEMAN DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000048332: {
    comapnyId: 7000048332,
    company_name: "PLATA ZONE",
    region: "region_1",
    section: "sectionData_4",
    area: 12,
  },
  7000016838: {
    comapnyId: 7000016838,
    company_name: "HEMRATNA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000059107: {
    comapnyId: 7000059107,
    company_name: "YASHEEKA ORNAMENTS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000011486: {
    comapnyId: 7000011486,
    company_name: "P. P. GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000051525: {
    comapnyId: 7000051525,
    company_name: "SHINESTONE DIAM LLP",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000035776: {
    comapnyId: 7000035776,
    company_name: "JEWEL PARADISE",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000013147: {
    comapnyId: 7000013147,
    company_name: "SHAGUN JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000079848: {
    comapnyId: 7000079848,
    company_name: "SHANTI JEWELLERS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069641: {
    comapnyId: 7000069641,
    company_name: "ARHAM GEMS & JEWELS",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000006067: {
    comapnyId: 7000006067,
    company_name: "AARNA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000006281: {
    comapnyId: 7000006281,
    company_name: "KESHAVLAL DALPATBHAI ZAVERI & CO",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000062025: {
    comapnyId: 7000062025,
    company_name: "EMERGEDIAM LLP",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000012008: {
    comapnyId: 7000012008,
    company_name: "SHAKTI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000002065: {
    comapnyId: 7000002065,
    company_name: "ALPSTAR DESIGNER",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000017179: {
    comapnyId: 7000017179,
    company_name: "JALAN JEWELLERS PVT. LTD.",
    region: "region_5",
    section: "sectionData_1",
    area: 18,
  },
  7000005526: {
    comapnyId: 7000005526,
    company_name: "K.J. GEMS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000007145: {
    comapnyId: 7000007145,
    company_name: "DARPAN",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000014964: {
    comapnyId: 7000014964,
    company_name: "SHIV NARAYAN JEWELLE RS PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000057555: {
    comapnyId: 7000057555,
    company_name: "SANTLAL JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000000369: {
    comapnyId: 7000000369,
    company_name: "DAMARA GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000005151: {
    comapnyId: 7000005151,
    company_name: "PUSHPAK JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000017208: {
    comapnyId: 7000017208,
    company_name: "G.M. GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000014071: {
    comapnyId: 7000014071,
    company_name: "KIK JEWELLS PVT. LTD.",
    region: "region_5",
    section: "sectionData_2",
    area: 16,
  },
  7000003777: {
    comapnyId: 7000003777,
    company_name: "INTERNATIONAL GEMOLOGICAL INS T. (I) PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000070010: {
    comapnyId: 7000070010,
    company_name: "SKYRAH JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000006745: {
    comapnyId: 7000006745,
    company_name: "SURAAJ JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000048346: {
    comapnyId: 7000048346,
    company_name: "MANIRATNA CHAINS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000035010: {
    comapnyId: 7000035010,
    company_name: "AVIRA GOLD LLP",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000015068: {
    comapnyId: 7000015068,
    company_name: "HARSH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000057607: {
    comapnyId: 7000057607,
    company_name: "HEERA JEWELS AND GEMS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 54,
  },
  7000053151: {
    comapnyId: 7000053151,
    company_name: "DR JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000007797: {
    comapnyId: 7000007797,
    company_name: "ANMOL JEWELLERS",
    region: "region_1",
    section: "sectionData_4",
    area: 9,
  },
  7000009401: {
    comapnyId: 7000009401,
    company_name: "NAQSH COLLECTION",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000078465: {
    comapnyId: 7000078465,
    company_name: "MOON EXPORTS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000004181: {
    comapnyId: 7000004181,
    company_name: "VINAYAKA JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000038280: {
    comapnyId: 7000038280,
    company_name: "SGL LABS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_5",
    area: 27,
  },
  7000034772: {
    comapnyId: 7000034772,
    company_name: "BAPALAL KESHAVLAL",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000009018: {
    comapnyId: 7000009018,
    company_name: "AANGI CREATION",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000012657: {
    comapnyId: 7000012657,
    company_name: "RISHABH ABUSHAN PVT. LTD.",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000015411: {
    comapnyId: 7000015411,
    company_name: "SHASHVAT JEWELS PRIVATE    LIMITED",
    region: "region_3",
    section: "sectionData_7",
    area: 18,
  },
  7000008841: {
    comapnyId: 7000008841,
    company_name: "GLITTER JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000069574: {
    comapnyId: 7000069574,
    company_name: "MANDAWARA JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 12,
  },
  7000009135: {
    comapnyId: 7000009135,
    company_name: "MURARI EXPORTS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000012863: {
    comapnyId: 7000012863,
    company_name: "RUCHI EXPORTS",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000013722: {
    comapnyId: 7000013722,
    company_name: "SUNIL GOLD INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000006947: {
    comapnyId: 7000006947,
    company_name: "SKSM DIAMONDS IMPEX LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000002994: {
    comapnyId: 7000002994,
    company_name: "WHITE FIRE DIAMONDS INDIA  PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000008087: {
    comapnyId: 7000008087,
    company_name: "MAHEK GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000070065: {
    comapnyId: 7000070065,
    company_name: "TRIJAL ENTERPRISES PVT. LTD.",
    region: "region_5",
    section: "sectionData_2",
    area: 12,
  },
  7000063696: {
    comapnyId: 7000063696,
    company_name: "S P GOLD",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000008638: {
    comapnyId: 7000008638,
    company_name: "NAVYA GEMS AND JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000053094: {
    comapnyId: 7000053094,
    company_name: "PR JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000010608: {
    comapnyId: 7000010608,
    company_name: "VIRANI EXPORTS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000038267: {
    comapnyId: 7000038267,
    company_name: "M. SASHI BADALIA & SONS PVT. LTD.",
    region: "region_5",
    section: "sectionData_3",
    area: 36,
  },
  7000061146: {
    comapnyId: 7000061146,
    company_name: "K C JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 9,
  },
  7000015295: {
    comapnyId: 7000015295,
    company_name: "SCINTILLATING JEWELLERY",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000011269: {
    comapnyId: 7000011269,
    company_name: "MANGAL MANI JEWELLERS PVT.  LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000004123: {
    comapnyId: 7000004123,
    company_name: "ILLUSION SOLITAIRE DIAMOND JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000002162: {
    comapnyId: 7000002162,
    company_name: "WORLD GOLD COUNCIL (I) PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000000560: {
    comapnyId: 7000000560,
    company_name: "GOEL JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000015451: {
    comapnyId: 7000015451,
    company_name: "SHINESHILPI JEWELLERS PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000000743: {
    comapnyId: 7000000743,
    company_name: "DHARMESH JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000002479: {
    comapnyId: 7000002479,
    company_name: "GEMOLOGICAL SCIENCE  INTERNATIONAL PVT.LTD",
    region: "region_2",
    section: "sectionData_5",
    area: 36,
  },
  7000058475: {
    comapnyId: 7000058475,
    company_name: "KEEVA DIAMOND",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000005712: {
    comapnyId: 7000005712,
    company_name: "KANAKRATNA EXIM PVT.LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000001385: {
    comapnyId: 7000001385,
    company_name: "DESIGNER JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000017363: {
    comapnyId: 7000017363,
    company_name: "IMAGINARIUM (INDIA) PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000012470: {
    comapnyId: 7000012470,
    company_name: "ARIHANT JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000079579: {
    comapnyId: 7000079579,
    company_name: "SWASTIK GOLDCORP PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000001918: {
    comapnyId: 7000001918,
    company_name: "SAINATH JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000011400: {
    comapnyId: 7000011400,
    company_name: "JUNAGADH JEWELLERY PVT. LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000008275: {
    comapnyId: 7000008275,
    company_name: "MOMAI JEWELLERS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000003432: {
    comapnyId: 7000003432,
    company_name: "MRK GOLD LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000000849: {
    comapnyId: 7000000849,
    company_name: "PUNCHMUKHI DIAMOND JEWELLERY  PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000017620: {
    comapnyId: 7000017620,
    company_name: "ANSH JEWELS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000007634: {
    comapnyId: 7000007634,
    company_name: "PRIVAA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000013116: {
    comapnyId: 7000013116,
    company_name: "NAKSHATRA GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000001203: {
    comapnyId: 7000001203,
    company_name: "VINAYAK GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000012079: {
    comapnyId: 7000012079,
    company_name: "RAPAPORT INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_5",
    area: 9,
  },
  7000002907: {
    comapnyId: 7000002907,
    company_name: "MYSTIQUE JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000002436: {
    comapnyId: 7000002436,
    company_name: "RAJARAM JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000009649: {
    comapnyId: 7000009649,
    company_name: "GEM PARADISE",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000009976: {
    comapnyId: 7000009976,
    company_name: "ORIGINAL GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000007885: {
    comapnyId: 7000007885,
    company_name: "M.B. EXPORTS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000015634: {
    comapnyId: 7000015634,
    company_name: "SIDDHAI ENTERPRISES",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000006342: {
    comapnyId: 7000006342,
    company_name: "SAKLECHA DIAMONDS AND  JEWELLERY PVT. LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000077582: {
    comapnyId: 7000077582,
    company_name: "SAMBAV DIAMOND LLP",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000038552: {
    comapnyId: 7000038552,
    company_name: "GYS JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000009966: {
    comapnyId: 7000009966,
    company_name: "SURANA DIAMOND JEWELLERY PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_1",
    area: 27,
  },
  7000012977: {
    comapnyId: 7000012977,
    company_name: "S. JITENDRA & COMPANY",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000066122: {
    comapnyId: 7000066122,
    company_name: "VIMLACHAL JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000007668: {
    comapnyId: 7000007668,
    company_name: "LUCKY JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000000822: {
    comapnyId: 7000000822,
    company_name: "ANMOL DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000006456: {
    comapnyId: 7000006456,
    company_name: "KIRAN JEWELS INDIA",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000044229: {
    comapnyId: 7000044229,
    company_name: "GOLD STAR ELITE JEWELS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000011800: {
    comapnyId: 7000011800,
    company_name: "AKSHAR JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000049772: {
    comapnyId: 7000049772,
    company_name: "NEETI JEWELLS LLP",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000006137: {
    comapnyId: 7000006137,
    company_name: "KAPU GEMS LLP",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000049992: {
    comapnyId: 7000049992,
    company_name: "VARDHMANKRUPA GOLD PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000026303: {
    comapnyId: 7000026303,
    company_name: "H G & SONS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000004082: {
    comapnyId: 7000004082,
    company_name: "SURYABHANU",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000044101: {
    comapnyId: 7000044101,
    company_name: "N M KAREL & SONS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000014664: {
    comapnyId: 7000014664,
    company_name: "BRIJWASI GEMS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000007382: {
    comapnyId: 7000007382,
    company_name: "L. GOPAL & SONS JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 18,
  },
  7000016820: {
    comapnyId: 7000016820,
    company_name: "S.S. JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000015090: {
    comapnyId: 7000015090,
    company_name: "SSP JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000016075: {
    comapnyId: 7000016075,
    company_name: "SWARNA MANGAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000006466: {
    comapnyId: 7000006466,
    company_name: "RIVERSTONE JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000000037: {
    comapnyId: 7000000037,
    company_name: "P&S GALVASOLS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000066320: {
    comapnyId: 7000066320,
    company_name: "KAVAN JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000017057: {
    comapnyId: 7000017057,
    company_name: "BIKANERI JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000060825: {
    comapnyId: 7000060825,
    company_name: "OLIVER JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000013673: {
    comapnyId: 7000013673,
    company_name: "AASHNA DIAMOND",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000005466: {
    comapnyId: 7000005466,
    company_name: "K.C. CRAFT",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000059334: {
    comapnyId: 7000059334,
    company_name: "SANKALP DIAMOND",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000007388: {
    comapnyId: 7000007388,
    company_name: "L.D. & COMPANY",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000014896: {
    comapnyId: 7000014896,
    company_name: "GEM GALLERY",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000066135: {
    comapnyId: 7000066135,
    company_name: "AADYA CREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000050309: {
    comapnyId: 7000050309,
    company_name: "S PADMAVATI EXPORTS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000047772: {
    comapnyId: 7000047772,
    company_name: "FANCY FOREVER LLP",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000012251: {
    comapnyId: 7000012251,
    company_name: "JIA DIAMONDS",
    region: "region_6",
    section: "sectionData_1",
    area: 27,
  },
  7000015517: {
    comapnyId: 7000015517,
    company_name: "SHREE JEE JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 27,
  },
  7000004693: {
    comapnyId: 7000004693,
    company_name: "JAYANTILAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000014630: {
    comapnyId: 7000014630,
    company_name: "BILALA JEWELLERS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000002192: {
    comapnyId: 7000002192,
    company_name: "NAKODA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000011661: {
    comapnyId: 7000011661,
    company_name: "RADHEY SHYAM JEWELLERS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_1",
    area: 36,
  },
  7000013276: {
    comapnyId: 7000013276,
    company_name: "TEJ DIAMOND",
    region: "region_3",
    section: "sectionData_3",
    area: 18,
  },
  7000069512: {
    comapnyId: 7000069512,
    company_name: "RAJASTHAN SILVER WORKS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000010059: {
    comapnyId: 7000010059,
    company_name: "P. MANGATRAM JEWELLERS PVT LTD",
    region: "region_1",
    section: "sectionData_1",
    area: 36,
  },
  7000013103: {
    comapnyId: 7000013103,
    company_name: "BLISS GEMS & MINERALS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000007759: {
    comapnyId: 7000007759,
    company_name: "M. MEHTA SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000001978: {
    comapnyId: 7000001978,
    company_name: "MIDAS DIAMONDS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000050756: {
    comapnyId: 7000050756,
    company_name: "SANGEETA BOOCHRA JAIPUR",
    region: "region_4",
    section: "sectionData_4",
    area: 12,
  },
  7000048501: {
    comapnyId: 7000048501,
    company_name: "RAJ GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000048255: {
    comapnyId: 7000048255,
    company_name: "KIRAN GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000004855: {
    comapnyId: 7000004855,
    company_name: "SUKETU JHAVERI AND CO.",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000007994: {
    comapnyId: 7000007994,
    company_name: "M.S. TRADERS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000012063: {
    comapnyId: 7000012063,
    company_name: "RANK INTERNATIONAL",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000019018: {
    comapnyId: 7000019018,
    company_name: "SESMAL JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000016520: {
    comapnyId: 7000016520,
    company_name: "VEEKAY DIAMANTS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000075770: {
    comapnyId: 7000075770,
    company_name: "UNI DESIGN ELITE JEWELLERY PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000003533: {
    comapnyId: 7000003533,
    company_name: "HARSH PRECIOUS METALS P. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000009745: {
    comapnyId: 7000009745,
    company_name: "BAID TRADING CORPORATION",
    region: "region_4",
    section: "sectionData_9",
    area: 27,
  },
  7000017664: {
    comapnyId: 7000017664,
    company_name: "ARIHA DIAMOND JEWELLERY P. LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000050165: {
    comapnyId: 7000050165,
    company_name: "DE BEERS INDIA PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000037447: {
    comapnyId: 7000037447,
    company_name: "SHANTINATH PEARLS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000051492: {
    comapnyId: 7000051492,
    company_name: "SRUSTI DIAM",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000006662: {
    comapnyId: 7000006662,
    company_name: "KROWN JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000000198: {
    comapnyId: 7000000198,
    company_name: "COLOURFUL GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000005211: {
    comapnyId: 7000005211,
    company_name: "ARIANNA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 16,
  },
  7000060969: {
    comapnyId: 7000060969,
    company_name: "DIVIJA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000061022: {
    comapnyId: 7000061022,
    company_name: "DIARA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000069884: {
    comapnyId: 7000069884,
    company_name: "TIARA DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000057635: {
    comapnyId: 7000057635,
    company_name: "GANGA JAMUNA JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000059247: {
    comapnyId: 7000059247,
    company_name: "KALASH GOLD AND ORNAMENTS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000026463: {
    comapnyId: 7000026463,
    company_name: "SHREE RAM INTERNATIONAL",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000008858: {
    comapnyId: 7000008858,
    company_name: "MITHALI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000015741: {
    comapnyId: 7000015741,
    company_name: "SOLID GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000000694: {
    comapnyId: 7000000694,
    company_name: "DHANDIA GEMS CORPORATION",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000013234: {
    comapnyId: 7000013234,
    company_name: "RATHOD JEWELLERY MFG. PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000011351: {
    comapnyId: 7000011351,
    company_name: "MOHE JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000048235: {
    comapnyId: 7000048235,
    company_name: "OJASVI JEWELS",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000012917: {
    comapnyId: 7000012917,
    company_name: "RUSHABH DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000061075: {
    comapnyId: 7000061075,
    company_name: "SHUBHPRATEEK JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000017736: {
    comapnyId: 7000017736,
    company_name: "ASHA ENTERPRISES",
    region: "region_4",
    section: "sectionData_3",
    area: 9,
  },
  7000012451: {
    comapnyId: 7000012451,
    company_name: "RIDHI SIDHI GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000069735: {
    comapnyId: 7000069735,
    company_name: "DE SUPER DIAMOND INDIA LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000015616: {
    comapnyId: 7000015616,
    company_name: "SHWET RATAN IMPEX",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000051569: {
    comapnyId: 7000051569,
    company_name: "COLOUR JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000007332: {
    comapnyId: 7000007332,
    company_name: "AKASH GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000017247: {
    comapnyId: 7000017247,
    company_name: "PANKAJ ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000016993: {
    comapnyId: 7000016993,
    company_name: "NAICE JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000003050: {
    comapnyId: 7000003050,
    company_name: "GURJAR GEMS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000012648: {
    comapnyId: 7000012648,
    company_name: "MILANA KREATIONS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000052656: {
    comapnyId: 7000052656,
    company_name: "PUKHRAJ KUNDANMAL SHAH",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000003603: {
    comapnyId: 7000003603,
    company_name: "HEMA GOLD JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000013944: {
    comapnyId: 7000013944,
    company_name: "ALLIED GEMS CORPORATION",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000009113: {
    comapnyId: 7000009113,
    company_name: "JAANVI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000007097: {
    comapnyId: 7000007097,
    company_name: "VIJAY TAK GEMS PVT.LTD",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000007586: {
    comapnyId: 7000007586,
    company_name: "D.M. JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000060963: {
    comapnyId: 7000060963,
    company_name: "VIKAS SONEE DESIGN STUDIO",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000047197: {
    comapnyId: 7000047197,
    company_name: "CHARBHUJA JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000005569: {
    comapnyId: 7000005569,
    company_name: "K.P. SANGHVI & SONS LLP",
    region: "region_2",
    section: "sectionData_3",
    area: 36,
  },
  7000016864: {
    comapnyId: 7000016864,
    company_name: "KAMAL NARNOLI JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 27,
  },
  7000007006: {
    comapnyId: 7000007006,
    company_name: "MANGAL GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000014770: {
    comapnyId: 7000014770,
    company_name: "CHAINS CORNER JEWELLERY (I) PV T.LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000069965: {
    comapnyId: 7000069965,
    company_name: "NAMOHARIHANT CZ JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000008922: {
    comapnyId: 7000008922,
    company_name: "D. NAVINCHANDRA EXPORTS PVT. L TD.",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000001456: {
    comapnyId: 7000001456,
    company_name: "ETCHES JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000001804: {
    comapnyId: 7000001804,
    company_name: "GAYATRI CORPORATION",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000001295: {
    comapnyId: 7000001295,
    company_name: "SHAILESH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000000889: {
    comapnyId: 7000000889,
    company_name: "SHREE RATNAMANGAL JEWELS  PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000005906: {
    comapnyId: 7000005906,
    company_name: "VIVAAZ JEWELS PVT.LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000012302: {
    comapnyId: 7000012302,
    company_name: "R R JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000003489: {
    comapnyId: 7000003489,
    company_name: "HARI DARSHAN EXPORTS PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000010932: {
    comapnyId: 7000010932,
    company_name: "PREST DIAMANT CORPORATION",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000014604: {
    comapnyId: 7000014604,
    company_name: "BHINDI MANUFACTURERS",
    region: "region_3",
    section: "sectionData_2",
    area: 18,
  },
  7000004831: {
    comapnyId: 7000004831,
    company_name: "JEWELEX INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 108,
  },
  7000012001: {
    comapnyId: 7000012001,
    company_name: "RAMESH CHAND JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000007548: {
    comapnyId: 7000007548,
    company_name: "ANJAN GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000011035: {
    comapnyId: 7000011035,
    company_name: "PRISM ENTERPRISES PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000005668: {
    comapnyId: 7000005668,
    company_name: "KAMA JEWELRY PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000009801: {
    comapnyId: 7000009801,
    company_name: "ANJA JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000061131: {
    comapnyId: 7000061131,
    company_name: "GOLAY BUCHEL TRADING COMPANY",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000046142: {
    comapnyId: 7000046142,
    company_name: "HARESH AND SONS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000016555: {
    comapnyId: 7000016555,
    company_name: "VIJAY EXPORTS",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000016876: {
    comapnyId: 7000016876,
    company_name: "DIVINE ORNAMENTS PVT. LTD.",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000008423: {
    comapnyId: 7000008423,
    company_name: "MEHTA & SONS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000016404: {
    comapnyId: 7000016404,
    company_name: "V.H. JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000003581: {
    comapnyId: 7000003581,
    company_name: "HEENA GEMS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000013051: {
    comapnyId: 7000013051,
    company_name: "YASONI GOLDIAM PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000069597: {
    comapnyId: 7000069597,
    company_name: "SATYAM JEWELLERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000069799: {
    comapnyId: 7000069799,
    company_name: "KHANDELWAL GEMS AND JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 12,
  },
  7000061153: {
    comapnyId: 7000061153,
    company_name: "ALPHA KOSMIK DESIGNS LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000048184: {
    comapnyId: 7000048184,
    company_name: "JHALANI JEWELLERS",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000008006: {
    comapnyId: 7000008006,
    company_name: "M.U. JEWELLERS PVT. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000017171: {
    comapnyId: 7000017171,
    company_name: "VISHESH JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010257: {
    comapnyId: 7000010257,
    company_name: "PANKAJ ARTS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000011024: {
    comapnyId: 7000011024,
    company_name: "PRIORITY JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000009101: {
    comapnyId: 7000009101,
    company_name: "MULCHAND R. SHAH",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000017201: {
    comapnyId: 7000017201,
    company_name: "SHANKESH JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000000630: {
    comapnyId: 7000000630,
    company_name: "MOHANLAL JEWELLERS PRIVATE LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 54,
  },
  7000005484: {
    comapnyId: 7000005484,
    company_name: "K.G.K. CREATIONS (INDIA) PVT.  LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000010591: {
    comapnyId: 7000010591,
    company_name: "POOJA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000015816: {
    comapnyId: 7000015816,
    company_name: "SRI PADMARAJ JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000060720: {
    comapnyId: 7000060720,
    company_name: "SHREEJI GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000003754: {
    comapnyId: 7000003754,
    company_name: "INDUS JEWELLERY LLP.",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000015515: {
    comapnyId: 7000015515,
    company_name: "SHREE JEE EXPORTS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000013325: {
    comapnyId: 7000013325,
    company_name: "SAMRIDDHI JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000037756: {
    comapnyId: 7000037756,
    company_name: "ASIAN STAR CO. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 36,
  },
  7000004331: {
    comapnyId: 7000004331,
    company_name: "SHWETA CREATIONS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000038579: {
    comapnyId: 7000038579,
    company_name: "NEER IMPEX",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000026759: {
    comapnyId: 7000026759,
    company_name: "A` STAR JEWELLERY",
    region: "region_2",
    section: "sectionData_1",
    area: 72,
  },
  7000009083: {
    comapnyId: 7000009083,
    company_name: "MUKTI GOLD PVT. LTD",
    region: "region_2",
    section: "sectionData_6",
    area: 36,
  },
  7000000272: {
    comapnyId: 7000000272,
    company_name: "A. MAHENDRA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000066463: {
    comapnyId: 7000066463,
    company_name: "VIBHA GEMS AND JEWEL",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000008120: {
    comapnyId: 7000008120,
    company_name: "MAJESTIC GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000009985: {
    comapnyId: 7000009985,
    company_name: "SAPPHIRE CREATIONS",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000009220: {
    comapnyId: 7000009220,
    company_name: "PUNGALIA TRADING CORPORATION",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000065055: {
    comapnyId: 7000065055,
    company_name: "GLITIANI",
    region: "region_1",
    section: "sectionData_4",
    area: 9,
  },
  7000012857: {
    comapnyId: 7000012857,
    company_name: "RUBY SOURCE",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000060980: {
    comapnyId: 7000060980,
    company_name: "R.P. JEWELLERS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000012582: {
    comapnyId: 7000012582,
    company_name: "RMC GEMS INDIA LIMITED",
    region: "region_4",
    section: "sectionData_9",
    area: 36,
  },
  7000017253: {
    comapnyId: 7000017253,
    company_name: "ARHAM JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000011631: {
    comapnyId: 7000011631,
    company_name: "ADANI & CO",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000015851: {
    comapnyId: 7000015851,
    company_name: "STAR RAYS",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000007727: {
    comapnyId: 7000007727,
    company_name: "MEERA GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000016956: {
    comapnyId: 7000016956,
    company_name: "ADITYA FINDINGS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000017334: {
    comapnyId: 7000017334,
    company_name: "NICE DIAMONDS (INDIA) PVT. LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000013870: {
    comapnyId: 7000013870,
    company_name: "AT PLUS JEWELLERS PVT. LTD.",
    region: "region_5",
    section: "sectionData_2",
    area: 36,
  },
  7000003189: {
    comapnyId: 7000003189,
    company_name: "PRAVIN ORNAMENTS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000012459: {
    comapnyId: 7000012459,
    company_name: "ANMOL SWARN INDIA PVT  LTD.",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000069540: {
    comapnyId: 7000069540,
    company_name: "TUKDI",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000016482: {
    comapnyId: 7000016482,
    company_name: "VARNI GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000012759: {
    comapnyId: 7000012759,
    company_name: "ROYAL CHAINS PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000011540: {
    comapnyId: 7000011540,
    company_name: "R.S. DIAMONDS PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000054203: {
    comapnyId: 7000054203,
    company_name: "SANKESHWAR JEWELS",
    region: "region_1",
    section: "sectionData_4",
    area: 9,
  },
  7000010514: {
    comapnyId: 7000010514,
    company_name: "V. DIAM GEMS PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000000890: {
    comapnyId: 7000000890,
    company_name: "OM GANESH JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000008869: {
    comapnyId: 7000008869,
    company_name: "MODERN IMPEX",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000003773: {
    comapnyId: 7000003773,
    company_name: "INTERGEM EXPORTS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000015737: {
    comapnyId: 7000015737,
    company_name: "SOLANKI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000010575: {
    comapnyId: 7000010575,
    company_name: "TAHILRAM TIRTHDAS JEWELLERS",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000000947: {
    comapnyId: 7000000947,
    company_name: "SHANKHESHWAR JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000001609: {
    comapnyId: 7000001609,
    company_name: "FANCY DIAMONDS INDIA PRIVATE L IMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 27,
  },
  7000016071: {
    comapnyId: 7000016071,
    company_name: "SWARNSHILP CHAINS & JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000016975: {
    comapnyId: 7000016975,
    company_name: "SAANCHI GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000007503: {
    comapnyId: 7000007503,
    company_name: "LAXMI JEWELLERY EXPORT   PVT. LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 108,
  },
  7000060983: {
    comapnyId: 7000060983,
    company_name: "GRANDEUR JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000017575: {
    comapnyId: 7000017575,
    company_name: "ANIL ART GEMS",
    region: "region_4",
    section: "sectionData_1",
    area: 18,
  },
  7000005899: {
    comapnyId: 7000005899,
    company_name: "MAHESH CHAND JAIN & SONS SARRAF PVT. LTD.",
    region: "region_6",
    section: "sectionData_2",
    area: 16,
  },
  7000017537: {
    comapnyId: 7000017537,
    company_name: "AMORE JEWELS PVT.LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000007227: {
    comapnyId: 7000007227,
    company_name: "YAMUNA ENTERPRISE",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000005175: {
    comapnyId: 7000005175,
    company_name: "JUST PEARLS & JEWELS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000007949: {
    comapnyId: 7000007949,
    company_name: "PARAS JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000051497: {
    comapnyId: 7000051497,
    company_name: "MODERN JEWELS PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 54,
  },
  7000052664: {
    comapnyId: 7000052664,
    company_name: "RASHMINBHAI SHANTILAL ZINZUWADIA PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000017020: {
    comapnyId: 7000017020,
    company_name: "RBZ JEWELLERS LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 54,
  },
  7000053124: {
    comapnyId: 7000053124,
    company_name: "OP JEWELLERS",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000001942: {
    comapnyId: 7000001942,
    company_name: "DAZZLE DIAMONDS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000038507: {
    comapnyId: 7000038507,
    company_name: "SONIGARA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000011519: {
    comapnyId: 7000011519,
    company_name: "R.P. ORNAMENTS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000000540: {
    comapnyId: 7000000540,
    company_name: "SWAROOP JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000015835: {
    comapnyId: 7000015835,
    company_name: "STAR BRILLIAN",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000008980: {
    comapnyId: 7000008980,
    company_name: "J V BANGLES PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000060547: {
    comapnyId: 7000060547,
    company_name: "AV DIAMONDZ",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000049867: {
    comapnyId: 7000049867,
    company_name: "SHREE HARSIDDHI JEWELLERS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000011862: {
    comapnyId: 7000011862,
    company_name: "ACTION GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000002104: {
    comapnyId: 7000002104,
    company_name: "TIRTH GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000002812: {
    comapnyId: 7000002812,
    company_name: "SHREE JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000014745: {
    comapnyId: 7000014745,
    company_name: "CARATI CREATION CO.",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000048165: {
    comapnyId: 7000048165,
    company_name: "KUNDAN HUT",
    region: "region_6",
    section: "sectionData_2",
    area: 9,
  },
  7000008556: {
    comapnyId: 7000008556,
    company_name: "MANUBHAI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000016913: {
    comapnyId: 7000016913,
    company_name: "RATNA SHILP",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000011831: {
    comapnyId: 7000011831,
    company_name: "BACHUBHAI ZAVERI",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000008459: {
    comapnyId: 7000008459,
    company_name: "DEVANSH CREATION",
    region: "region_5",
    section: "sectionData_2",
    area: 12,
  },
  7000010661: {
    comapnyId: 7000010661,
    company_name: "SAMARTH DIAMOND",
    region: "region_3",
    section: "sectionData_3",
    area: 18,
  },
  7000010090: {
    comapnyId: 7000010090,
    company_name: "SRI NAVDURGA GEMS AND JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000010283: {
    comapnyId: 7000010283,
    company_name: "PARAS CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000000287: {
    comapnyId: 7000000287,
    company_name: "TATIWALAS GEHNA",
    region: "region_4",
    section: "sectionData_2",
    area: 36,
  },
  7000009736: {
    comapnyId: 7000009736,
    company_name: "AARPEE BANGLES",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000016383: {
    comapnyId: 7000016383,
    company_name: "V. CHAINS JEWELLERY PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000005506: {
    comapnyId: 7000005506,
    company_name: "KGK DIAMONDS (I) PVT. LTD",
    region: "region_2",
    section: "sectionData_3",
    area: 27,
  },
  7000047726: {
    comapnyId: 7000047726,
    company_name: "VINATI JEWELLERS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000011132: {
    comapnyId: 7000011132,
    company_name: "BHARATI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000068595: {
    comapnyId: 7000068595,
    company_name: "AVNIP GEMS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000017597: {
    comapnyId: 7000017597,
    company_name: "ANKIT GEMS PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000000471: {
    comapnyId: 7000000471,
    company_name: "SHARDA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000013538: {
    comapnyId: 7000013538,
    company_name: "PAYAL GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000009333: {
    comapnyId: 7000009333,
    company_name: "JEWEL CAST",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000004371: {
    comapnyId: 7000004371,
    company_name: "J.B. AND BROTHERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000015632: {
    comapnyId: 7000015632,
    company_name: "SHYAMSUNDER & BROS.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000014895: {
    comapnyId: 7000014895,
    company_name: "SAHELI JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000008616: {
    comapnyId: 7000008616,
    company_name: "BEERA JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 9,
  },
  7000069816: {
    comapnyId: 7000069816,
    company_name: "RATNAMANI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000011577: {
    comapnyId: 7000011577,
    company_name: "THATHI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000053026: {
    comapnyId: 7000053026,
    company_name: "KHATER JEWELLERS",
    region: "region_5",
    section: "sectionData_1",
    area: 18,
  },
  7000051439: {
    comapnyId: 7000051439,
    company_name: "AARYA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000007436: {
    comapnyId: 7000007436,
    company_name: "LADIWALA ENTERPRISES",
    region: "region_4",
    section: "sectionData_2",
    area: 18,
  },
  7000050317: {
    comapnyId: 7000050317,
    company_name: "TANVI EXPORTS INDIA LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 72,
  },
  7000015864: {
    comapnyId: 7000015864,
    company_name: "STELLAR EXPORTS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000026480: {
    comapnyId: 7000026480,
    company_name: "AGARWAL & CO",
    region: "region_4",
    section: "sectionData_1",
    area: 27,
  },
  7000005993: {
    comapnyId: 7000005993,
    company_name: "SHRIPATI GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000009516: {
    comapnyId: 7000009516,
    company_name: "MEHTA ART JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000069850: {
    comapnyId: 7000069850,
    company_name: "PDM JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000001450: {
    comapnyId: 7000001450,
    company_name: "ANANTAM JEWELS PRIVATE  LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000015131: {
    comapnyId: 7000015131,
    company_name: "D. RAJ DIAMONDS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000013484: {
    comapnyId: 7000013484,
    company_name: "A.J. MEHTA & CO.  LLP",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000009926: {
    comapnyId: 7000009926,
    company_name: "B. SONS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000017464: {
    comapnyId: 7000017464,
    company_name: "S.K. SETH CO. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000037961: {
    comapnyId: 7000037961,
    company_name: "MARS DIA JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000014874: {
    comapnyId: 7000014874,
    company_name: "MAGS GEMS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000011245: {
    comapnyId: 7000011245,
    company_name: "MY DIAMONDS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000002512: {
    comapnyId: 7000002512,
    company_name: "GEM PLAZA  PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_10",
    area: 36,
  },
  7000053083: {
    comapnyId: 7000053083,
    company_name: "TANKARIA VENTURES PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000013532: {
    comapnyId: 7000013532,
    company_name: "AKASH GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 27,
  },
  7000016902: {
    comapnyId: 7000016902,
    company_name: "GEMFIELDS (INDIA) PVT. LTD.",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000000090: {
    comapnyId: 7000000090,
    company_name: "VIDHI DESIGNS",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000049868: {
    comapnyId: 7000049868,
    company_name: "N.K. IMPEX",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000052262: {
    comapnyId: 7000052262,
    company_name: "SIDDHI ENTERPRISE",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000003336: {
    comapnyId: 7000003336,
    company_name: "RAJSHREE JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000001179: {
    comapnyId: 7000001179,
    company_name: "DIMEXON DIAMONDS  LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 36,
  },
  7000001501: {
    comapnyId: 7000001501,
    company_name: "BALAJI GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 12,
  },
  7000003419: {
    comapnyId: 7000003419,
    company_name: "HVK JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000060869: {
    comapnyId: 7000060869,
    company_name: "RAHUL GEMSTONES PVT LTD",
    region: "region_4",
    section: "sectionData_9",
    area: 12,
  },
  7000015794: {
    comapnyId: 7000015794,
    company_name: "SRI BALAJI JEWELLERS & EXPORTE RS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000006979: {
    comapnyId: 7000006979,
    company_name: "BRIANA JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 12,
  },
  7000053103: {
    comapnyId: 7000053103,
    company_name: "SUNRISE JEWELS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000049836: {
    comapnyId: 7000049836,
    company_name: "LABHESHWAR DIAMOND",
    region: "region_3",
    section: "sectionData_3",
    area: 9,
  },
  7000005370: {
    comapnyId: 7000005370,
    company_name: "JODHANI BROTHERS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000053171: {
    comapnyId: 7000053171,
    company_name: "ABHAY PEARLS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000008212: {
    comapnyId: 7000008212,
    company_name: "VIKASH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000009048: {
    comapnyId: 7000009048,
    company_name: "LOVE GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000004740: {
    comapnyId: 7000004740,
    company_name: "BHAVIKA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000060756: {
    comapnyId: 7000060756,
    company_name: "GEM CROWN INTERNATIONAL PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000003277: {
    comapnyId: 7000003277,
    company_name: "RAJ DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 36,
  },
  7000011835: {
    comapnyId: 7000011835,
    company_name: "AADITYA DIAMGOLD PVT.LTD.",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000002962: {
    comapnyId: 7000002962,
    company_name: "GREEN INTERNATIONAL",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000046255: {
    comapnyId: 7000046255,
    company_name: "PALM JEWELS LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000069952: {
    comapnyId: 7000069952,
    company_name: "GURU KRIPA DESIGN STUDIO PVT LTD",
    region: "region_4",
    section: "sectionData_2",
    area: 27,
  },
  7000015601: {
    comapnyId: 7000015601,
    company_name: "SHRUNGAR JEWELLERS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000009283: {
    comapnyId: 7000009283,
    company_name: "CLASSIC ORNAMENTS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000060613: {
    comapnyId: 7000060613,
    company_name: "SURANA ENSEMBLE PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 54,
  },
  7000007744: {
    comapnyId: 7000007744,
    company_name: "RATAN CAST",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000016509: {
    comapnyId: 7000016509,
    company_name: "VASUPATI JEWELLERS INDIA PVT.  LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000011566: {
    comapnyId: 7000011566,
    company_name: "R.V. AGRAWAL IMPEX PVT. LTD.",
    region: "region_2",
    section: "sectionData_4",
    area: 18,
  },
  7000001630: {
    comapnyId: 7000001630,
    company_name: "FINESTAR JEWELLERY AND  DIAMONDS PVT. LTD.",
    region: "region_2",
    section: "sectionData_3",
    area: 18,
  },
  7000012664: {
    comapnyId: 7000012664,
    company_name: "ROOMY EXPORTS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000011612: {
    comapnyId: 7000011612,
    company_name: "RACHANA ENTERPRISES",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000010225: {
    comapnyId: 7000010225,
    company_name: "PADMAVATI EXPORTS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000012117: {
    comapnyId: 7000012117,
    company_name: "PALAK JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000006294: {
    comapnyId: 7000006294,
    company_name: "P HIRANI EXPORTS LLP",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000008377: {
    comapnyId: 7000008377,
    company_name: "MASTER CHAIN PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000003673: {
    comapnyId: 7000003673,
    company_name: "HIRA IMPEX",
    region: "region_2",
    section: "sectionData_4",
    area: 36,
  },
  7000069767: {
    comapnyId: 7000069767,
    company_name: "REGENT GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000014272: {
    comapnyId: 7000014272,
    company_name: "BAANGANGA GOLD & DIAMOND (I) PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000010204: {
    comapnyId: 7000010204,
    company_name: "P.P. JAIN & COMPANY",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000003208: {
    comapnyId: 7000003208,
    company_name: "MINALAXMI EXIM",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000016784: {
    comapnyId: 7000016784,
    company_name: "KANCHAN ANTIQUE GOLD JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000050080: {
    comapnyId: 7000050080,
    company_name: "SHREE ARIHANT CREATION",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000007978: {
    comapnyId: 7000007978,
    company_name: "SENCO GOLD LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 12,
  },
  7000001888: {
    comapnyId: 7000001888,
    company_name: "MAA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000011769: {
    comapnyId: 7000011769,
    company_name: "RAJ JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000000828: {
    comapnyId: 7000000828,
    company_name: "SIDDHIVINAYAK GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000007579: {
    comapnyId: 7000007579,
    company_name: "LIVINGSTONES",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000067403: {
    comapnyId: 7000067403,
    company_name: "ARZISH GOLDCRAFT PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000016915: {
    comapnyId: 7000016915,
    company_name: "ALANKAR INTERNATIONAL PVT. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000014042: {
    comapnyId: 7000014042,
    company_name: "M S KUNDAN CREATION",
    region: "region_6",
    section: "sectionData_2",
    area: 16,
  },
  7000066507: {
    comapnyId: 7000066507,
    company_name: "SHIVANTA JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000016814: {
    comapnyId: 7000016814,
    company_name: "ZAR JEWELS PVT. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000015766: {
    comapnyId: 7000015766,
    company_name: "SONI SHIVLAL BROS. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000005268: {
    comapnyId: 7000005268,
    company_name: "PURPLE DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000006889: {
    comapnyId: 7000006889,
    company_name: "NAHAR DIAMOND",
    region: "region_1",
    section: "sectionData_1",
    area: 27,
  },
  7000049954: {
    comapnyId: 7000049954,
    company_name: "KALISTA JEWELS (I) PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000014831: {
    comapnyId: 7000014831,
    company_name: "S.S. GEMS JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000054300: {
    comapnyId: 7000054300,
    company_name: "SHIVANI GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000066367: {
    comapnyId: 7000066367,
    company_name: "RATNAWALI",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000067928: {
    comapnyId: 7000067928,
    company_name: "P. B. JADIA",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000015562: {
    comapnyId: 7000015562,
    company_name: "SHREE VIKRAM JEWELLERS",
    region: "region_3",
    section: "sectionData_1",
    area: 18,
  },
  7000000106: {
    comapnyId: 7000000106,
    company_name: "BONITAS JEWELS PVT.L TD.",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000059283: {
    comapnyId: 7000059283,
    company_name: "RITU GEMS PRIVATE LIMITED",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000008441: {
    comapnyId: 7000008441,
    company_name: "VIERA CZ JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000052494: {
    comapnyId: 7000052494,
    company_name: "GEMS PARK",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000001234: {
    comapnyId: 7000001234,
    company_name: "SILVER PLANET",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000001954: {
    comapnyId: 7000001954,
    company_name: "GEMS KITE",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000011570: {
    comapnyId: 7000011570,
    company_name: "B G PLATINUM",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000012298: {
    comapnyId: 7000012298,
    company_name: "SHREE MANGAL PALACE",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000005642: {
    comapnyId: 7000005642,
    company_name: "KALINGA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000017278: {
    comapnyId: 7000017278,
    company_name: "ANM JEWELLERYLINE",
    region: "region_6",
    section: "sectionData_4",
    area: 9,
  },
  7000009847: {
    comapnyId: 7000009847,
    company_name: "DIAJE",
    region: "region_2",
    section: "sectionData_4",
    area: 27,
  },
  7000000963: {
    comapnyId: 7000000963,
    company_name: "DIAMOND INDIA LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000069927: {
    comapnyId: 7000069927,
    company_name: "VEER GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000011792: {
    comapnyId: 7000011792,
    company_name: "LAKHTARWALA JEWELLERS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000061029: {
    comapnyId: 7000061029,
    company_name: "JOHRISONS JEWELLERS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000079841: {
    comapnyId: 7000079841,
    company_name: "TRIDENT JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000015661: {
    comapnyId: 7000015661,
    company_name: "SILVER EMPORIUM PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000015966: {
    comapnyId: 7000015966,
    company_name: "SUNDARAM CHAINS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 72,
  },
  7000061005: {
    comapnyId: 7000061005,
    company_name: "PMS JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000000526: {
    comapnyId: 7000000526,
    company_name: "DEREWALA  INDUSTRIES LTD.",
    region: "region_4",
    section: "sectionData_2",
    area: 36,
  },
  7000015609: {
    comapnyId: 7000015609,
    company_name: "SHUBH JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000001722: {
    comapnyId: 7000001722,
    company_name: "GAJRAJ",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000015763: {
    comapnyId: 7000015763,
    company_name: "SONI NANALAL BECHARDAS  JEWELLERS PVT. LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 54,
  },
  7000009581: {
    comapnyId: 7000009581,
    company_name: "NEPTUNE GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000009730: {
    comapnyId: 7000009730,
    company_name: "MAHAYOGI SILVERS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000010597: {
    comapnyId: 7000010597,
    company_name: "POOJA OPTICAL INDUSTRIES",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061097: {
    comapnyId: 7000061097,
    company_name: "R.S.JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000032689: {
    comapnyId: 7000032689,
    company_name: "SILVER GALAXY",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000015061: {
    comapnyId: 7000015061,
    company_name: "SIROYA CHAMPALAL UTTAMCHAND",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000013154: {
    comapnyId: 7000013154,
    company_name: "TIRUPATI GOLD PRIVATE LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 9,
  },
  7000050172: {
    comapnyId: 7000050172,
    company_name: "JEWEL & CHAINZ",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000008350: {
    comapnyId: 7000008350,
    company_name: "SHRI RISHAB GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000018971: {
    comapnyId: 7000018971,
    company_name: "MANIBHADRA CHAIN",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000059559: {
    comapnyId: 7000059559,
    company_name: "GITA JEWELS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000069810: {
    comapnyId: 7000069810,
    company_name: "BHAIRAV GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000006090: {
    comapnyId: 7000006090,
    company_name: "G.P. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000009137: {
    comapnyId: 7000009137,
    company_name: "BIRAWAT CHAIN",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000001917: {
    comapnyId: 7000001917,
    company_name: "V U CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000013202: {
    comapnyId: 7000013202,
    company_name: "LAL GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000008060: {
    comapnyId: 7000008060,
    company_name: "MADHU JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000038025: {
    comapnyId: 7000038025,
    company_name: "SURESH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010080: {
    comapnyId: 7000010080,
    company_name: "HITESH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000009143: {
    comapnyId: 7000009143,
    company_name: "ARIHANT GEMS N JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000052668: {
    comapnyId: 7000052668,
    company_name: "RASI JEWEL TECH",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000015394: {
    comapnyId: 7000015394,
    company_name: "SHANTI GOLD INTERNATIONAL LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000060759: {
    comapnyId: 7000060759,
    company_name: "MEET GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000066477: {
    comapnyId: 7000066477,
    company_name: "SHREE DIAM",
    region: "region_6",
    section: "sectionData_3",
    area: 9,
  },
  7000008239: {
    comapnyId: 7000008239,
    company_name: "S K JORA JEWELLERS",
    region: "region_6",
    section: "sectionData_2",
    area: 12,
  },
  7000004323: {
    comapnyId: 7000004323,
    company_name: "DHANRESHA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000066647: {
    comapnyId: 7000066647,
    company_name: "NAMOSHREE GOLD LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000017023: {
    comapnyId: 7000017023,
    company_name: "UTSSAV CZ GOLD JEWELS LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000057628: {
    comapnyId: 7000057628,
    company_name: "AUGOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000017203: {
    comapnyId: 7000017203,
    company_name: "RAMNIKLAL & SONS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000045735: {
    comapnyId: 7000045735,
    company_name: "ARYA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061084: {
    comapnyId: 7000061084,
    company_name: "SHREE JI JEWELLERY MANUFACTURERS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000052167: {
    comapnyId: 7000052167,
    company_name: "FIVE FINGER HANDICRAFTS PVT LTD",
    region: "region_5",
    section: "sectionData_4",
    area: 9,
  },
  7000069948: {
    comapnyId: 7000069948,
    company_name: "NEW BOMBAY JEWELLERS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000013212: {
    comapnyId: 7000013212,
    company_name: "SK JEWELLERS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000026606: {
    comapnyId: 7000026606,
    company_name: "H. G.JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000078328: {
    comapnyId: 7000078328,
    company_name: "ARADHANA JEWELLERS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000052145: {
    comapnyId: 7000052145,
    company_name: "DHARM JEWELS",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000009276: {
    comapnyId: 7000009276,
    company_name: "SHRINGAR HOUSE OF MANGALSUTRA  PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000009406: {
    comapnyId: 7000009406,
    company_name: "JEWEL TRADING",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000050975: {
    comapnyId: 7000050975,
    company_name: "T. M. JEWELLERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000002002: {
    comapnyId: 7000002002,
    company_name: "AMBICA CAST",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000015465: {
    comapnyId: 7000015465,
    company_name: "SHIV ENTERPRISES",
    region: "region_6",
    section: "sectionData_4",
    area: 27,
  },
  7000003210: {
    comapnyId: 7000003210,
    company_name: "SAKARIYA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000008448: {
    comapnyId: 7000008448,
    company_name: "MESMERIZE INTERNATIONAL",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000002212: {
    comapnyId: 7000002212,
    company_name: "SHREE GUPTA JEWELS",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000001542: {
    comapnyId: 7000001542,
    company_name: "EMERALD JEWEL INDUSTRY INDIA  LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 108,
  },
  7000057559: {
    comapnyId: 7000057559,
    company_name: "PORWAL PALACE",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000037937: {
    comapnyId: 7000037937,
    company_name: "MAHALAXMI TRADERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000003170: {
    comapnyId: 7000003170,
    company_name: "RED EXIM",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000078928: {
    comapnyId: 7000078928,
    company_name: "DIAMOND CREATIONS PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_1",
    area: 54,
  },
  7000015249: {
    comapnyId: 7000015249,
    company_name: "SHREE FINE JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000013218: {
    comapnyId: 7000013218,
    company_name: "S K SETH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000017232: {
    comapnyId: 7000017232,
    company_name: "R.B. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000012137: {
    comapnyId: 7000012137,
    company_name: "SAAZ JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000016779: {
    comapnyId: 7000016779,
    company_name: "HREENKAR JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000026429: {
    comapnyId: 7000026429,
    company_name: "KAMLA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000014256: {
    comapnyId: 7000014256,
    company_name: "B.N. JEWELLERS INDIA PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000058916: {
    comapnyId: 7000058916,
    company_name: "R K BRACELETS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000016415: {
    comapnyId: 7000016415,
    company_name: "V. N. EXPORTS",
    region: "region_3",
    section: "sectionData_1",
    area: 27,
  },
  7000012091: {
    comapnyId: 7000012091,
    company_name: "RARE GEMS & JEWELLERY CO. PVT. LTD.",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000007857: {
    comapnyId: 7000007857,
    company_name: "M.A. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000069855: {
    comapnyId: 7000069855,
    company_name: "AMARI DESIGNER JEWELLERY",
    region: "region_1",
    section: "sectionData_4",
    area: 9,
  },
  7000012526: {
    comapnyId: 7000012526,
    company_name: "JINESHWAR JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000050618: {
    comapnyId: 7000050618,
    company_name: "MY CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000001509: {
    comapnyId: 7000001509,
    company_name: "DURGA CHAIN",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061049: {
    comapnyId: 7000061049,
    company_name: "SUBH MANGAL GOLD PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000038087: {
    comapnyId: 7000038087,
    company_name: "SWARNA SHILPI",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000053115: {
    comapnyId: 7000053115,
    company_name: "MUKESH & BROTHERS JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000009638: {
    comapnyId: 7000009638,
    company_name: "AAKAR JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000052984: {
    comapnyId: 7000052984,
    company_name: "V. J. JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000015274: {
    comapnyId: 7000015274,
    company_name: "SATYAM GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000061047: {
    comapnyId: 7000061047,
    company_name: "G.M. JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 9,
  },
  7000066343: {
    comapnyId: 7000066343,
    company_name: "RUBIA JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000004228: {
    comapnyId: 7000004228,
    company_name: "MAHAK ENTERPRISES",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000006422: {
    comapnyId: 7000006422,
    company_name: "SHIVAM JEWELLERS",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000007478: {
    comapnyId: 7000007478,
    company_name: "LAXMI DIA JEWEL PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 108,
  },
  7000011746: {
    comapnyId: 7000011746,
    company_name: "ZAVERAT",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000058619: {
    comapnyId: 7000058619,
    company_name: "DB SALES",
    region: "region_3",
    section: "sectionData_7",
    area: 12,
  },
  7000037978: {
    comapnyId: 7000037978,
    company_name: "AASTHA DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000038320: {
    comapnyId: 7000038320,
    company_name: "ADITYA JEWELS INDIA LLP",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000069926: {
    comapnyId: 7000069926,
    company_name: "SNEH JEWELLERS",
    region: "region_6",
    section: "sectionData_1",
    area: 9,
  },
  7000013138: {
    comapnyId: 7000013138,
    company_name: "SALASAR GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000054325: {
    comapnyId: 7000054325,
    company_name: "GOLDEN CARAT PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 108,
  },
  7000013840: {
    comapnyId: 7000013840,
    company_name: "AGRA CHAINS PRIVATE LIMITED",
    region: "region_6",
    section: "sectionData_4",
    area: 54,
  },
  7000015674: {
    comapnyId: 7000015674,
    company_name: "SILVER MOUNTAIN",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000008777: {
    comapnyId: 7000008777,
    company_name: "JAIPUR EMPORIUM INTERNATIONAL",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000037477: {
    comapnyId: 7000037477,
    company_name: "SEJAL GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010898: {
    comapnyId: 7000010898,
    company_name: "COLOURED ROCKS TRADING PVT. LTD.",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000004524: {
    comapnyId: 7000004524,
    company_name: "SHREE SANKHESWAR DARSHAN IMPEX",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061137: {
    comapnyId: 7000061137,
    company_name: "SACHHIYAY GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000016193: {
    comapnyId: 7000016193,
    company_name: "TIKKU MIKKU GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000005876: {
    comapnyId: 7000005876,
    company_name: "VISHNU AGARWAL AND COMPANY",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000045697: {
    comapnyId: 7000045697,
    company_name: "KUNDAN JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000044490: {
    comapnyId: 7000044490,
    company_name: "T. GULABCHAND JAIN",
    region: "region_2",
    section: "sectionData_4",
    area: 12,
  },
  7000009327: {
    comapnyId: 7000009327,
    company_name: "FLAVIA JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000014858: {
    comapnyId: 7000014858,
    company_name: "BRB ART & JEWELS PVT. LTD.",
    region: "region_4",
    section: "sectionData_4",
    area: 18,
  },
  7000038039: {
    comapnyId: 7000038039,
    company_name: "JINAYA JEWEL CAST LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061079: {
    comapnyId: 7000061079,
    company_name: "SHREENI JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000015407: {
    comapnyId: 7000015407,
    company_name: "SHARAD VIJAY JEWELLERS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000050598: {
    comapnyId: 7000050598,
    company_name: "SARTHAK JEWELLERS",
    region: "region_5",
    section: "sectionData_2",
    area: 9,
  },
  7000060474: {
    comapnyId: 7000060474,
    company_name: "TRILLION JEWELS PVT.LTD.",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000009993: {
    comapnyId: 7000009993,
    company_name: "SILVER SHOP",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000020104: {
    comapnyId: 7000020104,
    company_name: "BANETHI EXPORTS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000011479: {
    comapnyId: 7000011479,
    company_name: "SHREE NAKODA CREATION",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000061155: {
    comapnyId: 7000061155,
    company_name: "RAMA BULLION PVT. LTD.",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000004604: {
    comapnyId: 7000004604,
    company_name: "SHREE SIDDHIVINAYAK  ENTERPRISES",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000017617: {
    comapnyId: 7000017617,
    company_name: "ANSAA JEWELLERS (P) LTD.",
    region: "region_2",
    section: "sectionData_10",
    area: 36,
  },
  7000007425: {
    comapnyId: 7000007425,
    company_name: "RAJYASH JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000001183: {
    comapnyId: 7000001183,
    company_name: "LALITH JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000038522: {
    comapnyId: 7000038522,
    company_name: "MANSUKHBHAI SAVJIBHAI DHANDHUKIYA",
    region: "region_3",
    section: "sectionData_4",
    area: 9,
  },
  7000005218: {
    comapnyId: 7000005218,
    company_name: "GOYAL ART JEWELLERS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000012678: {
    comapnyId: 7000012678,
    company_name: "ROOPSHREE JEWELLERS PRIVATE  LIMITED",
    region: "region_5",
    section: "sectionData_2",
    area: 12,
  },
  7000069873: {
    comapnyId: 7000069873,
    company_name: "URJA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000038113: {
    comapnyId: 7000038113,
    company_name: "AMRIT GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000057334: {
    comapnyId: 7000057334,
    company_name: "ALUX DIAMOND AND JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000070033: {
    comapnyId: 7000070033,
    company_name: "SHIVAM JEWELLERS",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000016935: {
    comapnyId: 7000016935,
    company_name: "ARIHANT GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000004982: {
    comapnyId: 7000004982,
    company_name: "SHUBH ART JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000060789: {
    comapnyId: 7000060789,
    company_name: "ADITYA GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000061008: {
    comapnyId: 7000061008,
    company_name: "PP ABHAY JEWELLERS PVT LTD",
    region: "region_5",
    section: "sectionData_2",
    area: 9,
  },
  7000008173: {
    comapnyId: 7000008173,
    company_name: "TINASHE CREATION",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000035584: {
    comapnyId: 7000035584,
    company_name: "KAPOOR CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000009091: {
    comapnyId: 7000009091,
    company_name: "HIRACHAND RATANCHAND & SONS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000012261: {
    comapnyId: 7000012261,
    company_name: "VINAYAK GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000053927: {
    comapnyId: 7000053927,
    company_name: "RAJENDRA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000015542: {
    comapnyId: 7000015542,
    company_name: "SHREE NATH GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000002280: {
    comapnyId: 7000002280,
    company_name: "TULSI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000001307: {
    comapnyId: 7000001307,
    company_name: "M.J. & SONS JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000007684: {
    comapnyId: 7000007684,
    company_name: "DHANLAXMI JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 12,
  },
  7000017034: {
    comapnyId: 7000017034,
    company_name: "SOVANN",
    region: "region_6",
    section: "sectionData_4",
    area: 9,
  },
  7000001998: {
    comapnyId: 7000001998,
    company_name: "S.P. JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000000303: {
    comapnyId: 7000000303,
    company_name: "CROSS JEWEL",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000001354: {
    comapnyId: 7000001354,
    company_name: "DUSAD & COMPANY",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000069978: {
    comapnyId: 7000069978,
    company_name: "MANN CHAINS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000003689: {
    comapnyId: 7000003689,
    company_name: "HMV GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 18,
  },
  7000051563: {
    comapnyId: 7000051563,
    company_name: "VAJRA",
    region: "region_1",
    section: "sectionData_2",
    area: 18,
  },
  7000009450: {
    comapnyId: 7000009450,
    company_name: "NATIONAL FACETS",
    region: "region_4",
    section: "sectionData_9",
    area: 16,
  },
  7000002189: {
    comapnyId: 7000002189,
    company_name: "SHREE KRUPA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000011923: {
    comapnyId: 7000011923,
    company_name: "RATNAM JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000002656: {
    comapnyId: 7000002656,
    company_name: "GORDHANDAS NANDKISHORE JEWELLERS",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000002569: {
    comapnyId: 7000002569,
    company_name: "GEMS & JEWELS",
    region: "region_4",
    section: "sectionData_9",
    area: 16,
  },
  7000017030: {
    comapnyId: 7000017030,
    company_name: "PUSHPA GOLD PVT.LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000007639: {
    comapnyId: 7000007639,
    company_name: "LOTUS GEM IMPEX",
    region: "region_4",
    section: "sectionData_9",
    area: 16,
  },
  7000012517: {
    comapnyId: 7000012517,
    company_name: "VARK JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000000567: {
    comapnyId: 7000000567,
    company_name: "SHANTI JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000007269: {
    comapnyId: 7000007269,
    company_name: "KISHORE MOTIWALA",
    region: "region_4",
    section: "sectionData_9",
    area: 16,
  },
  7000008930: {
    comapnyId: 7000008930,
    company_name: "BIHARILAL HOLARAM",
    region: "region_4",
    section: "sectionData_9",
    area: 27,
  },
  7000012276: {
    comapnyId: 7000012276,
    company_name: "META JEWEL KRAFTS (INDIA) PVT. LTD.",
    region: "region_4",
    section: "sectionData_4",
    area: 12,
  },
  7000079903: {
    comapnyId: 7000079903,
    company_name: "HEMU SILVER PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_4",
    area: 9,
  },
  7000010200: {
    comapnyId: 7000010200,
    company_name: "P.N. JHAVERI",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000003818: {
    comapnyId: 7000003818,
    company_name: "KIMAYAS COLLECTION",
    region: "region_6",
    section: "sectionData_1",
    area: 18,
  },
  7000066945: {
    comapnyId: 7000066945,
    company_name: "DHYAAN SIDDHI ORNAMENTS",
    region: "region_1",
    section: "sectionData_4",
    area: 9,
  },
  7000005955: {
    comapnyId: 7000005955,
    company_name: "S. JINDAL GEMS INDIA LLP",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000006773: {
    comapnyId: 7000006773,
    company_name: "GREEN BELL",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000060871: {
    comapnyId: 7000060871,
    company_name: "LUXE RADIANT LLP",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000044699: {
    comapnyId: 7000044699,
    company_name: "DYNAMIC DIAMOND PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000012030: {
    comapnyId: 7000012030,
    company_name: "RAMNARAIN & COMPANY",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000009168: {
    comapnyId: 7000009168,
    company_name: "A.B. GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000069872: {
    comapnyId: 7000069872,
    company_name: "SILVER JEWELLERY MANUFACTURING CO.",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000013115: {
    comapnyId: 7000013115,
    company_name: "S.A. JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000001178: {
    comapnyId: 7000001178,
    company_name: "SHREEJI JEWELS",
    region: "region_2",
    section: "sectionData_9",
    area: 18,
  },
  7000027803: {
    comapnyId: 7000027803,
    company_name: "JEVAR ORNAMENTS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000051338: {
    comapnyId: 7000051338,
    company_name: "S. P. GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000008099: {
    comapnyId: 7000008099,
    company_name: "TARACHAND JEWELLERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000006991: {
    comapnyId: 7000006991,
    company_name: "SHRI RISHABH JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000057182: {
    comapnyId: 7000057182,
    company_name: "P. K. JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000057668: {
    comapnyId: 7000057668,
    company_name: "AJAHARA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000052707: {
    comapnyId: 7000052707,
    company_name: "MODI ORNAMENTS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000000459: {
    comapnyId: 7000000459,
    company_name: "MUSKAAN GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000012606: {
    comapnyId: 7000012606,
    company_name: "SHREEJI JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000067339: {
    comapnyId: 7000067339,
    company_name: "PNR MAHALAKSHMI JEWELLERY PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000047382: {
    comapnyId: 7000047382,
    company_name: "SHUBH CREATION",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000015626: {
    comapnyId: 7000015626,
    company_name: "SWARNSARITA JEWELS INDIA LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000014981: {
    comapnyId: 7000014981,
    company_name: "PARTH ORNAMENTS PVT. LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 54,
  },
  7000016961: {
    comapnyId: 7000016961,
    company_name: "CHAIN N CHAINS JEWELS LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000044410: {
    comapnyId: 7000044410,
    company_name: "ELA 18 DESIGNER JOOLRY",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000015370: {
    comapnyId: 7000015370,
    company_name: "SHAKTI JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000052980: {
    comapnyId: 7000052980,
    company_name: "DHANLAKSHMI JEWELLERS,",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000008653: {
    comapnyId: 7000008653,
    company_name: "AKSHAY GEMS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000008266: {
    comapnyId: 7000008266,
    company_name: "ASTMANGAL CHAINS PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 54,
  },
  7000046091: {
    comapnyId: 7000046091,
    company_name: "ADORE JEWELS LLP",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000000192: {
    comapnyId: 7000000192,
    company_name: "CLUSTER JEWELLERY LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000009781: {
    comapnyId: 7000009781,
    company_name: "VINAYAKA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000005823: {
    comapnyId: 7000005823,
    company_name: "PANSY JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000047492: {
    comapnyId: 7000047492,
    company_name: "ARAV JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000002372: {
    comapnyId: 7000002372,
    company_name: "SHREE SIDDHI VINAYAK JEWELS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000001940: {
    comapnyId: 7000001940,
    company_name: "AADEY JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 54,
  },
  7000015529: {
    comapnyId: 7000015529,
    company_name: "SHREE KALPTARU JEWELS  (I) P.LT",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000044091: {
    comapnyId: 7000044091,
    company_name: "RITESH GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000059775: {
    comapnyId: 7000059775,
    company_name: "NIHAR JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000057629: {
    comapnyId: 7000057629,
    company_name: "SREE CHAMUNDI JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000017042: {
    comapnyId: 7000017042,
    company_name: "GOKUL GEMS AND JEWELS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000013731: {
    comapnyId: 7000013731,
    company_name: "KAKADIAM",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000013006: {
    comapnyId: 7000013006,
    company_name: "GAUTAM JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003314: {
    comapnyId: 7000003314,
    company_name: "SWASTIK JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003321: {
    comapnyId: 7000003321,
    company_name: "M. R. GOLD PVT. LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 36,
  },
  7000010252: {
    comapnyId: 7000010252,
    company_name: "PANIHARIN EXPORTS",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000058097: {
    comapnyId: 7000058097,
    company_name: "SHREE CHINTAMANI ORNA",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000016983: {
    comapnyId: 7000016983,
    company_name: "PAHINI JEWELLERY PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 27,
  },
  7000014117: {
    comapnyId: 7000014117,
    company_name: "SHRADDHA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 27,
  },
  7000069663: {
    comapnyId: 7000069663,
    company_name: "ROHIT GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000051850: {
    comapnyId: 7000051850,
    company_name: "LEELAZ JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000013935: {
    comapnyId: 7000013935,
    company_name: "SUPARSHWA EXPORTS",
    region: "region_2",
    section: "sectionData_1",
    area: 18,
  },
  7000037850: {
    comapnyId: 7000037850,
    company_name: "DARPAN JEWELS PVT. LTD.",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000004421: {
    comapnyId: 7000004421,
    company_name: "J.P. EXPORTS",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000017383: {
    comapnyId: 7000017383,
    company_name: "MARVY JEWELS",
    region: "region_4",
    section: "sectionData_4",
    area: 9,
  },
  7000008477: {
    comapnyId: 7000008477,
    company_name: "RAERO INTERNATIONAL CZ  JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000075462: {
    comapnyId: 7000075462,
    company_name: "BHINDI JEWELLERS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000035512: {
    comapnyId: 7000035512,
    company_name: "RTSHAH JEWELLERS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000025490: {
    comapnyId: 7000025490,
    company_name: "INDERLAL BALMUKAND",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000061136: {
    comapnyId: 7000061136,
    company_name: "JEWELSFREYA",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000005071: {
    comapnyId: 7000005071,
    company_name: "MAAN JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000080180: {
    comapnyId: 7000080180,
    company_name: "SHRADDHA GOLDS INDIA PVT LTD",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000013293: {
    comapnyId: 7000013293,
    company_name: "DAZZLING JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000000857: {
    comapnyId: 7000000857,
    company_name: "NAKSHATRA JEWELLERY",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000015914: {
    comapnyId: 7000015914,
    company_name: "SUMAN JEWELLERS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000010135: {
    comapnyId: 7000010135,
    company_name: "AADISH GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000007999: {
    comapnyId: 7000007999,
    company_name: "V.K.JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000079900: {
    comapnyId: 7000079900,
    company_name: "SHRADDHA CHAINS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 12,
  },
  7000015786: {
    comapnyId: 7000015786,
    company_name: "SPARKLE DIAM PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000026533: {
    comapnyId: 7000026533,
    company_name: "BHAGWATI JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000044250: {
    comapnyId: 7000044250,
    company_name: "VIKAS JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000006879: {
    comapnyId: 7000006879,
    company_name: "RAJ GOLD",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000006912: {
    comapnyId: 7000006912,
    company_name: "SETHIA JEWELS GEMS LLP",
    region: "region_4",
    section: "sectionData_9",
    area: 9,
  },
  7000007107: {
    comapnyId: 7000007107,
    company_name: "SHREE MANGAL JEWELS PVT LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000012148: {
    comapnyId: 7000012148,
    company_name: "S.S. PLATINUM",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000007696: {
    comapnyId: 7000007696,
    company_name: "PM JEWELEX PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 16,
  },
  7000047617: {
    comapnyId: 7000047617,
    company_name: "QG INDIA LAB GROWN LLP",
    region: "region_2",
    section: "sectionData_6",
    area: 9,
  },
  7000004461: {
    comapnyId: 7000004461,
    company_name: "SONA RUPA JEWELLERS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000004266: {
    comapnyId: 7000004266,
    company_name: "ALLWIN JEWELLERS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000004419: {
    comapnyId: 7000004419,
    company_name: "J.P. ENTERPRISE",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000046242: {
    comapnyId: 7000046242,
    company_name: "OMKAR MINT PRIVATE LIMITED",
    region: "region_1",
    section: "sectionData_4",
    area: 9,
  },
  7000008527: {
    comapnyId: 7000008527,
    company_name: "D P JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000060874: {
    comapnyId: 7000060874,
    company_name: "RS GOLD",
    region: "region_6",
    section: "sectionData_2",
    area: 9,
  },
  7000059157: {
    comapnyId: 7000059157,
    company_name: "DHOLAKIA TECHNOLOGIES LLP",
    region: "region_3",
    section: "sectionData_6",
    area: 36,
  },
  7000007305: {
    comapnyId: 7000007305,
    company_name: "MANIHAR GEMS CREATION  PRIVATE LTD",
    region: "region_5",
    section: "sectionData_1",
    area: 16,
  },
  7000008285: {
    comapnyId: 7000008285,
    company_name: "ANNORAA CREATIONS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000007187: {
    comapnyId: 7000007187,
    company_name: "M L KANHAIYALAL JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000017374: {
    comapnyId: 7000017374,
    company_name: "IGL GEMSTONES PVT. LTD.",
    region: "region_2",
    section: "sectionData_5",
    area: 9,
  },
  7000049121: {
    comapnyId: 7000049121,
    company_name: "PRAGYA JEWELS",
    region: "region_1",
    section: "sectionData_4",
    area: 12,
  },
  7000053745: {
    comapnyId: 7000053745,
    company_name: "BHAVANI GEMS",
    region: "region_2",
    section: "sectionData_6",
    area: 9,
  },
  7000051963: {
    comapnyId: 7000051963,
    company_name: "MEET JEWELS LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000012486: {
    comapnyId: 7000012486,
    company_name: "RINKLE IMPEX",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000013498: {
    comapnyId: 7000013498,
    company_name: "CHUNOBA GOLD PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 16,
  },
  7000001126: {
    comapnyId: 7000001126,
    company_name: "SHREE BALAJI GOLD",
    region: "region_6",
    section: "sectionData_1",
    area: 16,
  },
  7000003386: {
    comapnyId: 7000003386,
    company_name: "DEEPA JEWELLERS PRIVATE  LIMITED",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000069598: {
    comapnyId: 7000069598,
    company_name: "PUNAMIYA & SONS JEWELLERS AND DIAMONDS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000051754: {
    comapnyId: 7000051754,
    company_name: "PT 950 WORLD OF PLATINUM",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000003546: {
    comapnyId: 7000003546,
    company_name: "HARSHWARDHAN GEMS PVT. LTD.",
    region: "region_5",
    section: "sectionData_1",
    area: 16,
  },
  7000003571: {
    comapnyId: 7000003571,
    company_name: "DIVYA JEWELS",
    region: "region_5",
    section: "sectionData_1",
    area: 16,
  },
  7000001140: {
    comapnyId: 7000001140,
    company_name: "JEWELS N JOOLRY",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000037909: {
    comapnyId: 7000037909,
    company_name: "A R JEWEL",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000003867: {
    comapnyId: 7000003867,
    company_name: "SWARN BHAVYA MANGAL JEWELS  PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061144: {
    comapnyId: 7000061144,
    company_name: "GLORY JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000003596: {
    comapnyId: 7000003596,
    company_name: "VARAKRUPA JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000038115: {
    comapnyId: 7000038115,
    company_name: "ETHEREAL GREEN DIAMOND LLP",
    region: "region_2",
    section: "sectionData_6",
    area: 36,
  },
  7000008913: {
    comapnyId: 7000008913,
    company_name: "DEVI JEWELLERS",
    region: "region_1",
    section: "sectionData_1",
    area: 18,
  },
  7000011089: {
    comapnyId: 7000011089,
    company_name: "ABDHI JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000014838: {
    comapnyId: 7000014838,
    company_name: "DEW DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 16,
  },
  7000057508: {
    comapnyId: 7000057508,
    company_name: "AVIRA STAR JEWEL LLP",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000009339: {
    comapnyId: 7000009339,
    company_name: "SADHANA GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000062026: {
    comapnyId: 7000062026,
    company_name: "PAVAN GEMS",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000061671: {
    comapnyId: 7000061671,
    company_name: "KARNISH JEWELS",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000016802: {
    comapnyId: 7000016802,
    company_name: "A.B. GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000061192: {
    comapnyId: 7000061192,
    company_name: "PREMVATI JEWELLERY PVT LTD",
    region: "region_3",
    section: "sectionData_1",
    area: 9,
  },
  7000004348: {
    comapnyId: 7000004348,
    company_name: "SANGAM JEWELS N GOLD LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000050691: {
    comapnyId: 7000050691,
    company_name: "MILAN JEWELS",
    region: "region_3",
    section: "sectionData_2",
    area: 16,
  },
  7000048119: {
    comapnyId: 7000048119,
    company_name: "RADHARANI JEWELLERS LIMITED",
    region: "region_6",
    section: "sectionData_2",
    area: 18,
  },
  7000069888: {
    comapnyId: 7000069888,
    company_name: "VK PRECIOUS PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_6",
    area: 36,
  },
  7000005327: {
    comapnyId: 7000005327,
    company_name: "VAMA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000037694: {
    comapnyId: 7000037694,
    company_name: "CHHANDA JEWELLERS",
    region: "region_5",
    section: "sectionData_1",
    area: 9,
  },
  7000017120: {
    comapnyId: 7000017120,
    company_name: "ANMOL JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000069580: {
    comapnyId: 7000069580,
    company_name: "BRAHAMMAND JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000066457: {
    comapnyId: 7000066457,
    company_name: "GREENLAB JEWELS PRIVATE LIMITED",
    region: "region_3",
    section: "sectionData_7",
    area: 54,
  },
  7000015328: {
    comapnyId: 7000015328,
    company_name: "SHA KHEMRAJ PRITHVIRAJ & CO.",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000002129: {
    comapnyId: 7000002129,
    company_name: "SHIV SILVER",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000003801: {
    comapnyId: 7000003801,
    company_name: "ANOKHI JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000010056: {
    comapnyId: 7000010056,
    company_name: "OM JEWELS",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000010388: {
    comapnyId: 7000010388,
    company_name: "PAWAN & COMPANY",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000005095: {
    comapnyId: 7000005095,
    company_name: "SILBER INTERNATIONAL",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000000988: {
    comapnyId: 7000000988,
    company_name: "VANSH GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000064164: {
    comapnyId: 7000064164,
    company_name: "G P SILVERS",
    region: "region_2",
    section: "sectionData_4",
    area: 9,
  },
  7000001845: {
    comapnyId: 7000001845,
    company_name: "MINERALS AND JEWELS",
    region: "region_2",
    section: "sectionData_9",
    area: 9,
  },
  7000015047: {
    comapnyId: 7000015047,
    company_name: "R.K. SILVER",
    region: "region_3",
    section: "sectionData_2",
    area: 36,
  },
  7000004934: {
    comapnyId: 7000004934,
    company_name: "SILVER ZONE",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000053102: {
    comapnyId: 7000053102,
    company_name: "ASHOK DIAMONDS",
    region: "region_1",
    section: "sectionData_1",
    area: 9,
  },
  7000017608: {
    comapnyId: 7000017608,
    company_name: "ANMOL JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 36,
  },
  7000009731: {
    comapnyId: 7000009731,
    company_name: "KANAK JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 18,
  },
  7000015560: {
    comapnyId: 7000015560,
    company_name: "SHREE TRADING CO.",
    region: "region_6",
    section: "sectionData_4",
    area: 16,
  },
  7000016711: {
    comapnyId: 7000016711,
    company_name: "WORLDWIDE GEM EXPORTS",
    region: "region_2",
    section: "sectionData_9",
    area: 16,
  },
  7000066662: {
    comapnyId: 7000066662,
    company_name: "M M JEWELLERIES",
    region: "region_2",
    section: "sectionData_2",
    area: 12,
  },
  7000003007: {
    comapnyId: 7000003007,
    company_name: "GUNJAN JEWELS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 108,
  },
  7000001435: {
    comapnyId: 7000001435,
    company_name: "S.G. CREATIONS",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000014533: {
    comapnyId: 7000014533,
    company_name: "BHARAT TRADERS",
    region: "region_2",
    section: "sectionData_4",
    area: 16,
  },
  7000013228: {
    comapnyId: 7000013228,
    company_name: "RIDHI SIDHI GEMS & JEWELLERY",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000019090: {
    comapnyId: 7000019090,
    company_name: "LIMELIGHT LAB GROWN DIAMONDS LIMITED",
    region: "region_2",
    section: "sectionData_7",
    area: 18,
  },
  7000001138: {
    comapnyId: 7000001138,
    company_name: "MANGALAM GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 16,
  },
  7000000959: {
    comapnyId: 7000000959,
    company_name: "K.G. GEMS",
    region: "region_4",
    section: "sectionData_9",
    area: 16,
  },
  7000010488: {
    comapnyId: 7000010488,
    company_name: "KESHAVLAL F GANDHI JEWELLERS",
    region: "region_2",
    section: "sectionData_9",
    area: 16,
  },
  7000051236: {
    comapnyId: 7000051236,
    company_name: "NIKHIL JEWELLERS",
    region: "region_3",
    section: "sectionData_2",
    area: 9,
  },
  7000026282: {
    comapnyId: 7000026282,
    company_name: "PLUTO JEWELS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000047881: {
    comapnyId: 7000047881,
    company_name: "BLOSSOM JEWELS LLP",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000012775: {
    comapnyId: 7000012775,
    company_name: "M L PITLIYA JEWELLERS",
    region: "region_4",
    section: "sectionData_9",
    area: 16,
  },
  7000001790: {
    comapnyId: 7000001790,
    company_name: "AMIT GOLD",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000000563: {
    comapnyId: 7000000563,
    company_name: "CHAIN EXPERTS AND JE WELLERS PVT.LTD",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000069089: {
    comapnyId: 7000069089,
    company_name: "RAJ IMPEX",
    region: "region_3",
    section: "sectionData_6",
    area: 9,
  },
  7000010064: {
    comapnyId: 7000010064,
    company_name: "MANMOHAN EXPORTS",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000003497: {
    comapnyId: 7000003497,
    company_name: "HARI KRISHNA EXPORTS PVT. LTD.",
    region: "region_2",
    section: "sectionData_1",
    area: 54,
  },
  7000075730: {
    comapnyId: 7000075730,
    company_name: "RATAN JEWELLERS & SONS",
    region: "region_4",
    section: "sectionData_9",
    area: 16,
  },
  7000067595: {
    comapnyId: 7000067595,
    company_name: "AURAA DIAMONDS",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000061540: {
    comapnyId: 7000061540,
    company_name: "SHREE JEE JEWELLERS JAIPUR",
    region: "region_4",
    section: "sectionData_2",
    area: 12,
  },
  7000014820: {
    comapnyId: 7000014820,
    company_name: "SKY CHAINS & JEWELLERY PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000014839: {
    comapnyId: 7000014839,
    company_name: "S.V. EXPORTS",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000066358: {
    comapnyId: 7000066358,
    company_name: "AVP STAR PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_6",
    area: 18,
  },
  7000004919: {
    comapnyId: 7000004919,
    company_name: "SAMRAT GEMS",
    region: "region_2",
    section: "sectionData_9",
    area: 12,
  },
  7000002963: {
    comapnyId: 7000002963,
    company_name: "PRAGATI GOLD PVT LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000011460: {
    comapnyId: 7000011460,
    company_name: "RUDRA JEWELS",
    region: "region_2",
    section: "sectionData_2",
    area: 16,
  },
  7000009393: {
    comapnyId: 7000009393,
    company_name: "SHREE NATH ABHUSHAN BHANDAR",
    region: "region_4",
    section: "sectionData_2",
    area: 9,
  },
  7000053139: {
    comapnyId: 7000053139,
    company_name: "SUNDEEP JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000057763: {
    comapnyId: 7000057763,
    company_name: "VESARA JEWELS PVT LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 9,
  },
  7000003513: {
    comapnyId: 7000003513,
    company_name: "HARITSONS DESIGNS PVT. LTD.",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000010118: {
    comapnyId: 7000010118,
    company_name: "P.C. TOTUKA & SONS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000002843: {
    comapnyId: 7000002843,
    company_name: "GOLECHA S JEWELS",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000009785: {
    comapnyId: 7000009785,
    company_name: "SAVIO JEWELLERY",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000076576: {
    comapnyId: 7000076576,
    company_name: "GK CHUDIWALAS LLP",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000015564: {
    comapnyId: 7000015564,
    company_name: "SHREEJEE JEWELLERS PVT.LTD.",
    region: "region_5",
    section: "sectionData_10",
    area: 24,
  },
  7000001893: {
    comapnyId: 7000001893,
    company_name: "MEVANAGAR SALES PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_4",
    area: 12,
  },
  7000014641: {
    comapnyId: 7000014641,
    company_name: "BIRDHI CHAND GHANSHYAM DAS",
    region: "region_4",
    section: "sectionData_10",
    area: 48,
  },
  7000011503: {
    comapnyId: 7000011503,
    company_name: "R.N. GEMS",
    region: "region_2",
    section: "sectionData_3",
    area: 12,
  },
  7000012122: {
    comapnyId: 7000012122,
    company_name: "PADMAVATI JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000010714: {
    comapnyId: 7000010714,
    company_name: "PRAKASH GOLD PALACE (P) LTD",
    region: "region_1",
    section: "sectionData_2",
    area: 16,
  },
  7000015289: {
    comapnyId: 7000015289,
    company_name: "SAWANSUKHA JEWELLERS PVT.LTD.",
    region: "region_5",
    section: "sectionData_10",
    area: 24,
  },
  7000070312: {
    comapnyId: 7000070312,
    company_name: "KIRA DIAM LLP",
    region: "region_2",
    section: "sectionData_6",
    area: 54,
  },
  7000008091: {
    comapnyId: 7000008091,
    company_name: "MAHENDRA BROTHERS EXPORTS PVt. LTD..",
    region: "region_2",
    section: "sectionData_3",
    area: 9,
  },
  7000061038: {
    comapnyId: 7000061038,
    company_name: "BHANDERI LAB GROWN DIAMONDS LLP",
    region: "region_3",
    section: "sectionData_6",
    area: 54,
  },
  7000016454: {
    comapnyId: 7000016454,
    company_name: "VALENTINE JEWELLERY (INDIA) PV T. LTD",
    region: "region_4",
    section: "sectionData_10",
    area: 24,
  },
  7000009325: {
    comapnyId: 7000009325,
    company_name: "RAJDHANI OVERSEAS",
    region: "region_4",
    section: "sectionData_4",
    area: 16,
  },
  7000047183: {
    comapnyId: 7000047183,
    company_name: "GOLDEN RAY CHAINS & JEWELLERY LLP",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000017249: {
    comapnyId: 7000017249,
    company_name: "MANAK JEWELLERS PVT. LTD.",
    region: "region_2",
    section: "sectionData_2",
    area: 54,
  },
  7000017006: {
    comapnyId: 7000017006,
    company_name: "GIA INDIA LABORATORY PVT. LTD.",
    region: "region_2",
    section: "sectionData_5",
    area: 18,
  },
  7000052766: {
    comapnyId: 7000052766,
    company_name: "MERAYA",
    region: "region_2",
    section: "sectionData_6",
    area: 18,
  },
  7000001064: {
    comapnyId: 7000001064,
    company_name: "TRUSHNA EXIM",
    region: "region_3",
    section: "sectionData_6",
    area: 36,
  },
  7000069746: {
    comapnyId: 7000069746,
    company_name: "SRI SHUBHAM JEWELLERS",
    region: "region_1",
    section: "sectionData_2",
    area: 27,
  },
  7000011048: {
    comapnyId: 7000011048,
    company_name: "DEWAL JEWELS",
    region: "region_4",
    section: "sectionData_1",
    area: 9,
  },
  7000039496: {
    comapnyId: 7000039496,
    company_name: "GREENLAB DIAMONDS LLP",
    region: "region_3",
    section: "sectionData_6",
    area: 18,
  },
  7000050229: {
    comapnyId: 7000050229,
    company_name: "JOWELA EXIM PRIVATE LIMITED",
    region: "region_2",
    section: "sectionData_1",
    area: 9,
  },
  7000011467: {
    comapnyId: 7000011467,
    company_name: "ROYAL HEM",
    region: "region_2",
    section: "sectionData_2",
    area: 9,
  },
  7000068382: {
    comapnyId: 7000068382,
    company_name: "DEVNGI GEMS",
    region: "region_3",
    section: "sectionData_6",
    area: 18,
  },
  7000026268: {
    comapnyId: 7000026268,
    company_name: "INTERNATIONAL INSTITUTE OF GEMOLOGY",
    region: "region_2",
    section: "sectionData_5",
    area: 9,
  },
};

const data = {
  "7000017006A": {
    "comapnyId": "7000017006A",
    "company_name": "GIA INDIA LABORATORY PVT. LTD.",
    "region": "region_2",
    "area": 36,
    "section": "sectionData_5"
  },
  "7000017006B": {
    "comapnyId": "7000017006B",
    "company_name": "GIA INDIA LABORATORY PVT. LTD.",
    "region": "region_2",
    "area": 18,
    "section": "sectionData_5"
  },
  "7000053589A": {
    "comapnyId": "7000053589A",
    "company_name": "MEERA JEWELLERYHOUSE LLP",
    "region": "region_3",
    "area": 9,
    "section": "sectionData_7"
  },
  "7000053589B": {
    "comapnyId": "7000053589B",
    "company_name": "MEERA JEWELLERYHOUSE LLP",
    "region": "region_3",
    "area": 16,
    "section": "sectionData_1"
  },
  "7000009083A": {
    "comapnyId": "7000009083A",
    "company_name": "MUKTI GOLD PVT. LTD",
    "region": "region_2",
    "area": 72,
    "section": "sectionData_2"
  },
  "7000009083B": {
    "comapnyId": "7000009083B",
    "company_name": "MUKTI GOLD PVT. LTD",
    "region": "region_2",
    "area": 36,
    "section": "sectionData_6"
  },
  "7000002074A": {
    "comapnyId": "7000002074A",
    "company_name": "NIMESH GEMS",
    "region": "region_2",
    "area": 24,
    "section": "sectionData_10"
  },
  "7000002074B": {
    "comapnyId": "7000002074B",
    "company_name": "NIMESH GEMS",
    "region": "region_2",
    "area": 18,
    "section": "sectionData_11"
  },
  "7000009270A": {
    "comapnyId": "7000009270A",
    "company_name": "PIERCING SYSTEMS INDIA PVT.  LTD.",
    "region": "region_2",
    "area": 36,
    "section": "sectionData_12"
  },
  "7000009270B": {
    "comapnyId": "7000009270B",
    "company_name": "PIERCING SYSTEMS INDIA PVT.  LTD.",
    "region": "region_2",
    "area": 18,
    "section": "sectionData_13"
  },
  "7000066559A": {
    "comapnyId": "7000066559A",
    "company_name": "SHANTI SONS",
    "region": "region_2",
    "area": 9,
    "section": "sectionData_7"
  },
  "7000066559B": {
    "comapnyId": "7000066559B",
    "company_name": "SHANTI SONS",
    "region": "region_2",
    "area": 9,
    "section": "sectionData_1"
  },
  "7000015411A": {
    "comapnyId": "7000015411A",
    "company_name": "SHASHVAT JEWELS PRIVATE    LIMITED",
    "region": "region_3",
    "area": 54,
    "section": "sectionData_1"
  },
  "7000015411B": {
    "comapnyId": "7000015411B",
    "company_name": "SHASHVAT JEWELS PRIVATE    LIMITED",
    "region": "region_3",
    "area": 18,
    "section": "sectionData_7"
  },
  "7000006595A": {
    "comapnyId": "7000006595A",
    "company_name": "SHEETAL JEWELLERY HOUSE LLP",
    "region": "region_2",
    "area": 36,
    "section": "sectionData_11"
  },
  "7000006595B": {
    "comapnyId": "7000006595B",
    "company_name": "SHEETAL JEWELLERY HOUSE LLP",
    "region": "region_2",
    "area": 108,
    "section": "sectionData_1"
  },
  "7000015550A": {
    "comapnyId": "7000015550A",
    "company_name": "SHREE RAMKRISHNA EXPORTS P.LTD",
    "region": "region_2",
    "area": 36,
    "section": "sectionData_11"
  },
  "7000015550B": {
    "comapnyId": "7000015550B",
    "company_name": "SHREE RAMKRISHNA EXPORTS P.LTD",
    "region": "region_2",
    "area": 9,
    "section": "sectionData_1"
  }
};
export const setSellersBaseData = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = realDB.ref(REALDB_CONSTANT.SellerBaseData);
      // const snapshot = realDB.ref('/2022_sprint_3/test');
      await snapshot.update(newData);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

// console.log(data);

export const setNode = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = realDB.ref(REALDB_CONSTANT.SellerBaseData);
      await snapshot.update(data);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

export const updateExhibitorData = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const snapshot = realDB.ref(REALDB_CONSTANT.SellerBaseData);
      await snapshot.update(data);
      resolve();
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
};

window.setSellersBaseData = setSellersBaseData;
window.setNode = setNode;
